import * as API from "../../api/migration";
import * as actionType from '../action-types/migration';

import { message } from "antd";
import { goBackToPrev } from '../../../navigation/navigationService';
import {
    setLoadingSubmit,
    unsetLoadingSubmit,
} from './style';

export const get_stage = (uidList) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.getStage(uidList)
        .then(data => {
            if (data && data.status && data.status === 200) {
                dispatch(get_stage_success(data.users));

                dispatch(unsetLoadingSubmit());
            } else {
                message.error(data.err || `Error getting users`);

                dispatch(unsetLoadingSubmit());
            }
        })
}

const get_stage_success = (data) => {
    // console.log("get_stage_success data:", data);

    return {
        type: actionType.GET_STAGE,
        data: data,
    }
}

export const migrate_user = (uid, sourceStage, targetStage) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.migrateUser(uid, sourceStage, targetStage)
        .then(data => {
            // console.log("migrate_user data:", data);

            if (data.err) {
                message.error(data.err);

                dispatch(unsetLoadingSubmit());
            }
            else {
                dispatch(migrate_user_success(data.user, targetStage));
                dispatch(goBackToPrev());

                dispatch(unsetLoadingSubmit());
            }
        })
}

const migrate_user_success = (data, targetStage) => {
    // console.log("migrate_user_success data:", data);

    return {
        type: actionType.MIGRATE_USER,
        data: data,
        targetStage: targetStage,
    }
}

export const set_user_to_migrate = (selectedUser) => {
    // console.log("set_user_to_migrate data:", data);

    return {
        type: actionType.SET_USER_TO_MIGRATE,
        selectedUser: selectedUser,
    }
}