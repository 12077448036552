import React from "react";
import { connect } from "react-redux";

import moment from "moment";
import ExportExcelButton from "../ExportExcelButton";

import { PrimaryButton } from "../Button";
import { EVENT_TYPES, TRIP_STATUS, VEHICLE_STATUS } from "../../../../constants";
import { parseDuration } from "../FleetRecordTransitTimeline/parseDuration";
import {
  Row,
  Col,
  Select,
  message,
  DatePicker as DatePickerAntd,
} from "antd";

// Redux Actions
import { clear_selected_device } from "../../services/redux/actions/devices";
import { clear_selected_vehicle } from "../../services/redux/actions/vehicles";

import "./index.css";
import { parseTime } from '../../util/time';

class VehicleSelectForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedId:
        props.tripRecordControl.selectedVID ?
          `${props.vehicles.byId[props.tripRecordControl.selectedVID].dvid}||${props.vehicles.byId[props.tripRecordControl.selectedVID].vehiclePlate}||${props.tripRecordControl.selectedVID}` :
          undefined,
      startTime: props.tripRecordControl.searchStartTime ? moment(props.tripRecordControl.searchStartTime) : moment().startOf("day"),
      endTime: props.tripRecordControl.searchEndTime ? moment(props.tripRecordControl.searchEndTime) : moment().startOf("day").add(1, "day"),
      status: Object.keys(props.vehicles.byStatus).filter(k => k !== 'ALL')
    }
  }

  submitForm = () => {
    let {
      endTime,
      startTime,
      selectedId,
    } = this.state;

    this.props.onSubmit(startTime, endTime, selectedId);
  };

  returnEventExportButton = (selectedVID) => {
    const vehicle = this.props.vehicles.byId[selectedVID]
    if (!vehicle) return null

    const {
      endTime,
      startTime,
      // selectedId,
    } = this.state;

    const {
      events,
      // vehicles,
      statusBar,
    } = this.props;


    const isAllEvents = statusBar.selectedEventType === EVENT_TYPES.ALL;
    const newSheetData = Object.values(events.byId[selectedVID] || {}).filter((currEvent) => isAllEvents || currEvent.eventType === statusBar.selectedEventType);

    // console.log("Test:", selectedVID, statusBar.selectedEventType, isAllEvents, newSheetData);

    return (
      <ExportExcelButton
        filename={`${vehicle.vehiclePlate} - Events${!isAllEvents ? ` (${statusBar.selectedEventType})` : ""} - ${moment(startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}`}
        sheetData={newSheetData}
        sheetName={`${vehicle.vehiclePlate} Events${!isAllEvents ? ` (${statusBar.selectedEventType})` : null}`}
        sheetRow={[
          { label: 'Event Type', formatter: value => value.eventType ? value.eventType : '-' },
          { label: 'Event Name', formatter: value => value.eventName ? value.eventName : '-' },
          { label: 'Location', formatter: value => value.location ? `${value.location.lat}, ${value.location.lon}` : '-' },
          { label: 'Created At', formatter: value => value.createdAt ? moment(value.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-' },
        ]}
      />
    );
  }

  returnTripRecordExportButton = () => {

    const {
      selectedId, startTime, endTime,
    } = this.state

    const vehicle = this.props.vehicles.byId[selectedId]

    if (!vehicle) return null


    const getTripRecords = (filteredTransitRecords, filteredEventLogs, filteredTripRecords) => {

      if (
        !filteredTripRecords
        || !Array.isArray(filteredTripRecords)
      ) return null

      const transitRecordsByTrid = {}
      Object.values(this.props.transitRecord.byTsid).map(transitRecord => {
        if (!transitRecordsByTrid.hasOwnProperty(transitRecord.trid)) {
          transitRecordsByTrid[transitRecord.trid] = []
        }
        transitRecordsByTrid[transitRecord.trid].push(transitRecord)
        return null
      })

      /**Static counter require to simplify index of TRANSIT and PARKING trip */
      // let parkingCounter = 0
      // let transitCounter = 0
      return filteredTripRecords.map((tripRecord, i) => {

        const transitRecordsUnderThisTrip = filteredTransitRecords.filter(tr => {
          return tripRecord.startTime <= tr.startTime && tripRecord.endTime >= tr.endTime
        })
        const eventsUnderThisTrip = filteredEventLogs.filter(e => {
          return tripRecord.startTime <= e.createdAt && tripRecord.endTime >= e.createdAt
        })

        let durationByTransitStatus = {}
        transitRecordsUnderThisTrip
          .filter(transitRecord => transitRecord.duration)
          .map(transitRecord => {
            if (!durationByTransitStatus.hasOwnProperty(transitRecord.transitStatus)) {
              durationByTransitStatus[transitRecord.transitStatus] = 0
            }
            durationByTransitStatus[transitRecord.transitStatus] += transitRecord.duration
            return null
          })

        // const totalDistance = transitRecords.map(t => t.totalMileage).filter(m => m).reduce((a, b) => a + b, 0)
        const totalFuel = transitRecordsUnderThisTrip.map(t => t.totalMovingFuelConsumption).filter(m => m).reduce((a, b) => a + b, 0)

        const trip = {
          tripStatus: tripRecord.tripStatus,
          startTime: tripRecord.startTime,
          endTime: tripRecord.endTime,
          startAddress: tripRecord.startAddress,
          endAddress: tripRecord.endAddress,
          distance: tripRecord.totalMileage,
          topSpeed: tripRecord.topSpeed,
          movingDuration: (tripRecord.tripStatus === TRIP_STATUS.TRANSIT && durationByTransitStatus[VEHICLE_STATUS.MOVING]) || 0,
          idlingDuration: (tripRecord.tripStatus === TRIP_STATUS.TRANSIT && durationByTransitStatus[VEHICLE_STATUS.IDLING]) || 0,
          totalEvents: eventsUnderThisTrip.length,
          fuelUsed: vehicle.fuelCapacity * totalFuel,
        }

        return trip

      })
    }
    /**
     * Take startTime of 1st trip record
     * Take endTime of last trip record
     * Filter transitRecords & eventLogs accordingly
     */
    const filterData = () => {

      let tripRecords = this.props.tripRecord.byVID[selectedId]

      if (
        !tripRecords
        || !Array.isArray(tripRecords)
      ) return {}

      const filteredTripRecords = tripRecords
        .sort((a, b) => a.startTime - b.startTime)

      const firstTripRecord = filteredTripRecords[0]
      const lastTripRecord = filteredTripRecords[filteredTripRecords.length - 1]

      const firstTripRecordStartTime = firstTripRecord.startTime
      const lastTripRecordEndTime = lastTripRecord.endTime

      const filteredTransitRecords = Object.values(this.props.transitRecord.byTsid)
        .filter(ts => firstTripRecordStartTime <= ts.startTime && lastTripRecordEndTime >= ts.endTime)

      const eventLogs = (this.props.events.byId[selectedId] && Object.values(this.props.events.byId[selectedId])) || []
      const filteredEventLogs = eventLogs
        .filter(log => firstTripRecordStartTime <= log.createdAt && lastTripRecordEndTime >= log.createdAt)


      // console.log({ filteredTransitRecords, filteredEventLogs, filteredTripRecords })

      return { filteredTransitRecords, filteredEventLogs, filteredTripRecords }
    }

    const { filteredTransitRecords, filteredEventLogs, filteredTripRecords } = filterData()

    const trips = getTripRecords(filteredTransitRecords, filteredEventLogs, filteredTripRecords) || []

    return (
      <ExportExcelButton
        filename={`${vehicle.vehiclePlate} - Trip Records - ${moment(startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}`}
        sheetData={trips}
        sheetName='Trip Records'
        sheetRow={[
          {
            label: 'tripStatus',
            formatter: value => value.tripStatus || '-',
          },
          {
            label: 'startTime',
            formatter: value => parseTime(value.startTime) || '-',
          },
          {
            label: 'endTime',
            formatter: value => parseTime(value.endTime) || '-',
          },
          {
            label: 'startAddress',
            formatter: value => value.startAddress || '-',
          },
          {
            label: 'endAddress',
            formatter: value => value.endAddress || '-',
          },
          {
            label: 'distance (km)',
            formatter: value => {
              const distance = value.distance
              return (!isNaN(distance) && `${distance.toFixed(2)}`) || 0
            }
          },
          {
            label: 'topSpeed (km/h)',
            formatter: value => {
              const topSpeed = value.topSpeed
              return (!isNaN(topSpeed) && `${topSpeed.toFixed(2)}`) || 0
            }
          },
          {
            label: 'movingDuration',
            formatter: value => {
              const movingDuration = value.movingDuration
              return (!isNaN(movingDuration) && parseDuration(movingDuration)) || '-'
            }
          },
          {
            label: 'idlingDuration',
            formatter: value => {
              const idlingDuration = value.idlingDuration
              return (!isNaN(idlingDuration) && parseDuration(idlingDuration)) || '-'
            }
          },
          {
            label: 'totalEvents',
            formatter: value => value.totalEvents
          },
          {
            label: 'fuelUsed',
            formatter: value => {
              const fuelUsed = value.fuelUsed
              if (isNaN(fuelUsed)) return 0

              return (fuelUsed / 100).toFixed(2)
            }
          }
        ]} 
      />
    );
  }

  handleCalendarDisabledDate = () => moment().startOf("day").add(1, "day");

  componentDidUpdate = (prevProps) => {
    if (this.props.transitRecord !== prevProps.transitRecord && this.props.transitRecord.PDFstatus) {
      this.props.transitRecord.PDFstatus === 'done' && message.success('Report generated');
      this.props.transitRecord.PDFstatus === 'error' && message.error('Error in generating report. Try again?');
    }
  }

  componentWillUnmount = () => {
    this.props.dispatch(clear_selected_device());
    this.props.dispatch(clear_selected_vehicle());
  }

  render() {
    const {
      status,
      endTime,
      startTime,
      selectedId,
    } = this.state; 
    
    return (
      <div>
        <Row className="inputContainer">
          <Col span={8}>
            <span className="inputLabel">Vehicle:</span>
          </Col>

          <Col span={16}>
            <Select
              className="inputField"
              placeholder="Select a vehicle"
              value={selectedId}
              style={{ width: 206 }}
              onChange={(selectedId) => this.setState({ selectedId })}
            >
              {
                Object.values(this.props.vehicles.byId)
                .sort((a, b) => {
                  const vA = a.vehicleDisplayName.toLowerCase();
                  const vB = b.vehicleDisplayName.toLowerCase();

                  if (vA < vB) return -1;
                  if (vA > vB) return 1;
                  return 0;
                })
                .map((vehicle) => {
                  return (
                    <Select.Option key={vehicle.vid}>
                      {vehicle.vehicleDisplayName}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </Col>
        </Row>

        <Row className="inputContainer">
          <Col span={8}>
            <span className="inputLabel">Start Date:</span>
          </Col>

          <Col span={16}>
            <DatePickerAntd
              id="start-time"
              allowClear={false}
              value={startTime}
              className="inputField"
              format={'YYYY/MM/DD HH:mm:ss'}
              onChange={startTime => this.setState({ startTime })}
              showTime={{
                format: 'HH:mm:ss',
                inputReadOnly: false
              }}
            />
          </Col>
        </Row>

        <Row className="inputContainer">
          <Col span={8}>
            <span className="inputLabel">End Date:</span>
          </Col>

          <Col span={16}>
            <DatePickerAntd
              id="end-time"
              allowClear={false}
              value={endTime}
              className="inputField"
              format={'YYYY/MM/DD HH:mm:ss'}
              disabledTime={this.handleCalendarDisabledDate}
              onChange={endTime => this.setState({ endTime })}
              showTime={{
                format: 'HH:mm:ss',
                inputReadOnly: false
              }}
            />
          </Col>
        </Row>

        <div className="inputButtonContainer" style={{ flex: 1, justifyContent: "center", marginTop: 20 }}>
          <PrimaryButton
            onClick={this.submitForm}
            loading={this.props.style.isLoadingFleetRecord}
            disabled={!(selectedId && startTime && endTime && status)}
          >
            Submit
          </PrimaryButton>
          {
            this.props.pathname === '/TripRecord' ? (
              this.returnTripRecordExportButton(this.state.selectedId)
            ) :
              this.props.pathname === '/EventRecord' ? (
                this.returnEventExportButton(this.state.selectedId)
              ) : null
          }
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  style: state.v2.style,
  events: state.v2.events,
  vehicles: state.v2.vehicles,
  statusBar: state.v2.statusBar,
  transitRecord: state.v2.transitRecord,
  tripRecordControl: state.v2.tripRecordControl,
  // vehicles: state.v2.vehicles,
  deviceLog: state.v2.deviceLog,
  tripRecord: state.v2.tripRecord,
});

export default connect(mapStateToProps)(VehicleSelectForm);