import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import ReactExport from "react-data-export";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import {
    Table,
    Select,
    Button,
    DatePicker,
} from 'antd';

// React Actions
import { select_user } from "../../services/redux/actions/hierarchy";
import { get_vehicle_log_by_timeframe_request } from "../../services/redux/actions/vehicleLog";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class VehicleLogInspectorPage extends React.Component {
    state = {
        date: moment(),

        selectedUID: "",
        selectedVID: "",
        selectedVehicleDisplayName: "", // Used for export title

        dataLog: null,
        startTime: moment().startOf("day"),
        endTime: moment().add(1, "day").startOf("day"),

        filteredInfo: {},
    }

    startTimeChange = (value) => {
        // console.log(`selected ${value}`);
        // console.log("Date:", moment(value).format());

        this.setState({
            ...this.state,
            startTime: value
        });
    }

    endTimeChange = (value) => {
        // console.log(`selected ${value}`);
        // console.log("Date:", moment(value).format());

        this.setState({
            ...this.state,
            endTime: value
        });
    }

    submit = () => {
        const vid = this.state.selectedVID
        const startTimeEpoch = Number(this.state.startTime.valueOf())
        const endTimeEopch = Number(this.state.endTime.valueOf())

        this.props.dispatch(get_vehicle_log_by_timeframe_request(vid, startTimeEpoch, endTimeEopch));
    }

    setUpState = () => {
        const { vehicle } = this.props.router.location.state || {};

        this.setState({
            selectedVID: vehicle && vehicle.vid,
        })
    }

    componentDidMount = () => {
        this.setUpState()
    }

    componentDidUpdate = (prevProps) => {
        const {
            router,
            hierarchy,
        } = this.props;

        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setUpState()
        }

        // if (
        //     JSON.stringify(prevProps.hierarchy) !== JSON.stringify(this.props.hierarchy)
        //     || JSON.stringify(prevProps.router.location) !== JSON.stringify(this.props.router.location)
        // ) {
        //     const { uid } = this.props.router.location.state || {}

        //     const user = this.props.hierarchy.byId[uid];

        //     if (!user) return;

        //     this.props.dispatch(select_user(user));
        // }

        // Calling select_user action to populate device list in case user refresh this page
        // Possible infinite but shouldn't happen as selected device in this page is derived from user management device table which lists selected user's devices according to hierarchy store
        if (JSON.stringify(prevProps.hierarchy) !== JSON.stringify(hierarchy)
            && router.location.state.uid 
            && hierarchy.byId[router.location.state.uid]
            && !hierarchy.byId[router.location.state.uid].vehicles.length
        ) {
            this.props.dispatch(select_user(hierarchy.byId[router.location.state.uid]));
        }
    }

    render() {
        const { Option } = Select;

        const {
            endTime,
            startTime,
            selectedVID,
            filteredInfo,
        } = this.state;

        const {
            hierarchy,
            vehicleLog,
        } = this.props;

        const { uid } = this.props.router.location.state || {};

        const selectedUser = hierarchy.byId[uid];
        const vehicles = (selectedUser && selectedUser.vehicles) || [];

        const selectedVehicle = vehicles.find(v => v.vid === this.state.selectedVID);

        window.props = this.props

        const columnsGPSLog = [
            {
                key: 'updateAt',
                title: 'Server Time',
                dataIndex: 'updateAt',
                sorter: (a, b) => a.updateAt.localeCompare(b.updateAt),
            },

            {
                key: 'vehicleTime',
                title: "Vehicle Time",
                dataIndex: "vehicleTime",
                defaultSortOrder: "descend",
                sorter: (a, b) => a.vehicleTime.localeCompare(b.vehicleTime),
            },

            {
                title: "Address",
                dataIndex: "address"
            },

            {
                title: "Trip Status",
                dataIndex: "tripStatus",
                filters: [
                    {
                        text: "TRANSIT",
                        value: "TRANSIT"
                    },
                    {
                        text: "PARKING",
                        value: "PARKING"
                    },
                ],
                filteredValue: filteredInfo.tripStatus || null,
                onFilter: (value, record) => record.tripStatus !== null && record.tripStatus.includes(value),
            },

            {
                title: "Trip From",
                dataIndex: "tripFrom"
            },

            {
                title: "Transit Status",
                dataIndex: "transitStatus",
                filters: [
                    {
                        text: "MOVING",
                        value: "MOVING"
                    },
                    {
                        text: "IDLING",
                        value: "IDLING"
                    },
                    {
                        text: "PARKING",
                        value: "PARKING"
                    },
                ],
                filteredValue: filteredInfo.transitStatus || null,
                onFilter: (value, record) => record.transitStatus !== null && record.transitStatus.includes(value),
            },

            {
                title: "Transit From",
                dataIndex: "transitFrom"
            },

            {
                title: "Mileage",
                dataIndex: "mileage"
            },
        ]

        return (
            <div className = "page-container">
                <Page title = "Vehicle Inspector Table">
                    {
                        <div style = {{ marginBottom: '10px' }}>
                            <Select
                                value = {selectedVID}
                                onChange = {selectedVID => {
                                    this.setState({
                                        selectedVID
                                    })
                                }}
                                style = {{
                                    width: 200,
                                    marginRight: '10px'
                                }}
                            >
                                {
                                    vehicles
                                        .sort((a, b) => {
                                            const A = a.vehicleDisplayName.toLowerCase();
                                            const B = b.vehicleDisplayName.toLowerCase();
                            
                                            if (A < B) return -1;
                                            if (A > B) return 1;
                                            return 0;
                                        })
                                        .map((currVehicle, index) => {
                                            return (
                                                <Option key = {index} value = {currVehicle.vid}>{currVehicle.vehicleDisplayName}</Option>
                                            )
                                        })
                                }
                            </Select>

                            <span style = {{ marginLeft: 10, marginRight: 5 }}>Start time:</span>
                            <DatePicker defaultValue = {startTime} onChange = {this.startTimeChange} showTime />

                            <span style = {{ marginLeft: 10, marginRight: 5 }}>End time:</span>
                            <DatePicker defaultValue = {endTime} onChange = {this.endTimeChange} showTime />

                            <Button
                                type = "primary"
                                disabled = {!selectedVID}
                                onClick = {this.submit}
                                loading = {this.props.style.isLoadingSubmit}
                                style = {{
                                    marginLeft: '15px',
                                    marginRight: '10px'
                                }}
                            >
                                Submit
                            </Button>

                            {/* <Button 
                                    type = "primary" 
                                    disabled = {!selectedVID} 
                                    onClick = {() => this.props.dispatch(goBackToPrev())} 
                                    loading = {this.props.style.isLoadingSubmit} 
                                    style = {{
                                        marginLeft:'15px', 
                                        marginRight:'10px'
                                    }}
                                >
                                    Back
                                </Button> */}

                            {/* Export Button */}
                            {
                                Object.keys(vehicleLog.byVID).length > 0
                                && vehicleLog.byVID[selectedVID] ?
                                    <ExcelFile
                                        filename = {`${selectedVehicle && selectedVehicle.vehicleDisplayName} - Logs Inspection - ${moment(startTime).format('YYYY-MM-DD HH:mm')} to ${moment(endTime).format('YYYY-MM-DD HH:mm')}`}
                                        element = {
                                            (
                                                vehicleLog.byVID[selectedVID] &&
                                                <Button
                                                    type = "primary"
                                                    style = {{ marginLeft: "10px" }}
                                                >
                                                    Export
                                                        </Button>
                                            ) ||
                                            <Button type = "primary" disabled = {true}>Export</Button>
                                        }
                                    >
                                        <ExcelSheet
                                            name = "Logs Inspection"
                                            data = {vehicleLog.byVID[selectedVID]}
                                        >
                                            <ExcelColumn
                                                label = "Server Time"
                                                value = {(col) => moment(col.updateAt).format('YYYY-MM-DD HH:mm:ss')}
                                            />

                                            <ExcelColumn
                                                label = "Vehicle Time"
                                                value = {(col) => moment(col.vehicleTime).format('YYYY-MM-DD HH:mm:ss')}
                                            />

                                            <ExcelColumn
                                                label = "Address"
                                                value = {(col) => col.address}
                                            />

                                            <ExcelColumn
                                                label = "Trip Status"
                                                value = {(col) => col.tripStatus}
                                            />

                                            <ExcelColumn
                                                label = "Trip From"
                                                value = {(col) => moment(col.tripFrom).format('YYYY-MM-DD HH:mm:ss')}
                                            />

                                            <ExcelColumn
                                                label = "Transit Status"
                                                value = {(col) => col.transitStatus}
                                            />

                                            <ExcelColumn
                                                label = "Transit From"
                                                value = {(col) => moment(col.transitFrom).format('YYYY-MM-DD HH:mm:ss')}
                                            />

                                            <ExcelColumn
                                                label = "Mileage"
                                                value = {(col) => col.mileage}
                                            />
                                        </ExcelSheet>
                                    </ExcelFile> :
                                    <Button type = "primary" disabled = {true}>Export</Button>
                            }
                        </div>
                    }

                    <Table
                        pagination = {{ pageSize: 25 }}
                        loading = {this.props.style.isLoadingSubmit}
                        columns = {columnsGPSLog}
                        scroll = {{
                            y: window.innerHeight
                        }}
                        dataSource = {
                            (
                                vehicleLog.byVID[selectedVID] &&
                                vehicleLog.byVID[selectedVID].map((log, index) => (
                                    {
                                        key: index,
                                        createdAt: moment(log.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                                        vehicleTime: moment(log.vehicleTime).format("YYYY-MM-DD HH:mm:ss"),
                                        address: log.address,
                                        tripStatus: log.tripStatus,
                                        tripFrom: moment(log.tripFrom).format("YYYY-MM-DD HH:mm:ss"),
                                        transitStatus: log.transitStatus,
                                        transitFrom: moment(log.transitFrom).format("YYYY-MM-DD HH:mm:ss"),
                                        mileage: log.mileage,
                                    }
                                ))
                            )
                            || []
                        }
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    hierarchy: state.v2.hierarchy,
    vehicleLog: state.v2.vehicleLog,
});

const ConnectedVehicleInspectorPage = connect(mapStateToProps)(VehicleLogInspectorPage);
export default showSecondarySidebar(false)(ConnectedVehicleInspectorPage);