import React, { 
    useEffect, 
    // useState 
} from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { getLatLngCenter } from "../methods/getLatLngCenter";
import { DEFAULT_GEOFENCE_HEXCODE } from "../../../../../constants";
import {
    Polygon,
    OverlayView,
} from '@react-google-maps/api';

// Redux Actions
import { set_map_control } from '../../../services/redux/actions/mapControl';
import { clear_enabled_geofence_pop_ups } from "../../../services/redux/actions/mapControl";
import {
    set_selected_geofence_geoid,
    clear_selected_geofence_geoid
} from '../../../services/redux/actions/geofences';

import "../gmaps.css";

const PolygonGeofences = (props) => {
    const { mapRef } = props;

    const vehicles = useSelector(state => state.v2.vehicles);
    const geofences = useSelector(state => state.v2.geofences);
    const mapControl = useSelector(state => state.v2.mapControl);
    const geofenceTemplates = useSelector(state => state.v2.geofenceTemplate);

    const dispatch = useDispatch()

    const returnLatestGeofenceTemplateColorForGeoID = (geoID) => {
        let latestTemplate = {};

        Object.values(geofenceTemplates.byId)
            .filter((currTemplate) => currTemplate.geofences.includes(geoID)) // Only check geofence templates containing this geofence ID
            .forEach((currTemplate) => {
                if (!latestTemplate.createdAt || currTemplate.createdAt > latestTemplate.createdAt) {
                    latestTemplate = currTemplate;
                }
            })

        // console.log("Color:", latestTemplate.colorHexCode);

        return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }

    useEffect(() => {
        if (
            geofences.selectedId
            && geofences.byId[geofences.selectedId]
            && geofences.byId[geofences.selectedId].geoType === 'Polygon'
            && geofences.byId[geofences.selectedId].coordinates
            && mapControl.mapControl === geofences.selectedId
        ) {
            if (mapRef) {
                // const location = getLatLngCenter(geofences.byId[geofences.selectedId].coordinates)
                // mapRef.panTo(location);

                const bounds = new window.google.maps.LatLngBounds()
                geofences.byId[geofences.selectedId].coordinates.map(c => bounds.extend(c))
                mapRef.fitBounds(bounds)

                dispatch(set_map_control(0))
            }
        }
    })

    // Initial mount of component
    useEffect(() => {
        dispatch(clear_enabled_geofence_pop_ups());
        dispatch(clear_selected_geofence_geoid());
    },
        [dispatch]
    )

    const getPolygon = (geofence) => {
        const polygon = (
            <div key = {geofence.geoid}>
                <Polygon
                    path = {geofence.coordinates}
                    options = {{
                        // strokeColor: 'black'
                        strokeColor: returnLatestGeofenceTemplateColorForGeoID(geofence.geoid)
                    }}
                    onClick = {() => {
                        // console.log('polygon clicked', geofence.geoid);

                        dispatch(set_selected_geofence_geoid(geofence.geoid));
                        dispatch(set_map_control(geofence.geoid));
                    }}
                />

                {
                    mapControl.uiControl.showAllGeofencesInfoWindow &&
                    <OverlayView
                        options = {{ disableAutoPan: true }}
                        position = {getLatLngCenter(geofence.coordinates)}
                        mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset = {(width, height) => ({
                            x: -(width / 2),
                            y: -height - 3,
                        })}
                    >
                        <div
                            style = {{
                                background: 'white',

                                border: '1px solid #ccc',
                                borderRadius: '10px',

                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 8,
                                paddingBottom: 1,
                            }}
                        >
                            <h4>{geofence.geofenceName}</h4>
                            <div>{geofence.geofenceComment}</div>
                        </div>
                    </OverlayView>
                }
            </div>
        )

        return polygon;
    }

    return (
        vehicles &&
        geofences.allIds
            .filter(geoid => {
                return mapControl.uiControl.showAllGeofence && geofences.byId[geoid].geoType === 'Polygon';
            })
            .map(geoid => {
                return getPolygon(geofences.byId[geoid]);
            })
    )
}


export default PolygonGeofences