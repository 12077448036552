import { config } from '../../../../config';

const api = config.api;
const headers = {
  'Accept': '*/*',
  'Content-Type': 'application/json; charset=utf-8'
}

export const reportMileage = (vidList, startTime, endTime) => (
    fetch(`${api}/report/mileage`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vidList,
            endTime,
            startTime,
        })
    })
    .then(res => res.json())
)

export const reportTrip = (vidList, startTime, endTime) => (
    fetch(`${api}/report/trip`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vidList,
            endTime,
            startTime,
        })
    })
    .then(res => res.json())
)

export const reportTransit = (vidList, startTime, endTime) => (
    fetch(`${api}/report/transit`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vidList,
            endTime,
            startTime,
        })
    })
    .then(res => res.json())
)

export const reportDetail = (dvidList, startTime, endTime) => (
    fetch(`${api}/report/detail`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            dvidList,
            endTime,
            startTime,
        })
    })
    .then(res => res.json())
)

export const reportGeofence = (vidList, geoidList, startTime, endTime) => (
    fetch(`${api}/report/geofence`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vidList,
            geoidList,
            endTime,
            startTime,
        })
    })
    .then(res => res.json())
)

export const reportEvent = (vidList, startTime, endTime) => (
    fetch(`${api}/report/event`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vidList,
            endTime,
            startTime,
        })
    })
    .then(res => res.json())
)

export const reportVehicle = (vidList, startTime, endTime) => (
    fetch(`${api}/report/vehicle`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            vidList,
            endTime,
            startTime,
        })
    })
    .then(res => res.json())
)