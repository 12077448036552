import * as COLOR from "./color";
import * as IMAGE_RESOURCE from "./img";
import {config} from '../config';

const GEOFENCE_COLORS = [
    "#5C1A8E", // This is default and therefore first
    "#FF0000",
    "#00FF00",
    "#00FFE8",
    "#FF00FF",
    "#FFA500",
    "#FFFF00",
]

const DEFAULT_GEOFENCE_HEXCODE = GEOFENCE_COLORS[0];

const VEHICLE_STATUS = {
    ALL: "ALL",
    EVENT: "EVENT",
    MOVING: "MOVING",
    IDLING: "IDLING",
    PARKING: "PARKING",
    OFFLINE: "OFFLINE",
    INACTIVE: "INACTIVE",
    STOP: "ENGINE STARTED",
    DISCONNECTED: "DISCONNECTED",
    NULL: "NULL", // In case vehicle transit status does not return truthy
};

const TRIP_STATUS = {
    TRANSIT:`TRANSIT`,
    PARKING: `PARKING`,
}

const MAP = {
    MARKER: 'marker',
    CIRCLE: 'circle',
    POLYGON: 'polygon',
    MINZOOM: 3
}

const VEHICLE_COLOR = {
    [VEHICLE_STATUS.ALL]: "#C4C4C4",
    [VEHICLE_STATUS.STOP]: "#856B37", //old color: #2A2AFF
    [VEHICLE_STATUS.EVENT]: "#81201B", //old color: #2A2AFF
    [VEHICLE_STATUS.MOVING]: "#52C41A", //old color: #37c837
    [VEHICLE_STATUS.IDLING]: "#FFB517", //old color: #7F2AFF
    [VEHICLE_STATUS.PARKING]: "#0488DB", //old color: #D4FF2A
    [VEHICLE_STATUS.OFFLINE]: "#333333", //black
    [VEHICLE_STATUS.INACTIVE]: "#ff7f2a", //orange
    [VEHICLE_STATUS.DISCONNECTED]: "#81201B", 
    [VEHICLE_STATUS.NULL]: "#333333", //black
};

const VEHICLE_TYPE = [
    { label: "Bus", value: "Bus" },
    { label: "Four Wheel Drive", value: "4x4Drive" },
    { label: "Motocar", value: "Motocar" },
    { label: "Motocycle", value: "Motocycle" },
    { label: "Taxi", value: "Taxi" },
    { label: "Truck", value: "Truck" },
    { label: "Van", value: "Van" },
    { label: "Other", value: "Other" },
];

const STATUSICONS = {
    [VEHICLE_STATUS.ALL]: "icon_all.svg",
    [VEHICLE_STATUS.PARKING]: "icon_parking.svg",
    [VEHICLE_STATUS.DISCONNECTED]: "icon_start.svg",
    [VEHICLE_STATUS.MOVING]: "iconmonstr-navigation-1.svg",
    [VEHICLE_STATUS.IDLING]: "iconmonstr-media-control-49.svg",
}

const FEATURE = {
    DEV: "Dev",
    POI: "POI",
    USERS: "Users",
    ABOUT: "About",
    CASES: "Cases",
    REPORT: "Report",
    PROFILE: "Profile",
    GEOFENCE: "Geofence",
    MANAGEMENT: "Management",
    PREFERENCES: "references",
    EVENT_RECORD: "EventRecord",
    TRIP_RECORD: "TripRecord",
    CALLED_REPORT: "CalledReport",
    NOTIFICATIONS: "Notifications",
    FLEET_TRACKING: "FleetTracking",
    USER_MANAGEMENT: "UserManagement",
    GEOFENCE_RECORD: "GeofenceRecord",
    VEHICLE_DISPLAY: "VehicleDisplay",
    VEHICLE_ACTIVITY: "VehicleActivity",
    OPERATOR_ACCOUNT: "OperatorAccount",
    GOSUN_INSPECTOR: "GosunDataInspector",
    DEVICE_MANAGEMENT: "DeviceManagement",
    VEHICLE_MANAGEMENT: "VehicleManagement",
    CONFIGURATION_MANAGEMENT: "Configuration",
    FUEL_ANALYSIS: "FuelAnalysis",
    DEVICE_INSPECTOR: "DeviceInspector",
    MIGRATION: "Migration",
    ESCALATION: "SOSAlerts",
    VEHICLE_REPORT: "VehicleReport",
};

const COMPANY = {
    NAME: 'Perodua',
    ADDRESS: 'Not Available'
}

const SCREENLAYOUT = {
    FULL_SIDEBAR_WIDTH: 400, // window.innerWidth*0.2, //Usual 400px,
    EXPAND_SIDEBAR_WIDTH: 700 // window.innerWidth*0.3 //Usual 400px,
}

 const ROLE_TYPE = {
    SUPER: "super",
    SUPPORT: "support",
    FLEET_OPERATOR: "fleetOperator",
    FLEET_OWNER: "fleetOwner",
    DISTRIBUTOR: "distributor",
    FLEET_MANAGER: "fleetManager",
    UNKNOWN: "Unknown",
};

const ROLE_DEFINE = {
    SUPER: "Admin",
    DISTRIBUTOR: "Distributor",
    SUPPORT: "Support",
    FLEET_OWNER: "Fleet Owner",
    FLEET_MANAGER: "Fleet Manager",
    FLEET_OPERATOR: "Fleet Operator",
    UNKNOWN: "Unknown"
}

const FUEL_TYPE = {
    RON95: "RON95",
    RON97: "RON97",
    DIESEL: "DIESEL",
}

const DISPLAY_FUEL_TYPE = {
    RON95: "RON 95",
    RON97: "RON 97",
    DIESEL: "DIESEL",
}

const FUEL_EVENT_TYPE = {
    LOSS: "LOSS",
    REFUEL: "REFUEL",
}

switch(config.project) {
    default: {
        ROLE_TYPE.SUPER = 'super';
        ROLE_TYPE.DISTRIBUTOR = "distributor";
        ROLE_DEFINE.SUPER = "Admin";
        ROLE_DEFINE.DISTRIBUTOR = "Distributor";
        break;
    }
}

const  DISPLAY_USER_ROLE = (userRole) => {
    if (userRole === ROLE_TYPE.FLEET_OPERATOR){
      return ROLE_DEFINE.FLEET_OPERATOR
    }
    else if (userRole === ROLE_TYPE.FLEET_MANAGER){
      return ROLE_DEFINE.FLEET_MANAGER
    }
    else if (userRole === ROLE_TYPE.FLEET_OWNER){
      return ROLE_DEFINE.FLEET_OWNER
    }
    else if (userRole === ROLE_TYPE.DISTRIBUTOR){
      return ROLE_DEFINE.DISTRIBUTOR
    }
    else if (userRole === ROLE_TYPE.SUPER){
      return ROLE_DEFINE.SUPER
    }
}

const RULE_TYPE = {
    INFO: 0,
    WARNING: 1,
    CRITICAL: 2,
}

const RULE_TYPE_ANT = {
    INFO: 'info',
    WARNING: 'warn',
    CRITICAL: 'error',
}

const EVENT_TYPES = {
    ALL: "ALL",
    INFO: "INFO",
    WARNING: "WARNING",
    CRITICAL: "CRITICAL",
    CONTROL: "CONTROL"
};

const EVENT_COLOR = {
    [EVENT_TYPES.ALL]: "#C4C4C4",
    [EVENT_TYPES.INFO]: "#52C41A", //old color: #37c837
    [EVENT_TYPES.WARNING]: "#FFB517", //old color: #7F2AFF
    [EVENT_TYPES.CRITICAL]: "#0488DB", //old color: #D4FF2A
    [EVENT_TYPES.CONTROL]: "#856B37" //old color: #2A2AFF
};

const DISPLAY_RULE_TYPE = {
    0: "INFO",
    1: "WARNING",
    2: "CRITICAL",
}

const LOCATION = {
    lat: 4.527157,
    lng: 102.213207
}

const NOTIFICATION_TYPE = {
    "EMAIL": 0,
    "PUSH": 1,
    "SMS": 2,
    "CALL": 3,
}

const DISPLAY_NOTIFICATION_TYPE = {
    0: "EMAIL",
    1: "PUSH",
    2: "SMS",
    3: "CALL"
}

const DEVICE_MODEL = {
    GPS100: "GPS100",
    TEL100: "TEL100"
}

// console.log("App is running in mode 1")

const STAGE = {
    V2: 'V2'
}

const DEVICE_TRIGGERED_EVENTS = {
    SOS: 'SOS',
}

const TEMPLATE_URLS = {
    BULK_ADD_DEVICES: "https://gpsfleet-templates.s3-ap-southeast-1.amazonaws.com/Bulk+Upload+Device.xlsx",
    BULK_CREATE_GEOFENCES: "https://gpsfleet-templates.s3-ap-southeast-1.amazonaws.com/Bulk+Upload+Geofence.xlsx",
    BULK_CREATE_VEHICLE_TEMPLATE: ' https://gpsfleet-templates.s3-ap-southeast-1.amazonaws.com/Bulk+Create+Vehicle+Template.xlsx',
}

export {
    MAP,
    COLOR,
    COMPANY,
    FEATURE,
    LOCATION,
    ROLE_TYPE,
    RULE_TYPE,
    RULE_TYPE_ANT,
    SCREENLAYOUT,
    VEHICLE_TYPE,
    VEHICLE_COLOR,
    VEHICLE_STATUS,
    TRIP_STATUS,
    STATUSICONS,
    IMAGE_RESOURCE,
    DISPLAY_USER_ROLE,
    FUEL_TYPE,
    DISPLAY_FUEL_TYPE,
    FUEL_EVENT_TYPE,
    DISPLAY_RULE_TYPE,
    NOTIFICATION_TYPE,
    DISPLAY_NOTIFICATION_TYPE,
    DEVICE_MODEL,
    STAGE,
    EVENT_TYPES,
    EVENT_COLOR,
    DEVICE_TRIGGERED_EVENTS,
    TEMPLATE_URLS,
    
    // Geofence Colors
    GEOFENCE_COLORS,
    DEFAULT_GEOFENCE_HEXCODE,
}
