import {
    config
} from "../../../../config";

const wsURL = config.wsURL;
const wsReconnectInterval = config.wsReconnectInterval;

const closeWS = (client) => {
    // console.log(`closeWS`, new Date)
    client && client.close(4500);
}

const wsClient = (
    client,
    restart = () => { },
    cb = () => { }
) => {

    // console.log('websocket connect', new Date)
    if (client) {
        window.application = {
            close: () => closeWS(client),
            client: client,
        }

        
        client.onopen = async () => {
         }

        client.onmessage = async (event) => {
            cb(
                null,
                JSON.parse(event.data)
            );
        }

        client.onclose = async (e) => {
            // console.log(`onclose`, e, new Date);

            switch (e.code) {
                case 1000:	// CLOSE_NORMAL
                break
                case 4500:
                default:	// Abnormal closure
                    restart('reconnect', 0);
                    break;
            }
        }

        client.onerror = async (e) => {
            // console.log(`onerror`, e, new Date);

            switch (e.code) {
                case 'ECONNREFUSED':
                    restart('reconnect', 0);
                    break;
                default:
                    // console.log("[WS] Error", e);
                    break;
            }
        }
    }
}

export {
    closeWS,
    wsClient,
    wsReconnectInterval,
    wsURL
}