import * as actionType from '../action-types/migration';

const defaultState = {
    userList: [],
    selectedUser: {},
};

export const migration = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case actionType.GET_STAGE: {
            newState.userList = 
                action.data
                .filter((currUser) => currUser.uid && currUser.stage && currUser.userName)
                // .filter((currUser) => currUser.stage.toLowerCase() === "v1")
                .sort((a, b) => {
                    const aName = a.userName.toLowerCase();
                    const bName = b.userName.toLowerCase();
                    
                    if (aName < bName) return -1;
                    if (aName > bName) return 1;
            
                    return 0;
                })
                .sort((a, b) => {     
                    const aStage = a.stage.toLowerCase();
                    const bStage = b.stage.toLowerCase();

                    if (aStage < bStage) return -1;
                    if (aStage > bStage) return 1;
            
                    return 0;
                });
            
            return newState;
        }

        case actionType.MIGRATE_USER: {
            newState.userList.push({
                ...action.data,
                stage: action.targetStage
            })

            return newState;
        }

        case actionType.SET_USER_TO_MIGRATE: {
            newState.selectedUser = action.selectedUser

            return newState;
        }

        default: {
            return state;
        }
    }
}