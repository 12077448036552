import moment from 'moment';

const filterDataSource = (defaultFilter, state) => {
    let newDataSource = state.dataSource;

    switch(state.currTable) {
        case "EVENT": {
            newDataSource = 
                newDataSource.filter((currData) => state.selectedEventTypes.includes(defaultFilter) || state.selectedEventTypes.includes(currData.eventType));
            
            break;
        }

        case "GEOFENCE": {
            newDataSource = 
                newDataSource
                .filter((currData) => state.selectedGeofenceFilters.includes(defaultFilter) || state.selectedGeofenceFilters.includes(currData.geoid))
                .filter((currData) => (state.selectedDuration === defaultFilter || (!isNaN(currData.inAt) && !isNaN(currData.outAt) && (moment(currData.outAt).valueOf() - moment(currData.inAt).valueOf()) > (state.selectedDuration * 60000))));
            
            break;
        }
        default: return null
    }

    return newDataSource;
}

export default filterDataSource;