import React from "react";
import { connect } from "react-redux";

import Page from "../../components/Page";
import VehicleDisplayPanel from "./VehicleDisplayPanel";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { EmptyIndicator } from "../../components/EmptyIndicator";

class VehicleDisplayPage extends React.Component {
    render() {
        return(
            <div className = "page-container">
                <Page title = "Vehicle Information Table">
                    {
                        this.props.user.uid ?
                            <VehicleDisplayPanel uid = {this.props.user.uid}/> : 
                            <EmptyIndicator/>
                    }
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user
});

const ConnectedVehicleDisplayPage = connect(mapStateToProps)(VehicleDisplayPage);
export default showSecondarySidebar(false)(ConnectedVehicleDisplayPage);