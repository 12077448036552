import * as actionTypes from "../action-types/managementControl";

const defaultState = {
    currTab: ""
};

export const managementControl = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case actionTypes.SET_MANAGEMENT_TAB: {
            return {
                ...newState,
                currTab: action.newTab,
            };
        }

        case actionTypes.CLEAR_MANAGEMENT_TAB: {
            return {
                ...newState,
                currTab: "",
            };
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Management Control Store:", defaultState);
    
            return defaultState;
        }

        default: {
            return state;
        }
    }
}