import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import Highlighter from 'react-highlight-words';

import { moveToPage } from '../../navigation/navigationService';
import {
    Tag,
    Input,
    Table,
    Modal,
    Button,
} from 'antd';
import {
    EyeOutlined, 
    CheckOutlined,
    PhoneOutlined,
    SearchOutlined,
    LoadingOutlined,
} from '@ant-design/icons';

// Redux Actions
import { resolve_ticket_states } from '../../services/redux/actions/ticketStates';

const GPSFLEET_SUPPORT_NUMBER = `60182263008`;

// const END_OF_DAY = moment().endOf('day').valueOf();
// const START_OF_DAY = moment().startOf('day').valueOf();

const EVENT_STATUS_FILTER_BUTTONS = {
    INFO: 'info',
    WARNING: 'warning',
    CRITICAL: 'critical',
}

class Tickets extends Component {
    state = {
        columns: [],
        dataSource: [],
        ticketEventStatusFilters: Object.values(EVENT_STATUS_FILTER_BUTTONS),
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style = {{ padding: 8 }}>
                <Input
                    ref = {(node) => { this.searchInput = node }}
                    placeholder = {`Search ${dataIndex}`}
                    value = {selectedKeys[0]}
                    onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter = {() => this.handleSearch(selectedKeys, confirm)}
                    style = {{
                        width: 188,
                        marginBottom: 8,
                        display: 'block',
                    }}
                />

                <Button
                    type = "primary"
                    icon = {<SearchOutlined/>}
                    size = "small"
                    onClick = {() => this.handleSearch(selectedKeys, confirm)}
                    style = {{
                        width: 90,
                        marginRight: 8,
                    }}
                >
                    Search
                </Button>

                <Button
                    size = "small"
                    style = {{ width: 90 }}
                    onClick = {() => this.handleReset(clearFilters)}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style = {{ color: filtered ? '#1890ff' : undefined }}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                autoEscape
                textToHighlight = {text ? text : ""}
                searchWords = {[this.state.searchText]}
                highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();

        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();

        this.setState({ searchText: '' });
    };

    markAcknowledge = ticketIds => {
        if (!ticketIds) return

        const userName = this.props.user.userName
        let remark = `Tickets acknowledged on ${moment().format('LLLL')} by ${userName}`;

        return (
            Modal.confirm({
                width: 700,
                title: `Resolve tickets (${ticketIds.length})`,
                content: (
                    <div>
                        <h3>Remarks</h3>
                        <Input.TextArea
                            rows = {4}
                            onChange = {e => {
                                remark = e.target.value
                            }}
                            defaultValue = {remark}
                        />
                    </div>
                ),
                onOk: () => {
                    const ticketStates = ticketIds.map(ticketId => this.props.ticketStates.byTicketId[ticketId])
                    this.props.dispatch(resolve_ticket_states(ticketStates, remark, this.props.user.uid))
                },
                // onCancel: () => { },
            })
        )
    }


    contactSupport = ticketIds => {
        ticketIds = ticketIds.map(ticketId => ticketId.split('#').pop()).join(',')

        const message = `Hi%21%20I%27m%20having%20trouble%20with%20tickets:%20${ticketIds}`;

        window.open(`https://api.whatsapp.com/send?phone=${GPSFLEET_SUPPORT_NUMBER}&text=${message}`, '_blank');
    }

    getTicketsSummary = () => {
        const { dataSource } = this.state;

        const dataSourceByEventType = {
            [EVENT_STATUS_FILTER_BUTTONS.INFO]: 0,
            [EVENT_STATUS_FILTER_BUTTONS.WARNING]: 0,
            [EVENT_STATUS_FILTER_BUTTONS.CRITICAL]: 0,
        }

        dataSource.map(d => {
            if (!dataSourceByEventType[d.eventType]) {
                dataSourceByEventType[d.eventType] = 0
            }

            dataSourceByEventType[d.eventType]++;

            return null;
        })

        return (
            <h3>Total number of tickets: {dataSource.length}</h3>
        )
    }

    setUpDatasource = () => {
        const {
            events,
            devices,
            vehicles,
            ticketStates,
        } = this.props;

        // console.log({ START_OF_DAY, END_OF_DAY })

        const dataSource = 
            Object.values(ticketStates.byTicketId)
            // .filter(ticketState => {
            //     try {
            //         /**filter by today */
            //         if (ticketState.createdAt > END_OF_DAY) throw `endTime`;
            //         if (ticketState.createdAt < START_OF_DAY) throw `startTime`;

            //         /**filter by selected device */
            //         if (devices.selectedId) {
            //             const vehicle = vehicles.byId[ticketState.vid];
            //             const dvid = vehicle && vehicle.dvid;

            //             if (dvid === devices.selectedId) throw `dvid`;
            //         }

            //         return true
            //     }
            //     catch (err) {
            //         return false
            //     }
            // })
            .filter((ticketState) => !devices.selectedId || (vehicles.byId[ticketState.vid] && vehicles.byId[ticketState.vid].dvid && vehicles.byId[ticketState.vid].dvid === devices.selectedId))
            .sort((a, b) => b.createdAt - a.createdAt)
            .map(ticketState => {
                const {
                    vid,
                    evid
                } = ticketState

                const vehicle = vehicles.byId[vid];
                const event = events.byId[vid] && events.byId[vid][evid];
                const address = event && event.location && JSON.stringify(event.location) && this.props.address.byLocation[JSON.stringify(event.location)];

                return {
                    key: ticketState.ticketId,
                    evid: evid,
                    vehiclePlate: vehicle && `${vehicle.vehicleDisplayName} (${vehicle.vehiclePlate})`,
                    eventName: event && event.eventName,
                    eventType: event && event.eventType,
                    location: event && event.location,
                    address: address,
                    createdAt: ticketState.createdAt,
                }
            })

        const columns = [
            {
                title: 'Date',
                dataIndex: 'createdAt',
                render: (date) => moment(date).format('LLLL')
            },
            {
                title: 'Vehicle',
                dataIndex: 'vehiclePlate',
                ...this.getColumnSearchProps('vehiclePlate')
            },
            {
                title: 'Event Type',
                dataIndex: 'eventType',
                render: eventType => {
                    if (Object.keys(events.byId).length === 0 && this.props.style.isLoadingSubmit) return <LoadingOutlined />

                    if (eventType) {
                        eventType = eventType.toUpperCase();
    
                        let color = 'black';
    
                        switch (eventType.toLowerCase()) {
                            case EVENT_STATUS_FILTER_BUTTONS.INFO:
                                color = 'blue'
                                break
                            case EVENT_STATUS_FILTER_BUTTONS.CRITICAL:
                                color = 'red'
                                break
                            case EVENT_STATUS_FILTER_BUTTONS.WARNING:
                                color = 'yellow'
                                break
                            default:
                                color = 'white'
                                break
                        }
    
                        return <Tag color = {color}>{eventType}</Tag>
                    }
                },
                filters: Object.values(EVENT_STATUS_FILTER_BUTTONS).map(type => ({ text: type, value: type })),
                onFilter: (value, record) => record.eventType && record.eventType.toLowerCase().indexOf(value) === 0,
            },
            {
                title: 'Event',
                dataIndex: 'eventName',
                render: eventName => {
                    if (Object.keys(events.byId).length === 0 && this.props.style.isLoadingSubmit) return <LoadingOutlined />

                    return eventName;
                },
                ...this.getColumnSearchProps('eventName')
            },
            {
                title: 'View on map',
                dataIndex: 'location',
                render: (location, rowData) => {
                    if (!location) return 'N\\A'
                    else return (
                        <EyeOutlined 
                            style = {{ fontSize: 20 }}
                            onClick = {() => {
                                // console.log(rowData.key)
                                
                                this.props.dispatch(moveToPage('/', { ticketId: rowData.key }))
                            }}
                        />
                    )
                }
            },
            {
                title: 'Action',
                render: (ticketState) => {
                    return (
                        <Button
                            onClick = {() => {
                                this.markAcknowledge([ticketState.key]);
                            }}
                        >
                            Mark resolved
                        </Button>
                    );
                }
            }
        ]
        this.setState({
            columns,
            dataSource,
        })
    }

    componentDidMount = () => {
        this.setUpDatasource()
    }

    componentDidUpdate = (prevProps) => {
        if (
            JSON.stringify(prevProps.events) !== JSON.stringify(this.props.events)
            || JSON.stringify(prevProps.devices) !== JSON.stringify(this.props.devices)
            || JSON.stringify(prevProps.ticketStates) !== JSON.stringify(this.props.ticketStates)
        ) {
            this.setUpDatasource()
        }
    }

    render() {
        const {
            columns,
            dataSource,
        } = this.state;

        ;

        return (
            <div>
                <div
                    style = {{
                        display: 'flex',

                        padding: 5,
                        backgroundColor: 'white',
                    }}
                >
                    {this.getTicketsSummary()}

                    {
                        <div
                            style = {{
                                flex: 5,

                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                icon = {<CheckOutlined/>}
                                disabled = {!this.state.ticketIds || !this.state.ticketIds.length}
                                onClick = {() => this.markAcknowledge(this.state.ticketIds)}
                            >
                                Mark resolved
                            </Button>

                            <Button
                                icon = {<PhoneOutlined/>}
                                disabled = {!this.state.ticketIds || !this.state.ticketIds.length}
                                onClick = {() => this.contactSupport(this.state.ticketIds)}
                            >
                                Contact Support
                            </Button>
                        </div>
                    }
                </div>

                <Table
                    columns = {columns}
                    dataSource = {dataSource}
                    loading = {this.props.style.isLoadingSubmit}
                    rowSelection = {{
                        onChange: (ticketIds) => {
                            this.setState({ ticketIds })
                        }
                    }}
                    scroll = {{
                        x: columns && columns.length * 100,
                        y: window.innerHeight * 0.2
                    }}
                    style = {{
                        flex: 1,
                        
                        backgroundColor: 'white',
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.v2.user,
    style: state.v2.style,
    events: state.v2.events,
    address: state.v2.address,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    mapControl: state.v2.mapControl,
    ticketStates: state.v2.ticketStates,
});

export default connect(mapStateToProps)(Tickets);