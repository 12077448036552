import * as API from "../../api";

import { message } from "antd";
import { subscribeWS } from '../../websocket';
import { update_user_hierarchy_devices } from './hierarchy';
import { goBackToPrev } from "../../../navigation/navigationService";
import { 
  setLoadingSubmit, 
  unsetLoadingSubmit,
} from './style';

export const get_devices_data = (devices) => {
  // console.log("Get Devices Data:", devices);

  return {
    type: "GET_DEVICES",
    devices: devices
  }
}

export const get_devices_by_vgid = (uid, vgids) => (dispatch) => {
  // console.log("VGIDs:", vgids);

  API.getDeviceByVGIDs(uid, vgids)
  .then(data => {
    // console.log("Get Devices By VGID Data:", data);

    if (data.status !== 200 && data.message) {
      message.error(data.message);
    }

    if (data.status === 200) {
      if(data.devices && data.devices.length > 0) {
        let dvids = data.devices.map(device => `dev#${device.dvid}`);
        
        subscribeWS(dvids, uid);
      }
  
      data.devices && dispatch(get_devices_data(data.devices));
    }
    
    dispatch(unsetLoadingSubmit());
  })
}

export const add_new_device = (uid, dgid, device) => dispatch =>  {
  dispatch(setLoadingSubmit());

  // console.log("UID", uid);
  // console.log("device", device);

  API.addDevice(dgid, device)
  .then(data => {
    // console.log("Add Device Data", data);

    if (data.status !== 200 && data.message) {
      message.error(data.message);
    }
    
    if (data.status === 200) {
      message.success("Successfully Added New Device");
  
      dispatch(add_new_device_success(data.device));
      dispatch(update_user_hierarchy_devices(uid, data.device));

      dispatch(goBackToPrev());
    }
    
    dispatch(unsetLoadingSubmit());
  })
  .finally(()=>{

    dispatch(unsetLoadingSubmit());
  })
}

export const add_new_device_success = (device) => {
  return{
    type: "ADD_NEW_DEVICE_SUCCESS",
    device: device
  }
}

export const edit_device = (uid, device) => dispatch =>  {
  dispatch(setLoadingSubmit());

  // console.log("Edited Device:", device);

  API.editDevice(device)
  .then(data => {
    // console.log("Edit Device Data:", data);

    if (data.errorMessage) {
      message.error(data.errorMessage);
    }
    else {
      message.success("Successfully Edited the Device");

      dispatch(edit_device_success(data.device));
      dispatch(update_user_hierarchy_devices(uid, data.device));

      dispatch(goBackToPrev());
    }
    
    dispatch(unsetLoadingSubmit());
  })
  .finally(()=>{
    dispatch(unsetLoadingSubmit());
  })
}

export const edit_device_success = (device) => {
  return {
    type: "EDIT_DEVICE_SUCCESS",
    device: device
  }
}

export const get_selected_device = () => {
  return {
      type: "GET_SELECTDEVICE",
  };
}

export const clear_selected_device = () => {
  return {
    type: "CLEAR_SELECTED_DEVICE",
  };
}

export const set_selected_device = (dvid) => {
  return {
    type: "SET_SELECTED_DEVICE",
    dvid: dvid
  }
}

// export const get_devices_data_admin = (devices) => {
//   return {
//     type: "GET_DEVICES_ADMIN",
//     devices: devices
//   }
// }

export const update_device = (device) => {
  return {
    type: "UPDATE_DEVICE",
    device: device
  }
}