import * as actionType from "../action-types/fuelRecord";

const defaultState = {
    byVID: {},
    byFRID: {},

    currFuelUnitPrice: 0,
    movingFuelConsumption: {},
    idlingFuelConsumption: {},

    selectedFRID: "",

    fuelRecordDeviceLog: {
        byDVID: {},
    },
};

export const fuelRecord = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case actionType.GET_FUEL_RECORD: {
            newState = JSON.parse(JSON.stringify(defaultState));

            // console.log("Get Fuel Record Data:", action.data);

            // Create empty arrays
            newState.byVID = {};
            newState.byFRID = {};
            newState.fuelRecordDeviceLog.byDVID = {};

            newState.currFuelUnitPrice = action.data.fuelUnitPrice;
            newState.movingFuelConsumption = action.data.movingFuelConsumption;
            newState.idlingFuelConsumption = action.data.idlingFuelConsumption;

            if (action.data.fuelRecords && action.data.fuelRecords.length > 0) {
                action.data.fuelRecords.forEach(currRecord => {
                    // console.log("Current Record:", currRecord);

                    newState.byFRID[currRecord.frid] = currRecord;

                    if (!newState.byVID[currRecord.vid]) {
                        newState.byVID[currRecord.vid] = [];
                    }

                    if (!newState.byVID[currRecord.vid].includes(currRecord.frid)) {
                        newState.byVID[currRecord.vid].push(currRecord.frid);
                    }
                })
            }

            if (action.data.deviceLog && action.data.deviceLog.length > 0) {
                // console.log("Test:", action.data.deviceLog);

                if (!newState.fuelRecordDeviceLog.byDVID[action.data.deviceLog[0].dvid]) {
                    newState.fuelRecordDeviceLog.byDVID[action.data.deviceLog[0].dvid] = [];
                }

                newState.fuelRecordDeviceLog.byDVID[action.data.deviceLog[0].dvid] = action.data.deviceLog;
            }

            return newState;
        }
        
        case actionType.UPDATE_FUEL_RECORD: {
            // console.log("Updated Fuel Record Data:", action.updatedFuelRecord);

            newState.byFRID[action.updatedFuelRecord.frid] = action.updatedFuelRecord;

            if (!newState.byVID[action.updatedFuelRecord.vid].includes(action.updatedFuelRecord.frid)) {
                newState.byVID[action.updatedFuelRecord.vid].push(action.updatedFuelRecord.frid);
            }

            return newState;
        }

        case actionType.SET_FUEL_RECORD_SELECTED_FRID: {
            // console.log("New Selected FRID:", action.selectedFRID);

            return {
                ...newState,
                selectedFRID: action.selectedFRID,
            };
        }

        case actionType.CLEAR_FUEL_RECORD_SELECTED_FRID: {
            return {
                ...newState,
                selectedFRID: "",
            };
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Fuel Record Store:", defaultState);

            return defaultState;
        }


        default: {
            return state;
        }
    }
}