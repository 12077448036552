import * as API from "../../api"
import * as actionType from "../action-types/user";

import { message } from 'antd'
import { closeWS } from '../../websocket';
import { get_devices_by_vgid } from './devices';
import { get_rule_template } from "./ruleTemplate";
import { get_devices_group } from './deviceGroups';
import { get_notifications } from './notifications';
import { get_vehicles_groups } from './vehicleGroups';
import { get_geofence_template } from './geofenceTemplate';
// import {
//     get_escalation_states,
//     get_escalation_records,
// } from './escalations';
// import { moveToPage } from '../../../../navigation/navigationService';
import {
    get_user_hierarchy,
    add_user_hierarchy,
    edit_user_hierarchy
} from './hierarchy';
import {
    setLoadingSubmit,
    unsetLoadingSubmit
} from './style';
import { ROLE_TYPE } from '../../../../../constants';
import { goBackToPrev } from '../../../navigation/navigationService';

export const user_sign_in_request = uid => dispatch => {
    API.getInfoByUser(uid)
        .then(info => {
            // console.log("user_sign_in_request info:", info);

            if (info.errorType === 'ValidationException') {
                message.error('Error. Refresh page?');
            }
            else {
                const {
                    user,
                    deviceGroup,
                    vehicleGroup,
                    ruleTemplate,
                    notification,
                    // notificationTemplate,
                    geofenceTemplate,
                } = info;

                // console.table(info)

                dispatch(get_user_hierarchy(uid));
                dispatch(get_rule_template(ruleTemplate));
                dispatch(get_notifications(notification));
                dispatch(get_vehicles_groups(vehicleGroup, uid));
                dispatch(get_geofence_template(geofenceTemplate, uid));

                switch (user.userRole) {
                    case ROLE_TYPE.SUPER:
                    case ROLE_TYPE.DISTRIBUTOR:
                        dispatch(get_devices_group(deviceGroup, uid));
                        break
                    default:
                        dispatch(get_devices_by_vgid(uid, vehicleGroup));
                        break
                }

                dispatch(user_sign_in_success(user));
            }
        })
}

export const user_sign_in_success = (user) => {
    return {
        type: actionType.USER_SIGN_IN,
        user
    };
}

export const user_sign_out = () => {
    closeWS();

    return {
        type: actionType.USER_SIGN_OUT
    };
}

export const create_new_user_request = (creator, newUser, uid) => (dispatch) => {
    dispatch(setLoadingSubmit())

    API.addUser(creator, newUser)
        .then(data => {

            if (data.status !== 200) {
                dispatch(unsetLoadingSubmit());

                message.error(data.errorMessage || data.message || 'Error creating user');
            }
            else {
                dispatch(unsetLoadingSubmit());

                dispatch(add_user_hierarchy(data.user, creator.uid));

                dispatch(get_user_hierarchy(uid))

                message.success("Successfully Added New Account");

                // dispatch(moveToPage("/UserManagement"));
            }
        })
}

export const edit_user_request = (editedUser) => dispatch => {
    dispatch(setLoadingSubmit());

    API.editUser(editedUser)
        .then(data => {
            // console.log("Edit User Action Data:", data);

            if (data && data.status !== 200) {

                message.error(`Error at editing user`);
            }
            else {

                message.success("Successfully edited user.");

                dispatch(edit_user_hierarchy(data.user));

                dispatch(goBackToPrev())
            }
        })
        .finally(() => {
            dispatch(unsetLoadingSubmit());
        })
}
