import React from "react";
import PropTypes from "prop-types";

import { 
  EVENT_TYPES, 
  EVENT_COLOR,
} from "../../../../../../constants";

import "./EventBadge.css";

const eventIcons = {
  ALL: "icon_all.svg",
  INFO: "icon-info.svg",
  WARNING: "icon-warning.svg",
  CRITICAL: "icon-critical.svg",
  CONTROL: "icon-control-24px.svg",
};

const EventBadge = ({ eventType, count, fontSize }) => {  
  return (
    <div>
      <div className = "centered-content">
        <div
          className = "status-icon-container centered-content"
          style = {{
            backgroundColor: EVENT_COLOR[eventType]
          }}
        >
          <img
            alt = {EVENT_TYPES[eventType]}
            src = {require(`../../../../../../img/${eventIcons[eventType] || eventIcons[EVENT_TYPES.ALL]}`)}
            style = {{
              width: "22px",
              height: "22px"
            }}
            // style = {{
            //   width: "36px",
            //   height: "36px"
            // }}
          />
        </div>
      </div>
      <p
        className = "status-count"
        style = {{ fontSize: fontSize + "px", lineHeight: fontSize + 2 + "px" }}
      >
        {eventType.slice(-8)} ({count})
      </p>
    </div>
  );
};

EventBadge.propTypes = {
  eventType: PropTypes.string,
  count: PropTypes.number,
  fontSize: PropTypes.number
};

EventBadge.defaultProps = {
  eventType: EVENT_TYPES.ALL,
  count: 0,
  fontSize: 12
};

export default EventBadge;
