export const user = (state = {}, action) => {
    switch (action.type) {
        case "V2_USER_SIGN_IN": {
            let user = action.user;
            return({
                ...state,
                ...user
            });
        }
        default: {
            return state;
        }
    }
}