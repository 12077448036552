import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { DEVICE_MODEL } from '../../../../../constants';
import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    message,
    Select,
} from "antd";

// Redux Actions
import { edit_device } from "../../../services/redux/actions/devices";

const FormItem = Form.Item;

class EditDevice extends Component {
    state = {
        editedDevice: {
            dvid: "",
            simCard: "",
            deviceImei: "",
            deviceModel: "",
            deviceBrand: "",
        }
    }

    submitForm = () => {
        this.setState({ isLoading: true });

        const {
            simCard,
            deviceImei,
            deviceModel,
            deviceBrand,
            editedDevice,
        } = this.state

        try {
            if (!deviceImei) throw new Error(`Device Imei cannot be blank`)
            if (!deviceModel) throw new Error(`Device Model cannot be blank`)
            if (this.isDeviceExist(deviceModel, deviceImei, editedDevice)) throw new Error(`Device already exist`)
            if (this.isSimCardExist(simCard, editedDevice)) throw new Error(`Sim Card is already in use`)

            const newDevice = {
                ...editedDevice,
                imei: deviceImei,
                simCard: simCard,
                model: deviceModel,
                brand: deviceBrand,
            }

            const uid = this.props.user.uid

            // console.log(uid, newDevice)

            this.props.dispatch(edit_device(
                uid,
                newDevice
            ))
        }
        catch (err) {
            (err.hasOwnProperty('message') && message.error(err.message)) || message.error(err)
        }
    }

    isSimCardExist = (simCard, editedDevice) => {
        if (editedDevice.simCard === simCard) return false
        return Object.values(this.props.devices.byId).find(device => device.simCard === simCard)
    }

    isDeviceExist = (deviceModel, deviceImei, editedDevice) => {
        const dvid = `${deviceModel}#${deviceImei}`;

        if (editedDevice.dvid === dvid) return false
        return this.props.devices.byId[dvid]
    }

    setFormDefaultValues = () => {
        const {
            device,
            targetUser
        } = this.props.router.location.state;

        // console.log("Test:", this.props.router.location.state);

        if (device && targetUser) {
            this.setState({
                ...this.state,
                editedDevice: device,
                dvid: device.dvid,
                simCard: device.simCard,
                deviceImei: device.dvid.split("#")[1],
                deviceModel: device.dvid.split("#")[0],
                deviceBrand: device.brand,
                targetUser: targetUser,
            })
        }
    }

    componentDidMount() {
        this.setFormDefaultValues();
    }

    componentDidUpdate = prevProps => {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setFormDefaultValues();
        }
    }

    render() {
        return (
            <div className="page-container">
                <Page title="Edit Device Information">
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                        <Form className="login-form">
                            <FormItem
                                label="Device Imei"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                help={`*Cannot be changed once created`}
                                required
                            >
                                <Input
                                    placeholder="IMEI Series No."
                                    disabled
                                    value={this.state.deviceImei}
                                    onChange={e =>
                                        this.setState({
                                            ...this.state,
                                            deviceImei: e.target.value,
                                        })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label="Device Model"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                help={`*Cannot be changed once created`}
                                required
                            >
                                <Select
                                    placeholder="Select a device model"
                                    disabled
                                    value={this.state.deviceModel}
                                    onChange={value =>
                                        this.setState({
                                            ...this.state,
                                            deviceModel: value,
                                        })
                                    }
                                >
                                    {
                                        Object.keys(DEVICE_MODEL).map((currModel) =>
                                            <Select.Option key={currModel} value={currModel}>
                                                {currModel}
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label="Device Brand"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="Select a device brand (Optional)"
                                    value={this.state.deviceBrand}
                                    onChange={e => {
                                        this.setState({
                                            ...this.state,
                                            deviceBrand: e.target.value,
                                        })
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                label="Sim Card"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder="+60123456789"
                                    value={this.state.simCard}
                                    onChange={e => {
                                        if (e.target.value.length < 2) {
                                            this.setState({
                                                ...this.state,
                                                simCard: "+6",
                                            })
                                        }
                                        else {
                                            this.setState({
                                                ...this.state,
                                                simCard: e.target.value,
                                            })
                                        }
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                label="Target user"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required
                                help={`Target user cannot be changed once set`}
                            >
                                <Select
                                    disabled
                                    showSearch
                                    value={this.state.targetUser}
                                    placeholder="Select a user"
                                    optionFilterProp="children"
                                    onChange={targetUser => {
                                        this.setState({ targetUser })
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        Object.values(this.props.hierarchy.byId).map(user => {
                                            return <Select.Option
                                                key={user.uid}
                                                value={user.uid}
                                            >
                                                {`${user.userName} (${user.userRole})`}
                                            </Select.Option>
                                        })
                                    }
                                </Select>
                            </FormItem>

                            <div style={{ position: "absolute", right: "100px" }}>
                                <Button
                                    type="danger"
                                    className="login-form-button"
                                    onClick={() => this.props.dispatch(goBackToPrev())}
                                    style={{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading={this.props.style.isLoadingSubmit && this.state.isLoading}
                                    onClick={this.submitForm}
                                >
                                    Save
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    hierarchy: state.v2.hierarchy,
    user: state.v2.user,
    style: state.v2.style,
    devices: state.v2.devices,
    router: state.router,
});

const ConnectedEditDevice = connect(mapStateToProps)(EditDevice);
export default showSecondarySidebar(false)(ConnectedEditDevice)