export const GET_GEOFENCE_TEMPLATE = "GET_GEOFENCE_TEMPLATE";
export const ADD_GEOFENCE_TEMPLATE = "ADD_GEOFENCE_TEMPLATE";
export const EDIT_GEOFENCE_TEMPLATE = "EDIT_GEOFENCE_TEMPLATE";

export const REASSIGN_GEOFENCE_TEMPLATES = "REASSIGN_GEOFENCE_TEMPLATES";
export const ASSIGN_GEOFENCES_TO_GEOFENCE_TEMPLATE = "ASSIGN_GEOFENCES_TO_GEOFENCE_TEMPLATE";
export const UNASSIGN_GEOFENCES_FROM_GEOFENCE_TEMPLATE = "UNASSIGN_GEOFENCES_FROM_GEOFENCE_TEMPLATE";

export const UPDATE_GEOFENCE_TEMPLATE_GEOFENCES = "UPDATE_GEOFENCE_TEMPLATE_GEOFENCES";

export const CLEAR_TEMP_NEW_GTID = "CLEAR_TEMP_NEW_GTID";