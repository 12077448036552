import * as actionTypes from "../action-types/tripRecordControl";

const defaultState = {
    isTripRecordEnabled: false,
    isTransitRecordEnabled: false,

    // Trip Record page search stuff
    searchStartTime: 0,
    searchEndTime: 0,
    selectedVID: "",
    selectedDVID: "",

    //Trip Record page selected trip stuff
    selectedEndTime: 0,
    selectedStartTime: 0,

    tripTimelineData: {},
    transitTimelineData: {}
};

export const tripRecordControl = (state = defaultState, action) => {
    const newStore = {
        isTripRecordEnabled: state.isTripRecordEnabled,
        isTransitRecordEnabled: state.isTransitRecordEnabled,

        searchStartTime: state.searchStartTime,
        searchEndTime: state.searchEndTime,
        selectedVID: state.selectedVID,
        selectedDVID: state.selectedDVID,

        selectedEndTime: state.selectedEndTime,
        selectedStartTime: state.selectedStartTime,

        tripTimelineData: state.tripTimelineData,
        transitTimelineData: state.transitTimelineData
    }

    switch (action.type) {
        case actionTypes.ENABLE_TRIP_RECORD: {
            newStore.isTripRecordEnabled = true;

            return newStore;
        }

        case actionTypes.DISABLE_TRIP_RECORD: {
            newStore.isTripRecordEnabled = false;

            return newStore;
        }

        case actionTypes.ENABLE_TRANSIT_RECORD: {
            newStore.isTransitRecordEnabled = true;
            
            return newStore;
        }

        case actionTypes.DISABLE_TRANSIT_RECORD: {
            newStore.isTransitRecordEnabled = false;
            
            return newStore;
        }

        case actionTypes.STORE_TRIP_TIMELINE_DATA: {
            newStore.tripTimelineData = action.data;
            
            return newStore;
        }

        case actionTypes.CLEAR_TRIP_TIMELINE_DATA: {            
            return {
                ...newStore,
                tripTimelineData: {},
            };
        }

        case actionTypes.STORE_TRANSIT_TIMELINE_DATA: {
            newStore.transitTimelineData = action.data;
            
            return newStore;
        }

        case actionTypes.CLEAR_TRANSIT_TIMELINE_DATA: {
            return {
                ...newStore,
                transitTimelineData: {},
            };
        }

        case actionTypes.STORE_SEARCH_DETAILS: {
            newStore.selectedVID = action.selectedVID;
            newStore.selectedDVID = action.selectedDVID;

            newStore.searchEndTime = action.endTime;
            newStore.searchStartTime = action.startTime;
            
            return newStore;
        }

        case actionTypes.CLEAR_SEARCH_DETAILS: {
            return {
                ...newStore,
                selectedVID: "",
                selectedDVID: "",
                searchEndTime: 0,
                searchStartTime: 0,
            };
        }

        case actionTypes.SET_SELECTED_TRIP_DETAILS: {
            newStore.selectedEndTime = action.selectedEndTime;
            newStore.selectedStartTime = action.selectedStartTime;
            
            return newStore;
        }

        case actionTypes.CLEAR_SELECTED_TRIP_DETAILS: {
            newStore.selectedEndTime = 0;
            newStore.selectedStartTime = 0;
            
            return newStore;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Fleet Management Control Store:", defaultState);
    
            return defaultState;
        }

        default: {
            return state
        }
    }
}