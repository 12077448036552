import React from 'react';
import { connect } from 'react-redux';

import { Tooltip } from "antd";
import { EVENT_TYPES, IMAGE_RESOURCE } from '../../../../constants';
import {
    Row,
    Col,
} from "antd";

// Images
import sosOn from '../../../../img/icon - SOS Bell Enabled.svg';
import sosOff from '../../../../img/icon - SOS Bell Disabled.svg';

const EventStatusIndividualIcon = ({ title, icon, color, size, viewBox }) => {
    const ICON = IMAGE_RESOURCE.EVENTSVG[icon];

    return ICON ?
        (
            <div>
                <Tooltip placement="top" title={title}>
                    <ICON height={size} width={size} fill={color} viewBox={viewBox || '0 0 24 24'} />
                </Tooltip>
            </div>
        ) :
        null
}

/**
 * Initially yes
 * Once data received, then would switch to either no/yes
 * @param {} device 
 */
const getCellularNetworkStatusIcon = device => {
    if (!device) return (
        <EventStatusIndividualIcon
            icon='cellFull'
            size={20}
            color="green"
            title={'Has Cellular Network'}
        />
    )


    if (device && device.isAlive) return (
        <EventStatusIndividualIcon
            icon='cellFull'
            size={20}
            color="green"
            title={'Has Cellular Network'}
        />
    )

    return (
        <EventStatusIndividualIcon
            icon='cellNo'
            size={20}
            color="red"
            title={
                <div>
                    {/* <p>{`No Cellular Network Since ${momentDatetimeTranslate(moment())} (${timeDiff(moment())})`}</p> */}
                    <p>{`No Cellular Network`}</p>
                </div>
            }
        />
    )
}

/**
 * Initially yes
 * Once data received, then would switch to either no/yes
 * @param {} device 
 */
const getGPSStatusIcon = device => {

    if (!device) return (
        <EventStatusIndividualIcon
            icon='gpsFull'
            size={20}
            color="green"
            title={'Has GPS'}
        />
    )

    if (device && device.gpsStatus) return (
        <EventStatusIndividualIcon
            icon='gpsFull'
            size={20}
            color="green"
            title={'Has GPS'}
        />
    )

    return (
        <EventStatusIndividualIcon
            icon='gpsNo'
            size={20}
            color="red"
            title={
                <div>
                    <p>{`No GPS`}</p>
                </div>
            }
        />
    )
}

const getCrticialEventStatusIcon = (criticalEvents = []) => {
    // console.log({ vehicle, ticketState })

    if (criticalEvents.length) return (
        <Tooltip placement="top" title={"Critical event"}>
            <img
                alt={'Critical event'}
                height={25}
                src={sosOn}
                title={'Critical event'}
                viewBox={'0 0 24 24'}
            />
        </Tooltip>
    )
    return (
        <Tooltip placement="top" title={"No critical event"}>
            <img
                alt={'No critical event'}
                height={25}
                src={sosOff}
                title={'No critical event'}
                viewBox={'0 0 24 24'}
            />
        </Tooltip>
    )
}

const EventStatusIcon = props => {
    const {
        events,
        devices,
        vehicles,
        deviceId,
        ticketStates,
    } = props;

    const device = devices.byId[deviceId];

    /**Find critical events in ticket */
    const vehicle = device && Object.values(vehicles.byId).find(vehicle => vehicle.dvid === device.dvid)
    const vehicleTicketStates = vehicle && Object.values(ticketStates.byTicketId).filter(ticketState => ticketState.vid === vehicle.vid)

    const vehicleEvents = (vehicle && events.byId[vehicle.vid]) || {}
    const criticalEvents = vehicleTicketStates
        && vehicleTicketStates.map(ticket=>ticket.evid)
        .map(eventId=>vehicleEvents[eventId])
        .filter(event => event && event.eventType === EVENT_TYPES.CRITICAL)


    return (
        <div
            style={{
                display: 'flex',
                alignItems: `center`
            }}
        >
            <Row>
                <Col span={8}>
                    {
                        getCellularNetworkStatusIcon(device)
                    }
                </Col>

                <Col span={8}>
                    {
                        getGPSStatusIcon(device)
                    }
                </Col>

                <Col span={8}>
                    {
                        getCrticialEventStatusIcon(criticalEvents)
                    }
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => ({
    events: state.v2.events,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    ticketStates: state.v2.ticketStates,
})

export default connect(mapStateToProps)(EventStatusIcon);