import React from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';

import Page from '../../components/Page';
import showSecondarySidebar from '../../components/Layout/helpers/showSecondarySidebar';

import { moveToPage } from "../../navigation/navigationService";
import {
    Table,
    Button,
    Input,
} from 'antd';
import {
    SearchOutlined,
    EditOutlined
} from '@ant-design/icons';

// Redux Actions
import { 
    get_stage,
    set_user_to_migrate,
} from "../../services/redux/actions/migration";

class MigrationPage extends React.Component {
    state = {
        dataSource: [],
    }

    setUpState = () => {
        let newDataSource = [];

        // console.log("Setting Up State:", Object.keys(hierarchy.byId));

        this.props.migration.userList.forEach((currUser) => {
            newDataSource.push({
                key: currUser.uid,
                ...currUser,
            })
        })

        this.setState({
            dataSource: newDataSource,
        })
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => { this.searchInput = node }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block',
                    }}
                />

                <Button
                    type="primary"
                    icon={<SearchOutlined/>}
                    size="small"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    style={{
                        width: 90,
                        marginRight: 8,
                    }}
                >
                    Search
                </Button>

                <Button
                    size="small"
                    style={{ width: 90 }}
                    onClick={() => this.handleReset(clearFilters)}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                textToHighlight={text ? text : ""}
                searchWords={[this.state.searchText]}
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                autoEscape
            />
        ),
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();

        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();

        this.setState({ searchText: '' });
    };


    componentDidMount = () => {
        const { migration } = this.props;

        if (migration.userList.length < 1) {
            this.props.dispatch(get_stage(null));
        }
        else {
            this.setUpState();
        }
    }

    componentDidUpdate = (prevProps) => {
        const { migration } = this.props;

        if (migration !== prevProps.migration && migration.userList.length > 0) {
            this.setUpState();
        }
    }

    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'userName',
                key: 'userName',
                ...this.getColumnSearchProps("userName"),

            },
            {
                title: 'Stage',
                dataIndex: 'stage',
                key: 'stage',
            },
            {
                title: "Actions",
                render: columnData => (
                    <Button.Group>
                        <button className = "transparent-button">
                            <EditOutlined 
                                onClick = {() => {
                                    // this.props.dispatch(moveToPage(`/Migration/MigrateUser/${columnData.key}`, columnData.key));
                                    this.props.dispatch(set_user_to_migrate(columnData));
                                    this.props.dispatch(moveToPage(`/Migration/MigrateUser/${columnData.key}`));
                                }}
                            />
                        </button>
                    </Button.Group>
                )
            }
        ]


        return (
            <div className = 'page-container'>
                <Page title = 'Migration'>
                    <Table
                        columns = {columns}
                        dataSource = {this.state.dataSource}
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    migration: state.v2.migration,
});

// const ConnectedMigrationPage = connect(mapStateToProps)(MigrationPage);
export default showSecondarySidebar(false)(connect(mapStateToProps)(MigrationPage));