import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

// import { PrimaryButton } from "../../../components/Button";
// import { EmptyIndicator } from "../../../components/EmptyIndicator";
// import { moveToPage } from "../../../../navigation/navigationService";
import {
    // Icon,
    Form,
    // Table,
    // DatePicker,
    // AutoComplete,
} from "antd";

// Redux Actions
// import { set_selected_vehicle } from "../../services/redux/actions/vehicles";
// import { get_device_log_by_timeframe_request } from "../../services/redux/actions/deviceLog";

class FuelAnalysisPage extends React.Component {
    state = {
        selectedVID: "",
        filterString: "",

        startTime: moment().startOf("day"),
        endTime: moment().add(1, "day").startOf("day"),

        dataSource: [],
    }

    componentDidMount = () => {

    }

    componentDidUpdate = (prevProps) => {

    }

    render() {
        return (
            <div className = "page-container">
                <Page title = "Fuel Loss Remark">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">

                        </Form>
                    </div>
                </Page>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    
});

const ConnectedFuelAnalysisPage = connect(mapStateToProps)(FuelAnalysisPage);
export default showSecondarySidebar(false)(ConnectedFuelAnalysisPage);