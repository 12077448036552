import * as actionType from "../action-types/vehicleGroups";

const defaultState = {
    byName: {},
    byVGID: {},
    allVGIDs: [],

    tempNewVGID: "" // Used to find newly created vehicle groups
};

export const vehicleGroups = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case actionType.GET_VEHICLE_GROUPS: {
            // console.log("Vehicle Groups Data:", action.vehicleGroups);

            if (action.vehicleGroups && action.vehicleGroups.length > 0) {
                action.vehicleGroups.forEach(currGroup => {
                    newState.byVGID[currGroup.vgid] = currGroup;

                    if (currGroup && currGroup.groupName) {
                        newState.byName[currGroup.groupName] = currGroup;
                    }

                    newState.allVGIDs.push(currGroup.vgid)
                })
            }

            return newState;
        }
        
        case actionType.ADD_VEHICLE_GROUP: {
            // console.log("Add Vehicle Group Data:", action);

            newState.byVGID[action.vehicleGroup.vgid] = action.vehicleGroup;

            if (action.vehicleGroup && action.vehicleGroup.groupName) {
                newState.byName[action.vehicleGroup.groupName] = action.vehicleGroup;
            }
            
            newState.allVGIDs.push(action.vehicleGroup.vgid);

            // Just to keep track of new VG. Will be cleared when leaving Add Vehicle Group pages
            newState.tempNewVGID = action.vehicleGroup.vgid;

            return newState;
        }
        
        case actionType.EDIT_VEHICLE_GROUP: {
            // console.log("Edit Vehicle Group Data:", action);

            newState.byVGID[action.vehicleGroup.vgid] = action.vehicleGroup;

            if (action.vehicleGroup && action.vehicleGroup.groupName) {
                newState.byName[action.vehicleGroup.groupName] = action.vehicleGroup;
            }
            
            // newState.allVGIDs.push(action.vehicleGroup.vgid);

            // Just to keep track of new VG. Will be cleared when leaving Add Vehicle Group pages
            // newState.tempNewVGID = action.vehicleGroup.vgid;

            return newState;
        }

        case actionType.REASSIGN_VEHICLE_GROUPS: {
            if (action.updatedVGs && action.updatedVGs.length > 0) {
                action.updatedVGs.forEach(currGroup => {
                    if (currGroup && currGroup.groupName) {
                        newState.byName[currGroup.groupName] = currGroup;
                    }

                    newState.byVGID[currGroup.vgid] = currGroup;
                })
            }

            return newState;
        }
        
        case actionType.ASSIGN_VEHICLE_GROUP: {
            // console.log("Assign Vehicle Group Data:", action);

            newState.byVGID[action.updatedVG.vgid] = action.updatedVG;

            if (action.updatedVG.groupName) {
                newState.byName[action.updatedVG.groupName] = action.updatedVG;
            }

            // Clearing Temp New VGID
            newState.tempNewVGID = "";

            return newState;
        }
        
        case actionType.UPDATE_VEHICLES_IN_VEHICLE_GROUP: {
            // console.log("Update Vehicles In Vehicle Group Data:", action);

            const updatedVG = action.updatedVG;

            if (updatedVG) {
                newState.byVGID[updatedVG.vgid] = updatedVG;

                if (updatedVG.groupName) {
                    newState.byName[updatedVG.groupName] = updatedVG;
                }
            }

            return newState;
        }
        
        case actionType.UPDATE_VEHICLE_GROUP_AFTER_ADD: {
            // console.log("Update Vehicle Group After Add:", action);

            if (action.vid && action.vgidList) {
                action.vgidList.forEach((currVGID) => {
                    const currVG = newState.byVGID[currVGID];

                    if (currVG) {
                        if (!currVG.vehicles.includes(action.vid)) {
                            currVG.vehicles.push(action.vid);
                        }

                        if (!newState.byName[currVG.groupName].vehicles.includes(action.vid)) {
                            newState.byName[currVG.groupName].vehicles.push(action.vid);
                        }
                    }

                })
            }

            return newState;
        }
        
        case actionType.UPDATE_VEHICLE_GROUP_AFTER_EDIT: {
            // console.log("Update Vehicle Group After Add:", action);

            if (action.vid && action.vgidList) {
                // Remove all instances of the vid in all vehicle groups first
                Object.values(newState.byVGID).forEach((currVG) => {
                    if (currVG.vehicles.includes(action.vid)) {
                        const newVIDList = currVG.vehicles.filter((currVID) => currVID !== action.vid);

                        currVG.vehicles = newVIDList;
                        newState.byName[currVG.groupName].vehicles = newVIDList;
                    }
                })

                action.vgidList.forEach((currVGID) => {
                    const currVG = newState.byVGID[currVGID];

                    if (currVG) {
                        if (!currVG.vehicles.includes(action.vid)) {
                            currVG.vehicles.push(action.vid);
                        }

                        if (!newState.byName[currVG.groupName].vehicles.includes(action.vid)) {
                            newState.byName[currVG.groupName].vehicles.push(action.vid);
                        }
                    }
                })
            }

            return newState;
        }
        
        case actionType.CLEAR_TEMP_NEW_VGID: {
            // console.log("Assign Vehicle Group Data:", action);

            return {
                ...newState,
                tempNewVGID: "",
            };
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Vehicle Groups Store:", defaultState);

            return defaultState;
        }

        default: {
            return state;
        }
    }
}