import { config } from '../../config'

const api = config.saAPI;
const headers = {
  'Accept': '*/*',
  'Content-Type': 'application/json; charset=utf-8'
}

// console.log("api", config)

const devGetStage = uid => {
  return fetch(`${api}/user/getstage`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid
    })
  })
    .then(res => res.json())
}

const prodGetStage = (uid) => (
  fetch(`${api}/user/getInfo`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid
    })
  })
    .then(res => res.json())
)


export const getInfoByUser = config.project === 'dev' ? devGetStage : prodGetStage