import React, { 
    // useState, 
    // useEffect 
} from 'react';
import { connect } from "react-redux";

import { Tooltip } from "antd";

// Redux Actions
import {
    // reset_ui_control,
    enable_show_vehicle_info,
    disable_show_vehicle_info,
    enable_show_geofence_info,
    disable_show_geofence_info,
    enable_show_geofences,
    disable_show_geofences,
    enable_show_live_traffic,
    disable_show_live_traffic,
    // enable_show_vehicle_trail,
    // disable_show_vehicle_trail,
    // clear_enabled_vehicle_pop_ups,
    clear_enabled_geofence_pop_ups,
} from "../../services/redux/actions/mapControl";

import "./index.css";

function MapControlPanel(props){
    // Just to trigger rerender
    // const [state, setState] = useState(props.mapControl)

    // useEffect(() => {

    // })

    return (
        <div
            style = {{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",

                width: "100%",
                height: "100%",
            }}
        >
            {
                !props.disableToggleGeofences &&
                    <Tooltip title="Toggle Geofences" placement="left">
                        {
                            props.mapControl.uiControl.showAllGeofence ?
                                <img
                                    alt={"geofenceMarker_Yay"}
                                    className={"icon"}
                                    src={require("../../../../img/svg/Map_Filter_Icons/geofenceMarker_Yay.svg")}
                                    onClick={() => {
                                        props.dispatch(disable_show_geofences());
                                    }}
                                /> :
                                <img
                                    alt={"geofenceMarker_Nay"}
                                    className={"icon"}
                                    src={require("../../../../img/svg/Map_Filter_Icons/geofenceMarker_Nay.svg")}
                                    onClick={() => {
                                        props.dispatch(enable_show_geofences());
                                    }}
                                />
                        }
                    </Tooltip>
            }

            {
                !props.disableToggleGeofenceInfo &&
                    <Tooltip title="Toggle Geofence Info" placement="left">
                        {
                            props.mapControl.uiControl.showAllGeofencesInfoWindow ?
                                <img
                                    alt={"geofenceMarkerCallout_Yay"}
                                    className={"icon"}
                                    src={require("../../../../img/svg/Map_Filter_Icons/geofenceMarkerCallout_Yay.svg")}
                                    onClick={() => {
                                        props.dispatch(disable_show_geofence_info());
                                    }}
                                /> :
                                <img
                                    alt={"geofenceMarkerCallout_Nay"}
                                    className={"icon"}
                                    src={require("../../../../img/svg/Map_Filter_Icons/geofenceMarkerCallout_Nay.svg")}
                                    onClick={() => {
                                        props.dispatch(enable_show_geofence_info());
                                        props.dispatch(clear_enabled_geofence_pop_ups());
                                    }}
                                />
                        }
                    </Tooltip>
            }

            {
                !props.disableToggleVehicleInfo &&
                    <Tooltip title="Toggle Vehicle Info" placement="left">
                        {
                            props.mapControl.uiControl.showAllVehiclesInfoWindow ?
                                <img
                                    alt={"vehicleMarkerCallout_Yay"}
                                    className={"icon"}
                                    src={require("../../../../img/svg/Map_Filter_Icons/vehicleMarkerCallout_Yay.svg")}
                                    onClick={() => {
                                        props.dispatch(disable_show_vehicle_info());
                                    }}
                                /> :
                                <img
                                    alt={"vehicleMarkerCallout_Nay"}
                                    className={"icon"}
                                    src={require("../../../../img/svg/Map_Filter_Icons/vehicleMarkerCallout_Nay.svg")}
                                    onClick={() => {
                                        props.dispatch(enable_show_vehicle_info());
                                        // props.dispatch(clear_enabled_vehicle_pop_ups());
                                    }}
                                />
                        }
                    </Tooltip>
            }

            {
                !props.disableToggleLiveTraffic &&
                    <Tooltip title="Toggle Live Traffic" placement="left">
                        {
                            props.mapControl.uiControl.showLiveTraffic ?
                                <img
                                    alt={"liveTraffic_Yay"}
                                    className={"icon"}
                                    src={require("../../../../img/svg/Map_Filter_Icons/liveTraffic_Yay.svg")}
                                    onClick={() => {
                                        props.dispatch(disable_show_live_traffic());
                                    }}
                                /> :
                                <img
                                    alt={"liveTraffic_Nay"}
                                    className={"icon"}
                                    src={require("../../../../img/svg/Map_Filter_Icons/liveTraffic_Nay.svg")}
                                    onClick={() => {
                                        props.dispatch(enable_show_live_traffic());
                                    }}
                                />
                        }
                    </Tooltip>
            }

            {/* no implementing in v2 */}
            {/* <Tooltip title="Toggle Vehicle Trail" placement="left">
                {
                    state.showVehicleTrail ?
                        <img
                            alt={"tripTrail_Yay"}
                            className={"icon"}
                            src={require("../../../img/svg/Map_Filter_Icons/tripTrail_Yay.svg")}
                            onClick={() => {
                                props.dispatch(disable_show_vehicle_trail());
                            }}
                        /> :
                        <img
                            alt={"tripTrail_Nay"}
                            className={"icon"}
                            src={require("../../../img/svg/Map_Filter_Icons/tripTrail_Nay.svg")}
                            onClick={() => {
                                props.dispatch(enable_show_vehicle_trail());
                            }}
                        />
                }
            </Tooltip> */}
        </div>
    );
}

const mapStateToProps = (state) => ({
    mapControl: state.v2.mapControl,
});

export default connect(mapStateToProps)(MapControlPanel);