import * as API from "../../api";
import * as ActionTypes from "../action-types/fuelRecord";

import { message } from "antd";
import {
    setLoadingSubmit, 
    unsetLoadingSubmit
} from "./style";

export const get_fuel_record_by_timeframe_request = (vid, startTime, endTime) => dispatch => {
    // console.log("Get Fuel Record By Timeframe Params:", vid, startTime, endTime);

    dispatch(setLoadingSubmit());
  
    API.getFuelRecordByTimeframe(vid, startTime, endTime)
    .then(data => {
        // console.log("Get Fuel Record Data:", data);

        if (data.status !== 200 && data.errorMessage) {
            message.error("Get Fuel Record Error: " + data.errorMessage);
        }
        else {
            dispatch(get_fuel_record_by_timeframe_success(data));
        }
      
        dispatch(unsetLoadingSubmit());
    })
    .finally(()=>{

        dispatch(unsetLoadingSubmit());
    })

}

const get_fuel_record_by_timeframe_success = (data) => {
    return {
        type: ActionTypes.GET_FUEL_RECORD,
        data: data
    }
}

export const update_fuel_record = (
    vid,
    fuelStartAt,
    fuelUnitPrice, 
    fuelType, 
    receiptID, 
    receiptDateTime, 
    receiptRefuelLevel, 
    receiptRemark, 
) => dispatch => {
    dispatch(setLoadingSubmit());
  
    API.updateSelectedFuelRecord(
        vid,
        fuelStartAt,
        fuelUnitPrice, 
        fuelType, 
        receiptID, 
        receiptDateTime, 
        receiptRefuelLevel, 
        receiptRemark, 
    )
    .then(data => {
        // console.log("Update Fuel Record Data:", data);

        dispatch(update_fuel_record_success(data.fuelRecord));
        dispatch(unsetLoadingSubmit());
    })
    .finally(()=>{
        dispatch(unsetLoadingSubmit());
    })
}

const update_fuel_record_success = (updatedFuelRecord) => {
    return {
        type: ActionTypes.UPDATE_FUEL_RECORD,
        updatedFuelRecord: updatedFuelRecord
    }
}

export const set_fuel_record_selected_frid = (selectedFRID) => {
    return {
        type: ActionTypes.SET_FUEL_RECORD_SELECTED_FRID,
        selectedFRID: selectedFRID
    }
}

export const clear_fuel_record_selected_frid = () => {
    return {
        type: ActionTypes.CLEAR_FUEL_RECORD_SELECTED_FRID
    }
}