import {
    config
} from "../config";

let color = {

}

switch(config.project) {
    default: {
        color.brand = "#3D8ACE";
        color.text = "white";
        break;
    }
}


export const BRAND_PRIMARY = color.brand;
export const TEXT_COLOUR = color.text;