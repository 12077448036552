import React from "react";
import { connect } from "react-redux";

import moment from 'moment';

import { PrimaryButton } from "../../../components/Button";
import { DISPLAY_RULE_TYPE } from "../../../../../constants/index";
import { moveToPage } from "../../../navigation/navigationService";
import { EmptyIndicator } from "../../../components/EmptyIndicator";
import {
    EditOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import {
    Table,
    Button,
    Tooltip,
} from "antd";

// Redux Actions
// import { set_selected_ruid } from "../../../services/redux/actions/rules";

class RuleEnginePanel extends React.Component {
    render() {
        const {
            rules,
            ruleTemplate,
        } = this.props;

        const expandedRuleRowsRender = (record) => {
            // console.log("Record:", record);

            const columnsRule = [
                {
                    title: "Rule Name",
                    dataIndex: "ruleName",
                },
                {
                    title: "Type",
                    dataIndex: "ruleType",
                },
                {
                    title: "Parameters",
                    dataIndex: "parameters",
                },
                {
                    title: "Creation Date",
                    dataIndex: "createdAt",
                    render: datetime => moment(Number(datetime)).format("YYYY/MM/DD h:mm:ss A"),
                },
                {
                    title: "Actions",
                    dataIndex: "ruid",
                    render: ruid => (
                        <Button.Group>
                            <button className = "transparent-button">
                                <EditOutlined 
                                    onClick = {() => {
                                        // this.props.dispatch(set_selected_ruid(ruid));
                                        this.props.dispatch(moveToPage(`/Management/Rule/Edit/${ruid}`, rules.byRuID[ruid]));
                                    }}
                                />
                            </button>
                        </Button.Group>
                    )
                }
            ]

            const ruleArr = ruleTemplate.relationByRules[record.key].map(ruid => ({
                key: ruid,
                ruid: ruid,
                ruleName: rules.byRuID[ruid].ruleName,
                ruleType: DISPLAY_RULE_TYPE[rules.byRuID[ruid].ruleType],
                createdAt: rules.byRuID[ruid].createdAt,
                parameters: `${rules.byRuID[ruid].conditions.map(c => c.parameter)}`,
            }))

            return <Table bordered columns = {columnsRule} dataSource = {ruleArr} pagination = {false} />
        }

        const ruleTemplateArr = Object.values(ruleTemplate.byId).map((currRuleTemplate) => {
            return {
                key: currRuleTemplate.rtid,
                createdAt: currRuleTemplate.createdAt,
                numRules: (currRuleTemplate.rules && currRuleTemplate.rules.length) || 0,
                templateName: `${currRuleTemplate.templateName} (${(currRuleTemplate.rules && currRuleTemplate.rules.length)|| 0})`,
            }
        })

        const columnsRuleTemplate = [
            {
                title: "Template Name",
                dataIndex: "templateName"
            },
            {
                title: "Rules",
                dataIndex: "numRules"
            },
            {
                title: "Creation Date",
                dataIndex: "createdAt",
                render: datetime => moment(Number(datetime)).format("YYYY/MM/DD h:mm:ss A"),
            },
            {
                title: "Actions",
                dataIndex: "key",
                render: (rtid) => (
                    ruleTemplate.byId[rtid].templateName !== "DEFAULT" ?
                        <Button.Group>
                            <button className = "transparent-button">
                                <EditOutlined 
                                    onClick = {() => {
                                            // this.props.dispatch(set_selected_ruid(ruid));
                                            this.props.dispatch(moveToPage(`/Management/RuleTemplate/Edit/${rtid}`, rtid));
                                        }}
                                />
                            </button>
                        </Button.Group> :
                        null
                )
            }
        ]

        const customRuleTemplates = Object.values(this.props.ruleTemplate.byId).filter(rt => rt.templateName !== 'DEFAULT')

        return (
            <div style = {{ display: "flex", flex: 1 }}>
                <div
                    className = "scroll-div"
                    style = {{
                        width: "95%",
                        margin: "0 5%"
                    }}
                >
                    <div
                        style = {{
                            display: "flex",
                            justifyContent: "flex-end",

                            padding: 5,
                            marginBottom: 10,
                        }}
                    >
                        <PrimaryButton 
                            icon = {<PlusOutlined/>} 
                            onClick = {() => this.props.dispatch(moveToPage("/Management/RuleTemplate/Add"))}
                        >
                            Add New Rule Template
                        </PrimaryButton>

                        <Tooltip
                            placement = "top"
                            title = {`No custom rule template found. Add a new rule template first`}
                            visible = {customRuleTemplates.length === 0 && !this.props.style.isLoadingSubmit}
                        >
                            <PrimaryButton
                                disabled = {customRuleTemplates.length === 0}
                                icon = {<PlusOutlined/>}
                                onClick = {() => this.props.dispatch(moveToPage("/Management/Rule/Add"))}
                                style = {{ marginLeft: 10 }}
                            >
                                Add New Rule
                            </PrimaryButton>
                        </Tooltip>
                    </div>

                    <div>
                        {
                            ruleTemplateArr.length > 0 ?
                                <Table
                                    loading = {this.props.style.isLoadingSubmit}
                                    dataSource = {ruleTemplateArr}
                                    columns = {columnsRuleTemplate}
                                    // onExpand = {this.onExpandTable}
                                    // expandedRowRender = {expandedRuleRowsRender}
                                    expandable = {{
                                        rowExpandable: (record) => record.templateName !== 'DEFAULT',
                                        expandedRowRender: (record) => expandedRuleRowsRender(record),
                                    }}
                                /> :
                                <EmptyIndicator />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    rules: state.v2.rules,
    ruleTemplate: state.v2.ruleTemplate,
});

export default connect(mapStateToProps)(RuleEnginePanel);