const deviceState = {
  byId: {},
  byStatus: {
    "ALL": [],
    "MOVING": [],
    "IDLING": [],
    "PARKING": [],
    "DISCONNECTED": []
  },
  allIds: [],
  listIds: [],
  selectedId: null,
  infoBoxList: {}
};

export const devices = (state = deviceState, action) => {
  const convertLocationToString = (locationObj) => {
    if (!locationObj) return
    const newLoc = {
      lat: locationObj.lat.toString(),
      lon: locationObj.lon.toString(),
    }

    return newLoc;
  }

  switch (action.type) {
    case 'GET_DEVICES': {
      let newState = {
        byId: {},
        // byStatus: {
        //   "ALL": [],
        //   "MOVING": [],
        //   "IDLING": [],
        //   "PARKING": [],
        //   "DISCONNECTED": []
        // },
        allIds: [],
        listIds: [],
        selectedId: state.selectedId
      };

      // console.log("Device Data:", action.devices);

      if (action.devices && action.devices.length > 0) {
        action.devices.forEach(device => {
          device.location = convertLocationToString(device.location);

          newState.byId[device.dvid] = device;

          if (!newState.allIds.includes(device.dvid)) {
            newState.allIds.push(device.dvid);
          }

          if (!newState.listIds.includes(device.dvid)) {
            newState.listIds.push(device.dvid);
          }

          // if (newState.byStatus[device.transitName] && !newState.byStatus[device.transitName].includes(device.dvid)) {
          //   newState.byStatus[device.transitName].push(device.dvid);

          //   // Only the devices that receive real gps data and has transit name, only it will be shown in the dashboard.
          //   if (!newState.allIds.includes(device.dvid)) {
          //     newState.allIds.push(device.dvid);
          //   }
          // }
        })
      }

      // newState.byStatus["ALL"] = newState.allIds

      // console.log("ALL in GET_DEVICES",newState.byStatus["ALL"]);

      return newState;
    }

    case 'UPDATE_DEVICE': {
      let newState = JSON.parse(JSON.stringify(state));
      // console.log("Before checking", newState.allIds);

      // console.log(action.device)

      if (action.device.hasOwnProperty('location')) {
        action.device.location = convertLocationToString(action.device.location);
      }

      newState.byId[action.device.dvid] = {
        ...newState.byId[action.device.dvid],
        ...action.device
      };

      if (!newState.allIds.includes(action.device.dvid)) {
        newState.allIds.push(action.device.dvid)
      }

      // It will go into the byStatus to loop the status state to update or delete the deviceId.

      // console.log("After checking", newState.allIds);
      // console.log("Device transit", action.device.transitName);

      // let allIdStatus = newState.allIds;

      // Object.keys(newState.byStatus).forEach((statusName) => {
      //   let statusArray = newState.byStatus[statusName];

      //   // console.log("Status", statusName);
      //   // console.log("Initial Status Array", statusArray);

      //   // Checking for status Array has the device Id, if no, then push the id into the array.
      //   if (!statusArray.includes(action.device.dvid)) {
      //     // console.log(`${statusArray} has no ${action.device.dvid}`);

      //     if (statusName === action.device.transitName) {
      //       statusArray.push(action.device.dvid);
      //     }
      //   }

      //   // Checking for status Array has the device Id, if no, then remove it from the array.
      //   if (statusArray.includes(action.device.dvid)) {
      //     // console.log(`${statusArray} has ${action.device.dvid}`);

      //     if (statusName !== action.device.transitName) {
      //       statusArray = statusArray.filter((dvid) => dvid !== action.device.dvid)
      //       // console.log("filtered statusArray",statusArray);
      //     }
      //   }

      //   newState.byStatus[statusName] = statusArray;
      // })

      // newState.byStatus["ALL"] = allIdStatus;

      // console.log("ALL in update_devices",newState.byStatus["ALL"]);
      // console.log("newState status",newState.byStatus);

      return newState;
    }

    case 'ADD_NEW_DEVICE_SUCCESS': {
      let addDeviceObject = JSON.parse(JSON.stringify(state));

      action.device.location = convertLocationToString(action.device.location);

      addDeviceObject.byId[action.device.dvid] = action.device;

      if (!addDeviceObject.allIds.includes(action.device.dvid)) {
        addDeviceObject.allIds.push(action.device.dvid);
      }

      if (!addDeviceObject.listIds.includes(action.device.dvid)) {
        addDeviceObject.listIds.push(action.device.dvid);
      }

      return addDeviceObject;
    }

    case 'EDIT_DEVICE_SUCCESS': {
      let editDeviceObject = JSON.parse(JSON.stringify(state));

      if (action.device.location) {
        action.device.location = convertLocationToString(action.device.location);
      }

      editDeviceObject.byId[action.device.dvid] = {
        ...editDeviceObject.byId[action.device.dvid],
        ...action.device
      };

      return editDeviceObject;
    }

    // case 'GET_DEVICES_ADMIN': {
    //   let newState = JSON.parse(JSON.stringify(state));

    //   if(action.devices && action.devices.length > 0) {
    //     action.devices.forEach(device => newState.byId[device.dvid] = device)
    //   }

    //   // console.log("NewObject",newState);

    //   return newState;
    // }

    case 'SET_SELECTED_DEVICE': {
      // console.log("select Id", action.dvid);

      return {
        ...state,
        selectedId: action.dvid
      }
    }

    case 'CLEAR_SELECTED_DEVICE': {
      return {
        ...state,
        selectedId: null
      }
    }

    default:
      return state;
  }
}