import { config } from '../../../../config'

const api = config.api;
const headers = {
  'Accept': '*/*',
  'Content-Type': 'application/json; charset=utf-8'
}

export const archiveGeofence = (uid, geoid, configSet) => (
  fetch(`${api}/geofence`, {
    method: 'DELETE',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "geoid": geoid,
      configSet: configSet
    })
  })
    .then(res => res.json())
)

export const addDevice = (dgid, device) => (
  fetch(`${api}/device/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dgid": dgid,
      "device": device
    })
  })
    .then(res => res.json())
)

export const addVehicle = (newVehicle, targetUserDetails, vgidList, gtidList, rtidList) => (
  fetch(`${api}/vehicle/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vehicle": newVehicle,
      "user": targetUserDetails,
      "vgids": vgidList,
      "gtids": gtidList,
      "rtids": rtidList,
    })
  })
    .then(res => res.json())
)

export const editDevice = (device) => (
  fetch(`${api}/device/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "device": device
    })
  })
    .then(res => res.json())
)

export const editPOI = (geofence) =>
  (
    fetch(`${api}/geofence/edit`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        ...geofence
      })
    })
      .then(res => res.json())
  )

export const editPolygon = (geofence) =>
  (
    fetch(`${api}/geofence/edit`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        ...geofence
      })
    })
      .then(res => res.json())
  )

export const editVehicle = (editedVehicle, currUserDetails, vgidList, gtidList, rtidList) => (
  fetch(`${api}/vehicle/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vehicle": editedVehicle,
      "user": currUserDetails,
      "vgids": vgidList,
      "gtids": gtidList,
      "rtids": rtidList,
    })
  })
    .then(res => res.json())
)

export const getFuelRecordByTimeframe = (vid, startTime, endTime) => (
  fetch(`${api}/fuelrecord/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Fuel Record API Data:", data);

      return data;
    })
)

export const updateSelectedFuelRecord = (
  vid,
  fuelStartAt,
  fuelUnitPrice,
  fuelType,
  receiptID,
  receiptDateTime,
  receiptRefuelLevel,
  receiptRemark,
) => (
    fetch(`${api}/fuelrecord/update`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        fuelRecord: {
          "vid": vid,
          "fuelStartAt": fuelStartAt,
          "fuelUnitPrice": fuelUnitPrice,
          "fuelType": fuelType,
          "receiptID": receiptID,
          "receiptDateTime": receiptDateTime,
          "receiptRefuelLevel": receiptRefuelLevel,
          "receiptRemark": receiptRemark
        }
      })
    })
      .then(res => res.json())
      .then(data => {
        // console.log("Update Fuel Record API Data:", data);

        return data;
      })
  )

export const getDeviceLogByTimeframe = (dvid, startTime, endTime) => (
  fetch(`${api}/deviceLog/getdvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dvid": dvid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Raw Device Log Data:", data);

      return data.Items ?
        data.Items :
        data.Item ?
          data.Item :
          data;
    })
)

export const getDeviceLogDebugByTimeframe = (dvid, startTime, endTime) => (
  fetch(`${api}/deviceLog/debug/getdvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dvid": dvid,
      "startTime": (startTime),
      "endTime": (endTime),
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Raw Device Log Debug Data:", data);

      return data.Items;
    })
)

export const getVehicleLogByTimeframe = (vid, startTime, endTime) => (
  fetch(`${api}/vehicleLog/getvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": (startTime),
      "endTime": (endTime),
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Raw Vehicle Log Data:", data);

      return data.Items || data;
    })
)

export const getTripRecordByTimeframe = (vid, startTime, endTime) => (
  fetch(`${api}/tripRecord/timeFrame`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
    .then(data => data.Items)
)

export const getTripRecordByTimeframe_Teltonika = (vid, startTime, endTime) => (
  fetch(`${api}/tripRecord/timeFrame/debug`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime),
      "deviceType": "TEL100"
    })
  })
    .then(res => res.json())
    .then(data => data.Items)
)

export const getTransitRecordByTimeframe = (vid, startTime, endTime) => (
  fetch(`${api}/transitRecord/timeFrame`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
    .then(data => data.Items)
)

export const getTransitRecordByTrids = (trids) => (
  fetch(`${api}/transitRecord/getbytrid`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      trids,
    })
  })
    .then(res => res.json())
)

export const getTransitRecordByTimeframe_Teltonika = (vid, startTime, endTime) => (
  fetch(`${api}/transitRecord/timeFrame/debug`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime),
      "deviceType": "TEL100"
    })
  })
    .then(res => res.json())
    .then(data => data.Items)
)

export const getPDFTransitRecordByTimeframe = (vid, startTime, endTime, status) => (
  fetch(`${api}/transitRecord/timeFrame/pdf`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/pdf"
    },
    body: JSON.stringify({
      "vid": vid,
      "startTime": Number(startTime),
      "endTime": Number(endTime),
      "status": status
    })
  })
    .then(res => res.json())
)

export const getEventByTimeframe = (vids, startTime, endTime) => (
  fetch(`${api}/event/getvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vids,
      startTime,
      endTime
    })
  })
    .then(res => res.json())
)

export const getDeviceByVGIDs = (uid, vgids) => (
  fetch(`${api}/device/vgid/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "vgids": vgids
    })
  })
    .then(res => res.json())
)

export const getDeviceByGroup = (dgids, uid) => (
  fetch(`${api}/device/group/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dgids": dgids,
      "uid": uid
    })
  })
    .then(res => res.json())
)

export const getVehicleByGroup = (vgids, uid) => (
  fetch(`${api}/vehicle/group/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vgids": vgids,
      "uid": uid
    })
  })
    .then(res => res.json())
)

export const addVehicleGroup = (uid, groupName) => (
  fetch(`${api}/vehicle/group/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "vehicleGroupName": groupName,
    })
  })
  .then(res => res.json())
)

export const editVehicleGroup = (editedVG) => (
  fetch(`${api}/vehicle/group/edit`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vehicleGroup": editedVG
    })
  })
  .then(res => res.json())
)

export const updateVehiclesInVehicleGroup = (vid, srcUID, srcVGID, destUID, destVGID) => (
  fetch(`${api}/vehicle/group/updatevehicles`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vid": vid,
      "srcUid": srcUID,
      "srcVgid": srcVGID,
      "destUid": destUID,
      "destVgid": destVGID,
    })
  })
    .then(res => res.json())
)

export const getGeofenceByTemplate = (gtids, uid) => (
  fetch(`${api}/geofence/template/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "gtids": gtids,
      'uid': uid,
    })
  })
    .then(res => res.json())
)

export const addGeofenceTemplate = (uid, colorHexCode, gtName) => (
  fetch(`${api}/geofence/template/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      'uid': uid,
      "colorHexCode": colorHexCode,
      "geofenceTemplateName": gtName,
    })
  })
  .then(res => res.json())
)

export const editGeofenceTemplate = (geofenceTemplate) => (
  fetch(`${api}/geofence/template/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "geofenceTemplate": geofenceTemplate,
    })
  })
  .then(res => res.json())
)

export const assignGeofencesToGeofenceTemplate = (uid, gtid, geoidList) => (
  fetch(`${api}/geofence/template/assign/geofence`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      'uid': uid,
      "gtid": gtid,
      "geoids": geoidList,
    })
  })
    .then(res => res.json())
)

export const unassignGeofencesFromGeofenceTemplate = (uid, selectedGTID, geoidList) => {
  return fetch(`${api}/geofence/template/unassign/geofence`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "gtid": selectedGTID,
      "geoids": geoidList,
    })
  })
    .then(res => res.json())
}

export const getInfoByUser = (uid) => (
  fetch(`${api}/user/getInfo`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid
    })
  })
    .then(res => res.json())
)

export const addUser = (currentUser, newUser) => {
  return fetch(`${api}/user/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      currentUser: currentUser,
      newUser: newUser
    })
  })
    .then(res => res.json())
}

export const editUser = (user) => (
  fetch(`${api}/user/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      user: user
    })
  })
    .then(res => res.json())
)

export const getUserHierarchy = (uid) => {

  return fetch(`${api}/user/gethierarchy`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      uid
    })
  })
    .then(res => res.json())
}

export const getUserHierarchyTest1 = (uid) => {

  return fetch(`${api}/user/gethierarchytest1`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      uid
    })
  })
    .then(res => res.json())
}

export const editUserHierarchy = (currentUser, uid) => (
  fetch(`${api}/user/updateHierarchy`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      currentUser: currentUser,
      uid: uid,
    })
  })
    .then(res => res.json())
)

export const setGeofence = (uid, geofence, comments) => (
  fetch(`${api}/geofence`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "geofence": geofence,
      "comments": comments
    })
  })
    .then(res => res.json())
)

export const getGeofenceRecordByTimeframe = (vids, geoid, startTime, endTime) => (
  fetch(`${api}/geofenceRecord/timeFrame`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "vids": vids,
      "geoid": geoid,
      "startTime": Number(startTime),
      "endTime": Number(endTime)
    })
  })
    .then(res => res.json())
)

export const getRuleTemplate = (rtids) => (
  fetch(`${api}/rule/template/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "rtids": rtids
    })
  })
    .then(res => res.json())
)

export const createRuleTemplate = (uid, ruleTemplateName) => (
  fetch(`${api}/rule/template/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "createdBy": uid,
      "ruleTemplateName": ruleTemplateName
    })
  })
    .then(res => res.json())
)
export const updateRuleTemplate = (uid, ruleTemplate) => {

  // return new Promise((res, rej) => {
  //   res({
  //     status: 200,
  //     ruleTemplate: ruleTemplate
  //   })
  // })
  return fetch(`${api}/rule/template/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      ruleTemplate
    })
  })
    .then(res => res.json())
}

export const createGeofence = (uid, geofence, gtidList) => (
  fetch(`${api}/geofence/create`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "geofence": geofence,
      "gtids": gtidList,
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Create Geofence Data: ", data);

      return data;
    })
)

export const editGeofence = (editedGeofence) => (
  fetch(`${api}/geofence/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "geofence": editedGeofence,
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Edit Geofence Data: ", data);

      return data;
    })
)

export const addGroupsToUser = (srcUser, destUser, groupIDList) => (
  fetch(`${api}/userrelation/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "srcUser": srcUser,
      "destUser": destUser,
      "ids": groupIDList,
    })
  })
    .then(res => res.json())
)

export const updateGroupsToUser = (srcUser, destUser, groupIDList) => (
  fetch(`${api}/userrelation/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "srcUser": srcUser,
      "destUser": destUser,
      "ids": groupIDList,
    })
  })
    .then(res => res.json())
)

export const addRule = (selectedRTID, newRule) => (
  fetch(`${api}/rule/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "rtid": selectedRTID,
      "rule": newRule,
    })
  })
    .then(res => res.json())
)

export const editRule = (editedRule) => (
  fetch(`${api}/rule/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "rule": editedRule,
    })
  })
    .then(res => res.json())
)

export const getNotifications = (nfidList) => (
  fetch(`${api}/notification/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "nfids": nfidList
    })
  })
    .then(res => res.json())
)

export const addNotification = (newNotification) => (
  fetch(`${api}/notification/add`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "notification": newNotification
    })
  })
    .then(res => res.json())
)

export const editNotification = (editedNotification) => (
  fetch(`${api}/notification/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "notification": editedNotification
    })
  })
    .then(res => res.json())
)

export const getDeviceLogDebugDeviceByTimeframe = (dvid, startTime, endTime) => {
  return fetch(`${api}/deviceLog/debug/devicegetdvidtimeframe`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "dvid": dvid,
      "startTime": startTime,
      "endTime": endTime,
      "deviceType": "TEL100",
    })
  })
    .then(res => res.json())
    .then(data => {
      // console.log("Raw Device Log Debug Data:", data);

      return data.Items;
    })
  // .catch(err=>console.log('err at getDeviceLogDebugDeviceByTimeframe', err))
}

export const reassignGeofenceTemplates = (uid, selectedGeoID, gtidList) => {
  return fetch(`${api}/geofence/template/reassign/geofence`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "geoid": selectedGeoID,
      "gtids": gtidList, // This array cannot be empty
    })
  })
    .then(res => res.json())
}

export const getNotificationLogByTimeframe = (nfid, startTime, endTime) => {
  return fetch(`${api}/notificationlog/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "nfid": nfid,
      "startTime": startTime,
      "endTime": endTime,
    })
  })
    .then(res => res.json())
}

export const reassignVehicleGroups = (uid, selectedVID, vgidList) => (
  fetch(`${api}/vehicle/group/reassign/vehicle`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "vid": selectedVID,
      "vgids": vgidList, // This array cannot be empty
    })
  })
    .then(res => res.json())
)

export const assignVehicleGroups = (uid, selectedVGID, vidList) => (
  fetch(`${api}/vehicle/group/assign/vehicle`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      "uid": uid,
      "vgid": selectedVGID,
      "vids": vidList,
    })
  })
    .then(res => res.json())
)

export const getEscalationStates = (nfids, startTime, endTime) => (
  fetch(`${api}/escalationcallstate/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      nfids,
      startTime,
      endTime
    })
  })
    .then(res => res.json())
)

export const updateEscalationState = (edittedState) => (
  fetch(`${api}/escalationcallstate/update`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      escalationCallState: edittedState
    })
  })
    .then(res => res.json())
)

export const getEscalationRecords = (ecids, startTime, endTime) => (
  fetch(`${api}/escalationcallrecord/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ecids,
      startTime,
      endTime
    })
  })
    .then(res => res.json())
)

export const getTicketStates = (vids) => {
  return fetch(`${api}/ticket/get`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      vids
    })
  })
    .then(res => res.json())
}

export const resolveTicketStates = (ticketStates, remark, uid) => {

  return fetch(`${api}/ticket/acknowledge`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ticketStates, remark, uid
    })
  })
    .then(res => res.json())
}

export const fakeAPI = () => {
  return new Promise((res, rej)=>{
      setTimeout(()=>res(), 1*1000)
  })
}