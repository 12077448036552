// import React from 'react';

import sanityCheckNumber from '../../../util/SanityCheckNumber';

import { message } from 'antd';

const returnNewDataSource = (currTable, vehicles, geofences, geofenceTemplates, data) => {
    // console.log("Current Report:", currTable);

    let newDataSource = [];

    switch(currTable) {
        case "TRIP": {
            newDataSource = data.tripReport;

            break;
        }

        case "EVENT": {
            newDataSource = data.eventReport;
            
            break;
        }

        case "DETAIL": {
            newDataSource = data.detailReport;
            
            break;
        }

        case "MILEAGE": {
            newDataSource = data;
            
            break;
        }

        case "TRANSIT": {
            newDataSource = data.transitReport;
            
            break;
        }

        case "VEHICLE": {
            newDataSource = data.vehicles;
            
            break;
        }

        case "GEOFENCE": {
            newDataSource = data.geofenceReport;
            
            break;
        }
        default: return null
    }

    // console.log("Unfiltered New Data Source:", newDataSource);

    // Set Up
    switch(currTable) {
        case "MILEAGE": {
            newDataSource = newDataSource
            .map((d, index) => (
                {
                    ...d, 
                    key: index, 
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    // totalEngineDuration: parseDuration(d.totalEngineDuration),
                    // totalIdlingDuration: parseDuration(d.totalIdlingDuration),
                    // totalMovingDuration: parseDuration(d.totalMovingDuration),
                    // totalParkingDuration: parseDuration(d.totalParkingDuration),
                }
            ))
            
            break;
        }

        case "TRIP": {
            newDataSource = newDataSource
            .map((d, index) => (
                {
                    ...d, 
                    key: index, 
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    fuelUsed: sanityCheckNumber(d.fuelUsed) ? d.fuelUsed.toFixed(2) : "-",
                    topSpeed: sanityCheckNumber(d.topSpeed) ? d.topSpeed.toFixed(2) : "-",
                    totalMileage: sanityCheckNumber(d.totalMileage) ? d.totalMileage.toFixed(2) : "-",
                    totalMovingDuration: d.tripStatus === "PARKING" ? 0 : d.totalMovingDuration,
                    // startLocation: 
                    //     d.startLocation && d.startLocation.lat && d.startLocation.lon ? 
                    //         returnAddress(d.startLocation.lat, d.startLocation.lon, d.startAddress) : 
                    //         "-",
                    // endLocation: 
                    //     d.endLocation && d.endLocation.lat && d.endLocation.lon ? 
                    //         returnAddress(d.endLocation.lat, d.endLocation.lon, d.endAddress) : 
                    //         "-",
                    // endTime: parseDate(d.endTime),
                    // startTime: parseDate(d.startTime),
                    // duration: parseDuration(d.duration),
                    // totalIdlingDuration: parseDuration(d.totalIdlingDuration),
                    // totalMovingDuration: parseDuration(d.totalMovingDuration),
                }
            ))
            
            break;
        }

        case "TRANSIT": {
            newDataSource = newDataSource
            .map((d, index) => (
                {
                    ...d, 
                    key: index, 
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    totalMileage: sanityCheckNumber(d.totalMileage) ? d.totalMileage.toFixed(2) : "-",
                    fuelUsed: sanityCheckNumber(d.totalMovingFuelConsumption) ? d.totalMovingFuelConsumption.toFixed(2) : "-",
                    // startLocation: 
                    //     d.startLocation && d.startLocation.lat && d.startLocation.lon ? 
                    //         returnAddress(d.startLocation.lat, d.startLocation.lon, d.startAddress) : 
                    //         "-",
                    // endLocation: 
                    //     d.endLocation && d.endLocation.lat && d.endLocation.lon ? 
                    //         returnAddress(d.endLocation.lat, d.endLocation.lon, d.endAddress) : 
                    //         "-",
                    // endTime: parseDate(d.endTime),
                    // startTime: parseDate(d.startTime),
                    // duration: parseDuration(d.duration),
                }
            ))
            
            break;
        }

        case "DETAIL": {
            newDataSource = newDataSource
            .map((d, index) => (
                {
                    ...d, 
                    key: index, 
                    deviceIMEI: d.dvid.split("#")[1],
                    gpsStatus: d.gpsStatus ? "ON" : "OFF",
                    engineStatus: d.engineStatus ? "ON" : "OFF",
                    fuel: sanityCheckNumber(d.fuel) ? d.fuel.toFixed(2) : "-",
                    speed: sanityCheckNumber(d.speed) ? d.speed.toFixed(2) : "-",
                    heading: !(d.heading === null || isNaN(Number(d.heading))) ? d.heading : "-",
                    // location: 
                    //     d.location && d.location.lat && d.location.lon ? 
                    //         returnAddress(d.location.lat, d.location.lon, d.address) : 
                    //         "-",
                    vehicle: 
                        d.dvid 
                        && Object.values(vehicles.byId).find((currVehicle) => currVehicle.dvid === d.dvid)
                        && Object.values(vehicles.byId).find((currVehicle) => currVehicle.dvid === d.dvid).vehicleDisplayName ? 
                            Object.values(vehicles.byId).find((currVehicle) => currVehicle.dvid === d.dvid).vehicleDisplayName :
                            "-",
                    // createdAt: parseDate(d.createdAt),
                    // deviceTime: parseDate(d.deviceTime),
                }
            ))
            
            break;
        }

        case "GEOFENCE": {
            newDataSource = newDataSource
            .map((d, index) => {
                let geofenceTemplatesString = "";

                Object.values(geofenceTemplates.byId)
                .filter((currGT) => currGT.geofences.includes(d.geoid))
                .sort((a, b) => {
                    const A = a.templateName.toLowerCase();
                    const B = b.templateName.toLowerCase();
            
                    if (B === "ungroup" || A < B) return -1;
                    if (A === "ungroup" || A > B) return 1;
                    return 0;
                })
                .forEach((currGT) => {
                    !geofenceTemplatesString ? geofenceTemplatesString += currGT.templateName : geofenceTemplatesString += `, ${currGT.templateName}`;
                })

                return ({
                    ...d, 
                    key: index, 
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    geofenceTemplates: geofenceTemplatesString,
                    geofenceName: geofences.byId[d.geoid].geofenceName,
                    // inAt: parseDate(d.inAt),
                    // outAt: parseDate(d.outAt),
                    // duration: parseDuration(d.duration),
                })
            })
            
            break;
        }

        case "EVENT": {
            newDataSource = newDataSource
            .map((d, index) => (
                {
                    ...d, 
                    key: index, 
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                    // location: d.location && d.location.lat && d.location.lon ? `${d.location.lat}, ${d.location.lon}` : "-",
                    // location: 
                    //     d.location && d.location.lat && d.location.lon ? 
                    //         returnAddress(d.location.lat, d.location.lon, d.address) : 
                    //         "-",
                    // createdAt: parseDate(d.createdAt),
                }
            ))

            break;
        }

        default: {
            newDataSource = newDataSource
            .map((d, index) => (
                {
                    ...d, 
                    key: index, 
                    vehicle: vehicles.byId[d.vid].vehicleDisplayName,
                }
            ))
            
            break;
        }
    }

    // Sort Data Source
    newDataSource = newDataSource.sort((a, b) => {
        const vA = a.vehicle.toLowerCase();
        const vB = b.vehicle.toLowerCase();

        if (vA < vB) return -1;
        if (vA > vB) return 1;
        return 0;
    })

    if (!newDataSource.length) {
        message.info("No reports found!");
    }

    // console.log("New Data Source:", newDataSource);

    return newDataSource;
}

export default returnNewDataSource;