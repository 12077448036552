import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import ExportExcelButton from '../../components/ExportExcelButton';
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { parseTime } from '../../util/time';
import {
    Table,
    Select,
    Button,
    DatePicker,
} from 'antd';

// React Actions
import { select_user } from "../../services/redux/actions/hierarchy";
import { get_device_log_debug_by_timeframe_request } from "../../services/redux/actions/deviceLog";

const { Option } = Select;

class DeviceLogInspectorPage extends React.Component {
    state = {
        date: moment(),

        selectedUID: "",
        selectedDVID: "",

        dataLog: null,
        startTime: moment().startOf("day"),
        endTime: moment().add(1, "day").startOf("day"),

        filteredInfo: {},
        sortedInfo: {
            order: 'descend',
            columnKey: 'createdAt',
        },
    }

    onSubmit = () => {
        let {
            endTime,
            startTime,
            selectedDVID,
        } = this.state;

        startTime = startTime.valueOf()
        endTime = endTime.valueOf()

        // console.log({
        //     selectedDVID,
        //     startTime,
        //     endTime
        // })

        this.props.dispatch(get_device_log_debug_by_timeframe_request(selectedDVID, startTime, endTime));
    }

    setupDefaultFormValues = () => {
        const state = this.props.router.location.state;
        
        // console.log({ state });

        const {
            uid,
            dvid,
        } = state;

        this.setState({
            selectedUID: uid,
            selectedDVID: dvid,
        })
    }

    setupDataSource = () => {
        const deviceLogs = this.props.deviceLog.debug.byId[this.state.selectedDVID] || []

        const dataSource = deviceLogs.map((log, index) => (
            {
                key: index,
                fuel: log.fuel,
                speed: log.speed,
                package: log.package,
                direction: log.heading,
                gpsStatus: log.gpsStatus,
                engineStatus: log.engineStatus,
                deviceMessage: log.deviceMessage,
                createdAt: log.createdAt,
                deviceTime: log.deviceTime,
                location: log.location,
                devicePackage: log.devicePackage,
            }
        ))

        this.setState({ dataSource });
    }

    componentDidMount = () => {
        this.setupDefaultFormValues()
    }

    componentDidUpdate = prevProps => {
        const {
            router,
            hierarchy,
        } = this.props;
        
        if (JSON.stringify(prevProps.router) !== JSON.stringify(router)) {
            this.setupDefaultFormValues()
        }

        if (JSON.stringify(prevProps.deviceLog) !== JSON.stringify(this.props.deviceLog)) {
            this.setupDataSource()
        }

        // Calling select_user action to populate device list in case user refresh this page
        // Possible infinite but shouldn't happen as selected device in this page is derived from user management device table which lists selected user's devices according to hierarchy store
        if (JSON.stringify(prevProps.hierarchy) !== JSON.stringify(hierarchy)
            && router.location.state.uid 
            && hierarchy.byId[router.location.state.uid]
            && !hierarchy.byId[router.location.state.uid].devices.length
        ) {
            this.props.dispatch(select_user(hierarchy.byId[router.location.state.uid]));
        }
    }

    render() {
        const {
            endTime,
            startTime,
            selectedUID,
            selectedDVID,
            dataSource = [],
        } = this.state;

        const { hierarchy } = this.props;

        const selectedUser = hierarchy.byId[selectedUID];

        // console.log("Test:", selectedUID, selectedUser);

        const columnsGPSLog = [
            {
                key: 'createdAt',
                title: 'Server Time',
                dataIndex: 'createdAt',
                sorter: (a, b) => a.createdAt - b.createdAt,
                render: time => parseTime(time)
            },

            {
                key: 'deviceTime',
                title: "Device Time",
                dataIndex: "deviceTime",
                defaultSortOrder: "descend",
                sorter: (a, b) => a.deviceTime - b.deviceTime,
                render: time => parseTime(time)
            },
            
            {
                title: "Data Package",
                dataIndex: "devicePackage",
                filters: [
                    {
                        text: "auth",
                        value: "auth"
                    },
                    {
                        text: "heartbeat",
                        value: "heartbeat"
                    },
                    {
                        text: "gps#latest",
                        value: "gps#latest"
                    },
                    {
                        text: "gps#memory",
                        value: "gps#memory"
                    },
                    {
                        text: "event#session#error",
                        value: "event#session#error"
                    },
                    {
                        text: "event#session#close",
                        value: "event#session#close"
                    },
                    {
                        text: "event#session#timeout",
                        value: "event#session#timeout"
                    }
                ],
                onFilter: (value, record) => record.devicePackage !== null && record.devicePackage.includes(value),
            },

            {
                title: "Message",
                dataIndex: "deviceMessage",
            },

            {
                title: "GPS Status",
                dataIndex: "gpsStatus",
                filters: [
                    {
                        text: "0",
                        value: 0
                    },
                    {
                        text: "1",
                        value: 1
                    },
                ],
                onFilter: (value, record) => record.gpsStatus !== null && record.gpsStatus === value,
            },

            {
                title: "ACC",
                dataIndex: "engineStatus",
                filters: [
                    {
                        text: "0",
                        value: 0
                    },
                    {
                        text: "1",
                        value: 1
                    },
                ],
                onFilter: (value, record) => record.engineStatus !== null && record.engineStatus === value,
            },

            {
                title: "Location",
                dataIndex: "location",
                render: location => {
                    if (
                        !location
                        || !location.lat
                        || !location.lon
                    ) return 'N/A'
                    return (
                        <a
                            href = {`https://www.google.com.my/maps/place/${location.lat},${location.lon}`}
                            target = "_blank"
                            rel = "noopener noreferrer"
                        >
                            {location.lat},{location.lon}
                        </a>
                    )

                }
            },

            {
                title: "Speed",
                dataIndex: "speed"
            },

            {
                title: "Direction",
                dataIndex: "direction"
            },

            {
                title: "Fuel",
                dataIndex: "fuel"
            },
        ]

        return (
            <div className = "page-container">
                <Page title = "Device Inspector Table">
                    <div style = {{ marginBottom: '10px' }}>
                        <Select
                            disabled = {!(selectedUser && selectedUser.devices && selectedUser.devices.length > 1)}
                            value = {selectedDVID}
                            onChange = {selectedDVID => {
                                this.setState({ selectedDVID })
                            }}
                            style = {{
                                width: 200,
                                marginRight: '10px'
                            }}
                        >
                            {
                                selectedUser 
                                && selectedUser.devices
                                    .sort((a, b) => {
                                        const A = a.dvid.toLowerCase();
                                        const B = b.dvid.toLowerCase();
                        
                                        if (A < B) return -1;
                                        if (A > B) return 1;
                                        return 0;
                                    })
                                    .map((currDevice, index) => {
                                        return (
                                            <Option key = {index} value = {currDevice.dvid}>{currDevice.dvid}</Option>
                                        )
                                    })
                            }
                        </Select>

                        <span style = {{ marginLeft: 10, marginRight: 5 }}>{"Start time: "}</span>
                        <DatePicker defaultValue = {startTime} onChange = {startTime => this.setState({ startTime })} showTime />

                        <span style = {{ marginLeft: 10, marginRight: 5 }}>{"End time: "}</span>
                        <DatePicker defaultValue = {endTime} onChange = {endTime => this.setState({ endTime })} showTime />

                        <Button
                            type = "primary"
                            disabled = {!selectedDVID}
                            onClick = {this.onSubmit}
                            loading = {this.props.style.isLoadingSubmit}
                            style = {{
                                marginLeft: '15px',
                                marginRight: '10px'
                            }}
                        >
                            Submit
                        </Button>

                        <ExportExcelButton
                            filename = {`Device Log Report ${moment().format('DD-MM-YYYY')}`}
                            sheetName = {`Device Log Report ${moment().format('DD-MM-YYYY')}`}
                            disabled = {dataSource.length === 0}
                            sheetData = {dataSource}
                            sheetRow = {
                                columnsGPSLog
                                    .filter(col => col.title !== 'Actions')
                                    .filter(col => col.title !== 'No')
                                    .map(col => {
                                        switch (col.dataIndex) {
                                            case 'deviceTime':
                                            case 'createdAt':
                                                return {
                                                    label: col.title,
                                                    formatter: value => col.render(value[col.dataIndex])
                                                }

                                            case 'location':
                                                return {
                                                    label: col.title,
                                                    formatter: value => JSON.stringify(value[col.dataIndex])
                                                }

                                            default:
                                                return {
                                                    label: col.title,
                                                    formatter: value => value[col.dataIndex]
                                                }
                                        }
                                    })
                            }
                        />
                    </div>

                    <Table
                        columns = {columnsGPSLog}
                        dataSource = {dataSource}
                        pagination = {{ pageSize: 25 }}
                        loading = {this.props.style.isLoadingSubmit}
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    deviceLog: state.v2.deviceLog,
    hierarchy: state.v2.hierarchy,
});

const ConnectedDeviceInspectorPage = connect(mapStateToProps)(DeviceLogInspectorPage);
export default showSecondarySidebar(false)(ConnectedDeviceInspectorPage);