import 
    React 
from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "antd";
import {
    LeftOutlined
} from '@ant-design/icons'

import {
    moveToDash,
} from "../../navigation/navigationService";
import { 
    signOutAccount,  
} from "../../services/auth/authService";
import {
    user_sign_out
} from "../../services/redux/actions/user";
import {
    IMAGE_RESOURCE,
    COMPANY,
    DISPLAY_USER_ROLE
} from "../../../../constants";

const ProfilePage = (props) => {

    const user = useSelector(state => state.v2.user)
    const style = useSelector(state => state.v2.style)

    const dispatch = useDispatch()

    return (
        <div className="page-container">
            <div
                className="page-header"
                style={{
                    backgroundColor: style.pageHeaderColor,
                    color: style.textColor
                }}
            >
                <div className="page-header-contents">
                    <button
                        className="transparent-button"
                        onClick={() => dispatch(moveToDash())}
                    >
                        <LeftOutlined />
                    </button>
                    <div>Profile</div>
                </div>
            </div>

            <div className='scroll-div' style={{display:'flex', flexDirection:'column'}}>
            <div style={{ position: "relative", alignSelf: "center" }}>
                <img
                    alt={COMPANY.NAME}
                    style={{
                        padding: "20px 0px 20px 0px",
                        width: "250px",
                        margin: "5px"
                    }}
                    src={IMAGE_RESOURCE.LOGO}
                />
            </div>

            <div style={{ alignSelf: "center", width: "80%" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    <div style={{ width: "40%" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            Name
                        </div>
                    </div>
                    <div style={{ width: "60%" }}>
                        {user.userName || "Perodua"}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    <div style={{ width: "40%" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            Email
                        </div>
                    </div>
                    <div style={{ width: "60%" }}>{user.userEmail}</div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    <div style={{ width: "40%" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            Phone Number
                        </div>
                    </div>
                    <div style={{ width: "60%" }}>
                        {user.userContact}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    <div style={{ width: "40%" }}>Role</div>
                    <div style={{ width: "60%" }}>
                        {DISPLAY_USER_ROLE(user.userRole)}
                    </div>
                </div>
                <div
                    style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px"
                    }}
                >
                    <Button
                        onClick={() => {
                            signOutAccount(() => dispatch(user_sign_out()))
                            // deleteSubscription();
                        }}
                    >
                        Log Out
                    </Button>
                </div>
            </div>

            <div style={{minHeight: '60px'}}></div>
            </div>
        </div>
    );
}

export default ProfilePage;
