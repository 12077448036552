import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { goBackToPrev } from '../../../navigation/navigationService';
import { 
    FUEL_TYPE,
    DISPLAY_FUEL_TYPE
} from "../../../../../constants";
import {
    Form,
    Input,
    Select,
    Button,
    DatePicker,
} from "antd";

// Redux Actions
import { update_fuel_record } from "../../../services/redux/actions/fuelRecord";

const { Option } = Select;
const { TextArea } = Input;

class FuelAnalysisRefuelReceiptPage extends React.Component {
    state = {
        // Set receipt default values here
        selectedFuelRecord: {
            fuelType: "",
            fuelStartAt: 0,
            fuelUnitPrice: 0,

            receiptID: "",
            receiptRemark: "", 
            receiptRefuelLevel: 0, // In percentage
            receiptDateTime: moment().startOf("day"),

            receiptFuelTotalPrice: Number(0).toFixed(2), // Not posted to API

            // VID: "",
            // fuelStartAt: 0,
            // fuelUnitPrice: 0,
            // fuelType: "",
            // receiptID: "",
            // receiptDateTime: 0,
            // receiptRefuelLevel: 0, // In percentage
            // receiptRemark: "",
        },
    }

    onSubmit = () => {
        const { fuelRecord } = this.props;
        const { selectedFuelRecord } = this.state;

        // console.log(`Submitting Selected Fuel Record: 
        //     - VID: ${fuelRecord.byFRID[fuelRecord.selectedFRID].vid}
        //     - Fuel Start At: ${selectedFuelRecord.fuelStartAt}
        //     - Fuel Unit Price: ${selectedFuelRecord.fuelUnitPrice}
        //     - Fuel Type: ${selectedFuelRecord.fuelType}
        //     - Receipt ID: ${selectedFuelRecord.receiptID}
        //     - Receipt Created At: ${selectedFuelRecord.receiptDateTime.valueOf()}
        //     - Fuel Level: ${selectedFuelRecord.receiptRefuelLevel}
        //     - Remarks: ${selectedFuelRecord.receiptRemark}
        // `);

        if (fuelRecord.selectedFRID
            && fuelRecord.byFRID[fuelRecord.selectedFRID]
            && fuelRecord.byFRID[fuelRecord.selectedFRID].vid
            && selectedFuelRecord.fuelStartAt
            && selectedFuelRecord.fuelUnitPrice
            && selectedFuelRecord.fuelType
            && selectedFuelRecord.receiptID
            && selectedFuelRecord.receiptDateTime.valueOf()
            && selectedFuelRecord.receiptRefuelLevel
            && selectedFuelRecord.receiptRemark) {
            this.props.dispatch(update_fuel_record(
                fuelRecord.byFRID[fuelRecord.selectedFRID].vid,
                selectedFuelRecord.fuelStartAt,
                selectedFuelRecord.fuelUnitPrice, 
                selectedFuelRecord.fuelType, 
                selectedFuelRecord.receiptID, 
                selectedFuelRecord.receiptDateTime.valueOf(), 
                selectedFuelRecord.receiptRefuelLevel, 
                selectedFuelRecord.receiptRemark, 
            ));
        }
    }

    componentDidMount = () => {
        const { fuelRecord } = this.props;

        if (fuelRecord.selectedFRID && fuelRecord.byFRID[fuelRecord.selectedFRID]) {
            this.setState({
                ...this.state,
                selectedFuelRecord: {
                    ...this.state.selectedFuelRecord,
                    fuelStartAt: fuelRecord.byFRID[fuelRecord.selectedFRID].fuelStartAt,
                }
            },
                // () => console.log("Current Selected Fuel Record:", this.state.selectedFuelRecord)
            );
        }
        else if (!fuelRecord.selectedFRID) {
            this.props.dispatch(goBackToPrev());
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.fuelRecord !== this.props.fuelRecord) {
            this.props.onSubmitReceipt();
        }
    }

    render() {
        const { style } = this.props;
        const { selectedFuelRecord } = this.state;
        
        return (
            <div className = "page-container">
                <Page title = "Refuel Receipt">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form>
                            <Form.Item
                                label = "Receipt ID"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input 
                                    disabled = {style.isLoadingSubmit}
                                    value = {selectedFuelRecord.receiptID}
                                    onFocus = {e => e.target.select()}
                                    onChange = {event => 
                                        this.setState({
                                            ...this.state,
                                            selectedFuelRecord: {
                                                ...selectedFuelRecord,
                                                receiptID: event.target.value,
                                            },
                                        })
                                    }
                                />
                            </Form.Item>
                            
                            <Form.Item
                                label = "Fuel Type"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Select 
                                    value = {selectedFuelRecord.fuelType}
                                    disabled = {style.isLoadingSubmit}
                                    onChange = {value => 
                                        this.setState({
                                            ...this.state,
                                            selectedFuelRecord: {
                                                ...selectedFuelRecord,
                                                fuelType: value,
                                            },
                                        })
                                    }
                                >
                                    {
                                        Object.keys(FUEL_TYPE).map((currType) => {
                                            return <Option key = {currType} value = {currType}>{DISPLAY_FUEL_TYPE[currType]}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            
                            <Form.Item
                                label = "Fuel Price/Litre"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input 
                                    min = {0}
                                    type = {"number"}
                                    disabled = {style.isLoadingSubmit}
                                    value = {selectedFuelRecord.fuelUnitPrice}
                                    onFocus = {e => e.target.select()}
                                    onChange = {event => 
                                        this.setState({
                                            ...this.state,
                                            selectedFuelRecord: {
                                                ...selectedFuelRecord,
                                                fuelUnitPrice: Number(event.target.value),
                                                receiptFuelTotalPrice: 
                                                    (selectedFuelRecord.receiptRefuelLevel * event.target.value).toFixed(2),
                                            },
                                        })
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label = "Refuel Amount (L)"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input 
                                    min = {0}
                                    type = {"number"}
                                    disabled = {style.isLoadingSubmit}
                                    value = {selectedFuelRecord.receiptRefuelLevel}
                                    onFocus = {e => e.target.select()}
                                    onChange = {event => 
                                        this.setState({
                                            ...this.state,
                                            selectedFuelRecord: {
                                                ...selectedFuelRecord,
                                                receiptRefuelLevel: Number(event.target.value),
                                                receiptFuelTotalPrice: 
                                                    (event.target.value * selectedFuelRecord.fuelUnitPrice).toFixed(2),
                                            },
                                        })
                                    }
                                />
                            </Form.Item>

                            {/* <Form.Item
                                label = "Total Price (RM)"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input 
                                    min = {0}
                                    type = {"number"}
                                    disabled = {style.isLoadingSubmit}
                                    value = {selectedFuelRecord.receiptRefuelLevel}
                                    onChange = {event => 
                                        this.setState({
                                            ...this.state,
                                            selectedFuelRecord: {
                                                ...selectedFuelRecord,
                                                receiptRefuelLevel: Number(event.target.value),
                                                receiptFuelTotalPrice: 
                                                    (event.target.value * selectedFuelRecord.fuelUnitPrice).toFixed(2),
                                            },
                                        })
                                    }
                                />
                            </Form.Item> */}
                            
                            <Form.Item
                                label = "Total Price (RM)"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {selectedFuelRecord.receiptFuelTotalPrice}
                            </Form.Item>

                            <Form.Item
                                label = "Date"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <DatePicker
                                    defaultValue = {selectedFuelRecord.receiptDateTime}
                                    disabled = {style.isLoadingSubmit}
                                    style = {{ width: '177px' }}
                                    onChange = {value => this.setState({ 
                                        ...this.state,
                                        startTime: value 
                                    })}
                                    showTime
                                />
                            </Form.Item>
                            
                            <Form.Item
                                label = "Remark"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <TextArea 
                                    value = {selectedFuelRecord.receiptRemark}
                                    disabled = {style.isLoadingSubmit}
                                    onFocus = {e => e.target.select()}
                                    onChange = {event => 
                                        this.setState({
                                            ...this.state,
                                            selectedFuelRecord: {
                                                ...selectedFuelRecord,
                                                receiptRemark: event.target.value,
                                            },
                                        })
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </div>

                    <div
                        style = {{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button 
                            type = "primary" 
                            style = {{ marginRight: 5 }} 
                            onClick = {() => this.props.onCancelReceipt()}
                        >
                            Cancel
                        </Button>

                        <Button 
                            type = "primary"
                            onClick = {() => this.onSubmit()}
                        >
                            Submit
                        </Button>
                    </div>
                </Page>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    fuelRecord: state.v2.fuelRecord,
});

const ConnectedFuelAnalysisRefuelReceiptPage = connect(mapStateToProps)(FuelAnalysisRefuelReceiptPage);
export default showSecondarySidebar(false)(ConnectedFuelAnalysisRefuelReceiptPage);