// import firebase from 'firebase';
// import { useDispatch } from 'react-redux';
import {
    closeWS,
    wsURL,
    wsClient,
    wsReconnectInterval
} from './config'
// import { user_sign_in_request } from '../redux/actions/user'

var wsc;

// const dispatch = useDispatch()

const wsContainer = (
    cb = () => { },
    resubscribe = () => {}
) => {

    if (!wsc || ![WebSocket.OPEN, WebSocket.CONNECTING].includes(wsc.readyState)) {
        wsc = new WebSocket(wsURL);
    }


    wsClient(
        wsc,
        (data, timer) => {
            // console.log(data);

            if (data === 'reconnect') {
                // console.log(`restarting`);

                resubscribe();

                // console.log("current session state", wsc.readyState);
                
                setTimeout(wsContainer(cb, resubscribe), wsReconnectInterval);
            }
        },
        (err, data) => {
            // console.log("[WebSoc]", data)
            cb(err, data)
        }
    )
}

const subscribeWS = (ids, uid) => {

    const subscribe_msg = {
        action: "subscribeTo",
        data: {
            ids: ids,
            uid: uid
        }
    }

    // console.log("websocket state", client.readyState)

    if (wsc.readyState === 0) {

        const retry = setInterval(
            () => {
                if (wsc.readyState !== 0) {
                    // console.log("Sending subscribe message in timeout",subscribe_msg);
                    wsc.send(JSON.stringify(subscribe_msg));
                    clearInterval(retry)
                }
            },
            3000
        )

    } else {
        wsc.send(JSON.stringify(subscribe_msg))
    }

}

export {
    closeWS,
    subscribeWS,
    wsContainer
}