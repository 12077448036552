import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import * as API from '../../../services/api';
import Highlighter from 'react-highlight-words';
import UploadExcelButton from "../../../components/UploadExcelButton";

import { ROLE_TYPE } from '../../../../../constants';
import { PrimaryButton } from "../../../components/Button";
import { moveToPage } from "../../../navigation/navigationService";
import {
  Table,
  Input,
  Button
} from "antd";
import {
  VEHICLE_TYPE, // Just to check if uploaded excel is valid
  VEHICLE_COLOR,
  TEMPLATE_URLS,
} from "../../../../../constants";
import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

// Redux Actions
import { add_new_vehicle_success } from "../../../services/redux/actions/vehicles";

class VehiclePanel extends React.Component {
  state = {
    filteredInfo: null,
  }

  isVehicleTypeValid = (type) => {
    let isValid = false;

    for (let i = 0; i < VEHICLE_TYPE.length; i++) {
      const currType = VEHICLE_TYPE[i];

      if (currType.label === type) {
        isValid = true;

        break;
      }
    }

    return isValid;
  }

  onChangeTable = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);

    this.setState({
      sortedInfo: sorter,
      filteredInfo: filters,
    });
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style = {{ padding: 8 }}>
        <Input
          ref = {node => {
            this.searchInput = node;
          }}
          value = {selectedKeys[0]}
          placeholder = {`Search ${dataIndex}`}
          style = {{ width: 188, marginBottom: 8, display: 'block' }}
          onPressEnter = {() => this.handleSearch(selectedKeys, confirm)}
          onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        />

        <Button
          size = "small"
          icon = {<SearchOutlined />}
          type = "primary"
          style = {{ width: 90, marginRight: 8 }}
          onClick = {() => this.handleSearch(selectedKeys, confirm)}
        >
          Search
        </Button>

        <Button size = "small" style = {{ width: 90 }} onClick = {() => this.handleReset(clearFilters)}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style = {{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        searchWords = {[this.state.searchText]}
        textToHighlight = {text ? text.toString() : "-"}
        highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
        autoEscape
      />
    ),
  });

  setUpDataSource = () => {
    const dataSource =
      Object.values(this.props.vehicles.byId)
        .map(vehicle => {
          const device = vehicle.dvid && Object.values(this.props.devices.byId).find(device => device.dvid === vehicle.dvid);
          const vgList =
            Object.values(this.props.vehicleGroups.byVGID)
              .filter((currVG) => currVG.groupName !== "ALL" && currVG.vehicles.includes(vehicle.vid)) // Make sure to remove default VG as we'll add it later
              .map((currVG) => currVG.groupName)
              .sort((a, b) => {
                const A = a.toLowerCase();
                const B = b.toLowerCase();

                if (A < B) return -1;
                if (A > B) return 1;

                return 0;
              })

          // Hide default VG
          let vgStr = vgList.join(',');

          return {
            key: vehicle.vid,
            vehiclePlate: vehicle.vehiclePlate,
            vehicleDisplayName: vehicle.vehicleDisplayName,
            vehicleType: vehicle.vehicleType,
            vehicleModel: vehicle.vehicleModel,
            dvid: vehicle.dvid,
            simCard: device && device.simCard,
            transitStatus: vehicle.transitStatus,
            mileage: vehicle.mileage,
            createdAt: vehicle.createdAt,
            updatedAt: vehicle.updatedAt,
            vehicleGroups: vgStr,
          }
        })
        .filter((currVehicle) => currVehicle.vehiclePlate)
        .sort((a, b) => {
          const A = a.createdAt;
          const B = b.createdAt;

          if (A < B) return -1;
          if (A > B) return 1;

          return 0;
        })

    const columns = [
      {
        title: "Vehicle Plate",
        dataIndex: "vehiclePlate",
        fixed: 'left',
        ...this.getColumnSearchProps("vehiclePlate"),
      },
      {
        title: "Vehicle Name",
        dataIndex: "vehicleDisplayName",
        ...this.getColumnSearchProps("vehicleDisplayName"),
      },
      {
        title: "Vehicle Groups",
        dataIndex: "vehicleGroups",
        ...this.getColumnSearchProps("vehicleGroups"),
      },
      {
        title: "Type",
        dataIndex: "vehicleType",
        ...this.getColumnSearchProps("vehicleType"),
      },
      {
        title: "Model",
        dataIndex: "vehicleModel",
        ...this.getColumnSearchProps("vehicleModel"),
      },
      {
        title: "Assigned Device",
        dataIndex: "dvid",
        ...this.getColumnSearchProps("dvid"),
      },
      {
        title: "Device Sim Card",
        dataIndex: "simCard",
        key: "simCard",
        ...this.getColumnSearchProps("simCard"),
      },
      {
        title: "Status",
        dataIndex: "transitStatus",
        key: "transitStatus",
        render: status => {
          if (!status) {
            return <div style = {{ color: VEHICLE_COLOR["DISCONNECTED"] }}>{status}</div>
          }

          return <div style = {{ color: VEHICLE_COLOR[status] }}>{status}</div>
        },
        // filteredValue: filteredInfo.transitStatus || null,
        filters: [
          { text: "MOVING", value: "MOVING" },
          { text: "IDLING", value: "IDLING" },
          { text: "PARKING", value: "PARKING" },
          { text: "DISCONNECTED", value: "DISCONNECTED" }
        ],
        onFilter: (value, record) => record.transitStatus.includes(value),
      },
      {
        //@IDEA: prefer the database is set to odometer, instead of mileage
        title: "Odometer (KM)",
        dataIndex: "mileage",
        key: "mileage",
        render: mileage => {
          const mileageString = (mileage && !isNaN(Number(mileage)) && Math.floor(Number(mileage) / 1000)) || 0

          return (mileageString && `${mileageString} km`) || '-'

        },
        sorter: (a, b) => a.mileage - b.mileage,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Registration Date",
        dataIndex: "createdAt",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.createdAt - b.createdAt,
        render: text => moment(Number(text)).format("YYYY/MM/DD h:mm:ss A"),
      },
      {
        title: "Actions",
        fixed: 'right',
        render: vehicle => (
          <Button.Group>
            <button className = "transparent-button">
              <EditOutlined 
                onClick = {() => {
                  this.props.dispatch(moveToPage(`/Management/Vehicle/Edit/${vehicle.key}`, {
                    vehicle: this.props.vehicles.byId[vehicle.key],
                    uid: this.props.user.uid
                  }))
                }} 
              />
            </button>

            {/* <Popconfirm
              placement = "right"
              title = "Are you sure?"
              onConfirm = {() => this.archiveVehicle(vehicle.vid)}
              okText = "Yes"
              cancelText = "No"
            >
              <button className = "transparent-button">
                <Icon type = "delete" />
              </button>
            </Popconfirm> */}
          </Button.Group>
        )
      }
    ];

    this.setState({
      columns,
      dataSource,
    })
  }

  componentDidMount = () => {
    this.setUpDataSource()
  }

  componentDidUpdate = prevProps => {
    if (
      JSON.stringify(this.props.devices) !== JSON.stringify(prevProps.devices)
      || JSON.stringify(this.props.vehicles) !== JSON.stringify(prevProps.vehicles)
    ) {
      this.setUpDataSource()
    }
  }

  render() {
    const {
      columns,
      dataSource,
    } = this.state;

    return (
      <div style = {{ display: "flex", flex: 1 }}>
        <div
          style = {{
            width: "95%",
            margin: "0 5%"
          }}
        >
          <div
            style = {{
              display: "flex",
              justifyContent: "flex-end",

              padding: 5,
              marginBottom: 10,
            }}
          >
            <UploadExcelButton
              title = 'Bulk create vehicles'
              buttonName = {`Bulk Create Vehicles`}
              templateUrl = {TEMPLATE_URLS.BULK_CREATE_VEHICLE_TEMPLATE}
              loading = {this.props.style.isLoadingSubmit}
              excelTemplateName = {`Bulk Create Vehicle Template`}
              dataColumns = {
                {
                  vehicleDisplayName: {
                    label: 'Vehicle Display Name',
                    rule: (vehicleDisplayName, allVehicleDisplayName) => {
                      return true
                    },
                  },
                  vehiclePlate: {
                    label: 'Vehicle Plate',
                    rule: (vehiclePlate, allVehiclePlates) => {
                      return true
                    },
                  },
                  imei: {
                    optional: true,
                    label: 'Device Imei',
                    rule: (imei) => {
                      const device = Object.keys(this.props.devices.byId).find(dvid => dvid.includes(imei));
                      const availableDevices = Object.values(this.props.devices.byId).filter(d => d.assignedStatus !== 1).map(d => d.dvid.split('#')[1]);

                      if (!device) throw new Error(`Invalid imei. Available devices are ${availableDevices}`)
                      if (device.assignedStatus === 1) throw new Error(`Device is already assigned. Available devices are ${availableDevices}`)

                      return true
                    },
                  }
                }
              }
              uploadLoop = {async (excelData) => {
                const imei = excelData.imei && excelData.imei.toString();
                const dvid = Object.keys(this.props.devices.byId).find(dvid => dvid.includes(imei));

                const vehiclePlate = excelData.vehiclePlate && excelData.vehiclePlate.toString();
                const vehicleDisplayName = excelData.vehicleDisplayName && excelData.vehicleDisplayName.toString();

                const vehicle = {
                  vehicleDisplayName,
                  vehiclePlate,
                  dvid: dvid || '-',
                }

                const vgids = Object.values(this.props.vehicleGroups.byVGID).filter(vg => vg.groupName === 'ALL').map(vg => vg.vgid);
                const rtids = Object.values(this.props.ruleTemplate.byId).filter(rt => rt.templateName === 'DEFAULT').map(rt => rt.rtid);
                const gtids = Object.values(this.props.geofenceTemplate.byId).filter(gt => gt.templateName === 'UNGROUP').map(gt => gt.gtid);

                const targetUserDetails = {
                  uid: this.props.user.uid,
                  userRole: this.props.user.userRole,
                }

                // console.log({ device: dvid, imei, vehicle, targetUserDetails, vgids, gtids, rtids })

                const data = await API.addVehicle(vehicle, targetUserDetails, vgids, gtids, rtids)

                switch (data.status) {
                  case 200: {
                    this.props.dispatch(add_new_vehicle_success(data.vehicle));

                    return {
                      uploadStatus: 'SUCCESS',
                      message: ''
                    }
                  }
                  default: {
                    return {
                      uploadStatus: 'FAILED',
                      message: data.message
                    }
                  }
                }
              }}
            />

            {
              this.props.user.userRole !== ROLE_TYPE.FLEET_OWNER ?
                <PrimaryButton
                  icon = {<PlusOutlined />}
                  onClick = {() => this.props.dispatch(moveToPage("/Management/Vehicle/Add", { uid: this.props.user.uid }))}
                  style = {{ marginLeft: 10 }}
                >
                  Add New Vehicle
                </PrimaryButton> :
                null
            }

            {
              this.props.user.userRole !== ROLE_TYPE.FLEET_OWNER ?
                <PrimaryButton
                  icon = {<PlusOutlined />}
                  onClick = {() => this.props.dispatch(moveToPage("/Management/VehicleGroup/Add"))}
                  style = {{ marginLeft: 10 }}
                >
                  Add New Vehicle Group
                </PrimaryButton> :
                null
            }
          </div>

          <div>
            <Table
              loading = {this.props.style.isLoadingSubmit}
              dataSource = {dataSource}
              columns = {columns}
              onChange = {this.onChangeTable}
              scroll = {{
                x: true,
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.v2.user, // To create new geofences when uploading
  style: state.v2.style,
  devices: state.v2.devices,
  vehicles: state.v2.vehicles,
  ruleTemplate: state.v2.ruleTemplate, // To create new geofences when uploading
  vehicleGroups: state.v2.vehicleGroups, // To create new geofences when uploading
  geofenceTemplate: state.v2.geofenceTemplate, // To create new geofences when uploading
});

export default connect(mapStateToProps)(VehiclePanel);