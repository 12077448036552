import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { DEFAULT_GEOFENCE_HEXCODE } from "../../../../../constants";
import {
    Marker,
    Circle,
    OverlayView,
} from '@react-google-maps/api';

// Redux Actions
import { 
    set_map_control,
    clear_enabled_geofence_pop_ups
} from '../../../services/redux/actions/mapControl';
import {
    set_selected_geofence_geoid,
    clear_selected_geofence_geoid
} from '../../../services/redux/actions/geofences';

import "../gmaps.css";

const POIGeofences = (props) => {
    const { mapRef } = props;

    // State
    const [circleRefs, setCircleRefs] = useState([]);

    // Redux Stores
    const vehicles = useSelector(state => state.v2.vehicles);
    const geofences = useSelector(state => state.v2.geofences);
    const mapControl = useSelector(state => state.v2.mapControl);
    const geofenceTemplates = useSelector(state => state.v2.geofenceTemplate);

    const dispatch = useDispatch();

    const returnLatestGeofenceTemplateColorForGeoID = (geoID) => {
        let latestTemplate = {};

        Object.values(geofenceTemplates.byId)
            .filter((currTemplate) => currTemplate.geofences.includes(geoID)) // Only check geofence templates containing this geofence ID
            .forEach((currTemplate) => {
                if (!latestTemplate.createdAt || currTemplate.createdAt > latestTemplate.createdAt) {
                    latestTemplate = currTemplate;
                }
            })

        // console.log("Color:", latestTemplate.colorHexCode);

        return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }

    const getMarker = (colorHexCode) => {
        return require(`../../../../../img/POI Markers 2/${(colorHexCode || DEFAULT_GEOFENCE_HEXCODE).replace("#", "").toUpperCase()}.svg`)
    }

    // Initial mount of component
    useEffect(() => {
        dispatch(clear_selected_geofence_geoid());
        dispatch(clear_enabled_geofence_pop_ups());
    }, 
        [dispatch]
    )

    useEffect(() => {
        if (
            geofences.selectedId
            && geofences.byId[geofences.selectedId]
            && geofences.byId[geofences.selectedId].geoType === 'POI'
            && geofences.byId[geofences.selectedId].coordinates
            && mapControl.mapControl === geofences.selectedId
        ) {
            const thisCircleRef = circleRefs.find(r => r.id === geofences.selectedId)

            if (mapRef) {
                // console.log("Circle Ref Bounds:", thisCircleRef.circle.getBounds());
                
                const bounds = thisCircleRef.circle.getBounds();
                mapRef.fitBounds(bounds);

                dispatch(set_map_control(0));
            }
        }
    })

    const getCircle = (geofence) => {
        const circle = (
            <div key = {geofence.geoid}>
                <Circle
                    onLoad = {ref => {
                        const newCircleRef = {
                            id: geofence.geoid,
                            circle: ref
                        }
                        circleRefs.push(newCircleRef)
                        setCircleRefs(circleRefs)
                    }}
                    key = {`c#${geofence.geoid}`}
                    center = {geofence.coordinates[0]}
                    radius = {geofence.geofenceRadius}
                    options = {{
                        // strokeColor: '#ff0000'
                        strokeColor: returnLatestGeofenceTemplateColorForGeoID(geofence.geoid)
                    }}
                    onClick = {() => {
                        // console.log('circle clicked', geofence.geoid);

                        dispatch(set_selected_geofence_geoid(geofence.geoid));
                        dispatch(set_map_control(geofence.geoid));
                    }}
                />

                <Marker
                    key = {geofence.geoid}
                    icon = {getMarker(returnLatestGeofenceTemplateColorForGeoID(geofence.geoid))}
                    title = {geofence.geofenceName}
                    position = {geofence.coordinates[0]}
                    onClick = {() => {
                        // console.log('marker clicked', geofence.geoid);

                        dispatch(set_selected_geofence_geoid(geofence.geoid));
                        dispatch(set_map_control(geofence.geoid));
                    }}
                />

                {
                    mapControl.uiControl.showAllGeofencesInfoWindow &&
                        <OverlayView
                            options = {{ disableAutoPan: true }}
                            position = {geofence.coordinates[0]}
                            mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                            getPixelPositionOffset = {(width, height) => ({
                                x: -(width / 2),
                                y: -height - 45,
                            })}
                        >
                            <div 
                                style = {{
                                    background: 'white',

                                    border: '1px solid #ccc',
                                    borderRadius: '10px',

                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 8,
                                    paddingBottom: 1,
                                }}
                            >
                                <h4>{geofence.geofenceName}</h4>
                                <div>{geofence.geofenceComment}</div>
                            </div>
                        </OverlayView>
                }
            </div>
        )

        return circle;
    }

    return (
        vehicles &&
        geofences.allIds
            .filter(geoid => {
                return mapControl.uiControl.showAllGeofence && geofences.byId[geoid].geoType === 'POI';
            })
            .map(geoid => {
                return getCircle(geofences.byId[geoid]);
            })
    )
}

export default POIGeofences