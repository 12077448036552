import moment from "moment";
import { isNumber, toNumber } from "lodash";

export const parseDuration = millisec => {
  let seconds = (millisec / 1000).toFixed(0);
  let minutes = Math.floor(seconds / 60);
  let hours = 0;

  // console.log("milisec", millisec)
  // console.log("minute", minutes);

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;
  }

  seconds = Math.floor(seconds % 60);

  if(Number.isNaN(millisec)){return " Not Available "}else if(hours > 0){
    return `${hours} ${hours > 1 ? "hrs" : "hr"} ${minutes} ${minutes > 1 ? "mins" : "min"}`.trim()
  }else return `${minutes} ${minutes > 1 ? "mins" : "min"} ${seconds} ${ seconds > 1 ? "secs" : "sec"}`.trim()


};

/** Return a simple duration that is rounded to the nearest time unit
 * @param {(number|string)} duration - duration to be parsed in milliseconds
 * @param {boolean} isRelativeToNow - whether to show suffix that describes the duration relatively to current time
 */
export const humanizeDuration = (duration, isRelativeToNow = false) =>
  moment
    .duration(isNumber(duration) ? duration : toNumber(duration))
    .humanize(isRelativeToNow);
