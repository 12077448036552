import * as API from "../../api";
import * as actionType from "../action-types/vehicleGroups";

import { message } from "antd";
import { subscribeWS } from '../../websocket';
import { goBackToPrev } from "../../../navigation/navigationService";
import {
  setLoadingSubmit,
  unsetLoadingSubmit,
} from './style';

// Redux Actions
import { get_todays_events } from './events';
import { get_vehicles_data } from './vehicles';
import { get_ticket_states } from './ticketStates';

export const get_vehicles_groups = (vehicleGroupArray, uid) => (dispatch) => {
  API.getVehicleByGroup(vehicleGroupArray, uid)
    .then(data => {
      // console.log("get_vehicles_groups data", data);

      const {
        vehicles = [],
        vehicleGroups
      } = data;

      const vids = vehicles.map(vehicle => vehicle.vid);

      subscribeWS(vids, uid);

      dispatch(get_vehicles_group_success(vehicleGroups));

      dispatch(get_ticket_states(vids));
      dispatch(get_todays_events(vids));
      dispatch(get_vehicles_data(vehicles));
    })
}

const get_vehicles_group_success = (vehicleGroups) => {
  return {
    type: actionType.GET_VEHICLE_GROUPS,
    vehicleGroups: vehicleGroups
  }
}

export const add_vehicle_group = (uid, groupName) => (dispatch) => {
  dispatch(setLoadingSubmit());

  API.addVehicleGroup(uid, groupName)
    .then(data => {
      // console.log("Add Vehicle Group Data", data);

      if (data.errorMessage || data.message || (data.err && (data.err.message || data.err))) {
        message.error(data.errorMessage || data.message || (data.err && (data.err.message || data.err)));

        dispatch(unsetLoadingSubmit());
      }
      else {
        message.success("Successfully Added New Vehicle Group");

        dispatch(add_vehicles_group_success(data.vehicleGroup));

        dispatch(unsetLoadingSubmit());
      }
    })
}

const add_vehicles_group_success = (vehicleGroup) => {
  return {
    type: actionType.ADD_VEHICLE_GROUP,
    vehicleGroup: vehicleGroup
  }
}

export const edit_vehicle_group = (editedVG) => (dispatch) => {
  dispatch(setLoadingSubmit());

  API.editVehicleGroup(editedVG)
    .then(data => {
      // console.log("Edit Vehicle Group Data", data);

      if (data.errorMessage || data.message || (data.err && (data.err.message || data.err))) {
        message.error(data.errorMessage || data.message || (data.err && (data.err.message || data.err)));

        dispatch(unsetLoadingSubmit());
      }
      else {
        message.success("Successfully Edited Vehicle Group");

        dispatch(edit_vehicles_group_success(data.vehicleGroup));

        dispatch(goBackToPrev());

        dispatch(unsetLoadingSubmit());
      }
    })
}

const edit_vehicles_group_success = (vehicleGroup) => {
  return {
    type: actionType.EDIT_VEHICLE_GROUP,
    vehicleGroup: vehicleGroup
  }
}

export const reassign_vehicles_groups = (uid, selectedVID, vgidList) => (dispatch) => {
  dispatch(setLoadingSubmit());

  API.reassignVehicleGroups(uid, selectedVID, vgidList)
    .then(data => {
      // console.log("Reassign Vehicle Group Data", data);

      if (data.errorMessage || data.message || (data.err && (data.err.message || data.err))) {
        message.error(data.errorMessage || data.message || (data.err && (data.err.message || data.err)));

        dispatch(unsetLoadingSubmit());
      }
      else {
        message.success("Successfully Reassigned Vehicle Group(s)");

        dispatch(reassign_vehicles_group_success(data.vehicleGroups));

        dispatch(unsetLoadingSubmit());
      }
    })
}

// Exported to vehicles action script
export const reassign_vehicles_group_success = (updatedVGs) => {
  return {
    type: actionType.REASSIGN_VEHICLE_GROUPS,
    updatedVGs: updatedVGs
  }
}

export const assign_vehicle_group = (uid, selectedVGID, vidList) => (dispatch) => {
  dispatch(setLoadingSubmit());

  API.assignVehicleGroups(uid, selectedVGID, vidList)
    .then(data => {
      // console.log("Assign Vehicle Group Data", data);

      if (data.errorMessage || data.message || (data.err && (data.err.message || data.err))) {
        message.error(data.errorMessage || data.message || (data.err && (data.err.message || data.err)));

        dispatch(unsetLoadingSubmit());
      }
      else {
        message.success("Successfully Assigned Vehicle Group");

        dispatch(assign_vehicle_group_success(data.vehicleGroup));

        dispatch(goBackToPrev());

        dispatch(unsetLoadingSubmit());
      }
    })
}

const assign_vehicle_group_success = (updatedVG) => {
  return {
    type: actionType.ASSIGN_VEHICLE_GROUP,
    updatedVG: updatedVG
  }
}

export const update_vehicles_in_vehicle_group = (vid, srcUID, srcVGID, destUID, destVGID) => (dispatch) => {
  API.updateVehiclesInVehicleGroup(vid, srcUID, srcVGID, destUID, destVGID)
    .then(data => {
      // console.log("Update Vehicles In Vehicle Group Data:", data);

      data.vehicleGroup && dispatch(update_vehicles_in_vehicle_group_success(vid, srcUID, srcVGID, data.vehicleGroup));
    })
}

const update_vehicles_in_vehicle_group_success = (updatedVG) => {
  return {
    type: actionType.UPDATE_VEHICLES_IN_VEHICLE_GROUP,
    updatedVG: updatedVG
  }
}

// Used by add vehicle action
export const updateVehicleGroupAfterAdd = (vid, vgidList) => {
  return {
    type: actionType.UPDATE_VEHICLE_GROUP_AFTER_ADD,
    vid: vid,
    vgidList: vgidList
  }
}

// Used by edit vehicle action
export const updateVehicleGroupAfterEdit = (vid, vgidList) => {
  return {
    type: actionType.UPDATE_VEHICLE_GROUP_AFTER_EDIT,
    vid: vid,
    vgidList: vgidList
  }
}

export const clear_temp_new_vgid = () => {
  return {
    type: actionType.CLEAR_TEMP_NEW_VGID,
  }
}
