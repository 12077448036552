import * as actionType from '../action-types/geofences'

const geofenceState = { byId: {}, allIds: [], selectedId: null };

export const geofences = (state = geofenceState, action) => {
    switch (action.type) {
        case actionType.GET_GEOFENCES: {
            let newState = JSON.parse(JSON.stringify(state));
            if (action.geofences && action.geofences.length > 0) {
                action.geofences.forEach(geofence => {
                    if (geofence && geofence.geoid) {
                        newState.byId[geofence.geoid] = geofence;

                        if (newState.allIds.includes(geofence.geoid) === false) {
                            newState.allIds.push(geofence.geoid)
                        }
                    }
                })
            }
            return newState;
        }

        case actionType.CREATE_GEOFENCE: {
            let newState = JSON.parse(JSON.stringify(state));

            newState.allIds.push(action.geoid);
            newState.byId[action.geoid] = {
                geoid: action.geoid,
                ...action.geofence
            };

            return newState;
        }

        case actionType.ARCHIVE_GEOFENCE: {
            let newState = JSON.parse(JSON.stringify(state));

            let geoid = action.geoid;
            delete newState.byId[geoid];
            newState.allIds.splice(newState.allIds.indexOf(geoid), 1);

            return newState;
        }

        case actionType.EDIT_GEOFENCE: {
            let newState = JSON.parse(JSON.stringify(state));

            newState.byId[action.geofence.geoid] = action.geofence;

            // console.log("edited POI",action.geofence);

            return newState;
        }

        case actionType.SET_SELECTED_GEOFENCE_GEOID: {
            // console.log("select Id", action.dvid)
            return { ...state, selectedId: action.geoid }
        }

        case actionType.CLEAR_SELECTED_GEOFENCE_GEOID: {
            let newState = JSON.parse(JSON.stringify(state));
            return { ...newState, selectedId: null }
        }


        default: {
            return state;
        }
    }

}