import * as API from "../../api";
import * as actionTypes from "../action-types/vehicles";

import { message } from "antd";
import { update_selected_user_hierarchy } from './hierarchy';
import { goBackToPrev } from "../../../navigation/navigationService";
import {
  setLoadingSubmit,
  unsetLoadingSubmit,
} from './style';
import { 
  updateVehicleGroupAfterAdd, 
  updateVehicleGroupAfterEdit,
} from './vehicleGroups';

export const get_vehicles_data = (vehicles) => {
  // console.log("Get Vehicles Data:", vehicles);

  return {
    type: "GET_VEHICLES",
    vehicles: vehicles
  }
}

export const add_new_vehicle = (newVehicle, targetUserDetails, vgidList, gtidList, rtidList) => (dispatch) => {
  dispatch(setLoadingSubmit());

  API.addVehicle(newVehicle, targetUserDetails, vgidList, gtidList, rtidList)
    .then(data => {
      // console.log("Add Vehicle Data:", data);

      if (data.status !== 200) {
        message.error(data.message || 'Error in adding vehicle')

        dispatch(unsetLoadingSubmit());
      }
      else {
        message.success("Successfully Add New Vehicle");

        dispatch(add_new_vehicle_success(data.vehicle));
        dispatch(updateVehicleGroupAfterAdd(data.vehicle.vid, vgidList));
        dispatch(update_selected_user_hierarchy(targetUserDetails.uid, data.vehicle, vgidList, gtidList, rtidList));

        dispatch(unsetLoadingSubmit());

        dispatch(goBackToPrev());
      }
    })
}

export const add_new_vehicle_success = (vehicle) => {
  return {
    type: "ADD_NEW_VEHICLE_SUCCESS",
    vehicle: vehicle
  }
}

export const edit_vehicle = (editedVehicle, targetUID, vgidList, gtidList, rtidList) => (dispatch, getState) => {
  dispatch(setLoadingSubmit());

  const currUserDetails = {
    uid: getState().v2.user.uid,
    userRole: getState().v2.user.userRole,
  }

  // console.log("Current User Details:", currUserDetails);

  API.editVehicle(editedVehicle, currUserDetails, vgidList, gtidList, rtidList)
    .then(data => {
      // console.log("Edit Vehicle Data:", data);

      if (data.hasOwnProperty('errorMessage')) {
        message.error(data.errorMessage);

        dispatch(unsetLoadingSubmit());
      }
      else {
        message.success("Successfully Edited the Vehicle");

        if (getState().user.uid === targetUID) {
          dispatch(edit_vehicle_success(data.vehicle));
          dispatch(updateVehicleGroupAfterEdit(data.vehicle.vid, vgidList));
          dispatch(update_selected_user_hierarchy(targetUID, data.vehicle, vgidList, gtidList, rtidList));

          dispatch(unsetLoadingSubmit());

          dispatch(goBackToPrev());
        }
        else {
          message.info("Reloading page");

          window.location.reload();
        }
      }
    })
}

const edit_vehicle_success = (editedVehicle) => {
  return {
    type: "EDIT_VEHICLE_SUCCESS",
    editedVehicle: editedVehicle
  }
}

export const set_selected_vehicle = (vid) => {
  return {
    type: "SET_SELECTED_VEHICLE",
    vid
  }
}

export const clear_selected_vehicle = () => {
  return {
    type: actionTypes.CLEAR_SELECTED_VEHICLE
  }
}

export const update_vehicle = (vehicle) => (dispatch) => {
  dispatch(update_vehicle_success(vehicle));

  // Testing only
  // dispatch(temp_update_vehicle_address(vehicle.vid, 0, 0));

  // console.log("Test:", vehicle.location && vehicle.location.lat && vehicle.location.lon);

  // Call another API to update vehicle address
  if (vehicle.location && vehicle.location.lat && vehicle.location.lon) {
    // console.log("Updating vehicle location");

    dispatch(temp_update_vehicle_address(vehicle.vid, vehicle.location.lat, vehicle.location.lon));
  }
  // else {
  //   console.log("No update to vehicle location");
  // }

  // return {
  //   type: "UPDATE_VEHICLE",
  //   vehicle,
  // }
}

const update_vehicle_success = (vehicle) => {
  return {
    type: "UPDATE_VEHICLE",
    vehicle,
  }
}

const temp_update_vehicle_address = (vid, lat, lng) => (dispatch) => {
  fetch(`https://kq031m0skl.execute-api.ap-southeast-1.amazonaws.com/V1Dev/reverse?lat=${lat}&lon=${lng}&format=geojson`, {
    method: 'GET',
  })
  // .then((res) => {
  //   console.log("Res:", res);
  // })
  .then((res) => res.json())
  .then((data) => {
    // console.log("temp_update_vehicle_address data:", data);

    if (data.features && data.features[0] && data.features[0].properties && data.features[0].properties.display_name) {
      dispatch(temp_update_vehicle_address_success(vid, data.features[0].properties.display_name));
    }
  })
}

// Exported to VehicleMarkers component for a button
export const temp_update_vehicle_address_2 = (vid, lat, lng) => (dispatch) => {
  fetch(`https://kq031m0skl.execute-api.ap-southeast-1.amazonaws.com/V1Dev/reverse?lat=${lat}&lon=${lng}&format=geojson`, {
    method: 'GET',
  })
  .then((res) => res.json())
  .then((data) => {
    // console.log("temp_update_vehicle_address_2 data:", data);

    if (data.error) {
      message.error(data.error);
    }
    else if (data.features && data.features[0] && data.features[0].properties && data.features[0].properties.display_name) {
      dispatch(temp_update_vehicle_address_success(vid, data.features[0].properties.display_name));
    }
  })
}

const temp_update_vehicle_address_success = (vid, newAddressText) => {
  return {
    type: actionTypes.TEMP_UPDATE_VEHICLE_ADDRESS,
    vid,
    newAddressText,
  }
}