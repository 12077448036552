import React from "react";

import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import GoogleMapsComponent from "../../components/Map/GoogleMapsComponent";
import GoogleMapsComponentEvent from "../../components/Map/GoogleMapsComponentEvent";
import GoogleMapsComponentAddPOI from "../../components/Map/GoogleMapsComponentAddPOI";
import GoogleMapsComponentEditPOI from "../../components/Map/GoogleMapsComponentEditPOI";
import GoogleMapsComponentGeofence from "../../components/Map/GoogleMapsComponentGeofence";
import GoogleMapsComponentAddPolygon from "../../components/Map/GoogleMapsComponentAddPolygon";
import GoogleMapsComponentEditPolygon from "../../components/Map/GoogleMapsComponentEditPolygon";
// import GoogleMapsComponentGeofenceWithPolyline from "../../components/Map/GoogleMapsComponentGeofenceWithPolyline";
import GoogleMapsComponentTripRecord from '../../components/Map/GoogleMapsComponentTripRecord'

const MainPanel = (props) => {
  const renderMap = pathname => {
    switch (pathname) {

      case '/TripRecord':
        return <GoogleMapsComponentTripRecord />

      // case '/FleetRecord_Test':
      //   return <GoogleMapsComponentWithPolyline />

      case '/Geofence':
        return <GoogleMapsComponentGeofence />

      case '/Geofence/AddNewPOI':
        return <GoogleMapsComponentAddPOI />

      case '/Geofence/EditPOI':
        return <GoogleMapsComponentEditPOI />

      case '/Geofence/AddNewPolygon':
        return <GoogleMapsComponentAddPolygon />

      case '/Geofence/EditPolygon':
        return <GoogleMapsComponentEditPolygon />

      case "/EventRecord":
        return <GoogleMapsComponentEvent />

      default:
        return <GoogleMapsComponent />
    }
  }

  return (
    <div>{renderMap(props.location.pathname)}</div>
  );
}
export default showSecondarySidebar(true)(MainPanel);
