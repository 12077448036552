import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
// import momentTz from 'moment-timezone'
import Page from "../../components/Page";
import RefuelReceipt from "./RefuelReceipt";
import FuelAnalysisTimelineChart from "./TimelineChart";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../components/Button";
import { FUEL_EVENT_TYPE } from "../../../../constants";
import { EmptyIndicator } from "../../components/EmptyIndicator";
// import { moveToPage } from "../../../navigation/navigationService";
import {
    // Icon,
    Form,
    Table,
    Input,
    message,
    DatePicker,
    // AutoComplete,
    Select,
} from "antd";

// Redux Actions
import {
    // set_fuel_record_selected_frid,
    clear_fuel_record_selected_frid,
    get_fuel_record_by_timeframe_request,
} from "../../services/redux/actions/fuelRecord";

class FuelAnalysisPage extends React.Component {
    state = {
        selectedVID: "",
        filterString: "",
        userFuelUnitPrice: 2.08,

        startTime: moment().startOf("day"),
        endTime: moment().add(1, "day").startOf("day"),

        refuelDataSource: [],
        movingDataSource: [],
        idlingDataSource: [],

        isUpdatingReceipt: false,
    }

    submitForm = () => {
        let {
            endTime,
            startTime,
            selectedVID,
        } = this.state;


        if (selectedVID && startTime && endTime) {

            const offSet = new Date().getTimezoneOffset() * 60 * 1000

            startTime = startTime.valueOf() - offSet
            endTime = endTime.valueOf() - offSet

            // console.log(`get_fuel_record_by_timeframe_request`, {
            //     selectedVID, startTime, endTime, startTimeformatted: moment(startTime).format('LLLL'), offSet
            // })

            this.props.dispatch(get_fuel_record_by_timeframe_request(selectedVID, startTime, endTime));
        }
        else {
            message.error(`Please fill up all the fields!`);
        }
    }

    setUpDataSources = () => {
        const {
            vehicles,
            fuelRecord
        } = this.props;

        const {
            selectedVID,
            userFuelUnitPrice,
        } = this.state;

        const {
            movingFuelConsumption,
            idlingFuelConsumption
        } = fuelRecord;

        let newMovingDataSource = [];
        let newIdlingDataSource = [];
        let newRefuelDataSource = [];

        const selectedVehicleFuelProfile = vehicles.byId[selectedVID].fuelProfile.fuel;

        const movingFuelUsageVolume =
            selectedVehicleFuelProfile.fuelCapacity && movingFuelConsumption.deltaFuel ?
                Math.abs(Number(selectedVehicleFuelProfile.fuelCapacity) * (Number(movingFuelConsumption.deltaFuel) / 100)).toFixed(2) :
                0;
        const idlingFuelUsageVolume =
            selectedVehicleFuelProfile.fuelCapacity && idlingFuelConsumption.deltaFuel ?
                Math.abs(Number(selectedVehicleFuelProfile.fuelCapacity) * (Number(idlingFuelConsumption.deltaFuel) / 100)).toFixed(2) :
                0;

        const totalMovingFuelCost =
            userFuelUnitPrice && movingFuelUsageVolume ?
                Math.abs(Number(userFuelUnitPrice) * Number(movingFuelUsageVolume)) :
                "-";

        const totalIdlingFuelCost =
            userFuelUnitPrice && idlingFuelConsumption ?
                Math.abs(Number(userFuelUnitPrice) * Number(idlingFuelUsageVolume)) :
                "-";

        const idlingHour = Math.floor(Number(idlingFuelConsumption.duration) / 1000 / 60 / 60);
        const idlingMin = Math.floor(Number(idlingFuelConsumption.duration) / 1000 / 60 % 60);
        const idlingSec = Math.floor(Number(idlingFuelConsumption.duration) / 1000 % 60);

        newMovingDataSource.push({
            key: selectedVID,
            fuelUsage_L: movingFuelUsageVolume,
            // fuelUsage_P: movingFuelConsumption.deltaFuel.toFixed(2),
            mileage: movingFuelConsumption.deltaMileage && movingFuelConsumption.deltaMileage.toFixed(2),
            totalFuelCost: totalMovingFuelCost !== "-" ? totalMovingFuelCost.toFixed(2) : "-",
            litrePerKM: movingFuelUsageVolume && movingFuelConsumption.deltaMileage ?
                Math.abs(movingFuelUsageVolume / Number(movingFuelConsumption.deltaMileage)).toFixed(2) : "-",
            kmPerLitre: movingFuelConsumption.deltaMileage && movingFuelUsageVolume ?
                Math.abs(Number(movingFuelConsumption.deltaMileage) / movingFuelUsageVolume).toFixed(2) : "-",
            rmPerKM: totalMovingFuelCost !== "-" && movingFuelConsumption.deltaMileage ?
                Math.abs(totalMovingFuelCost / Number(movingFuelConsumption.deltaMileage)).toFixed(2) : "-",
        });

        newIdlingDataSource.push({
            key: selectedVID,
            duration: `${idlingHour}h ${idlingMin}m ${idlingSec}s`,
            fuelUsage_L: idlingFuelUsageVolume,
            // fuelUsage_P: idlingFuelConsumption.deltaFuel.toFixed(2),
            totalFuelCost: totalIdlingFuelCost !== "-" ? totalIdlingFuelCost.toFixed(2) : "-",
            litrePerHour: idlingFuelUsageVolume && idlingFuelConsumption.duration ?
                Math.abs(idlingFuelUsageVolume / idlingFuelConsumption.duration * 3600000).toFixed(2) : "-",
            rmPerHour: totalIdlingFuelCost !== "-" && idlingFuelConsumption.duration ?
                Math.abs(totalIdlingFuelCost / idlingFuelConsumption.duration * 3600000).toFixed(2) : "-",
        });

        if (selectedVID && fuelRecord.byVID[selectedVID]) {
            let totalDeviceFuelUse = 0;
            let totalDeviceTotalCost = 0;
            let totalBillFuelUse = 0;
            let totalBillTotalCost = 0;
            let totalTotalCostDiff = 0;
            // let totalAccuracy = 0;

            fuelRecord.byVID[selectedVID]
                .map((currFRID) => fuelRecord.byFRID[currFRID])
                .filter((currRecord) => currRecord.recordStatus === FUEL_EVENT_TYPE.REFUEL)
                // .filter((currRecord) => currRecord.receiptRefuelLevel) // Testing only!
                .forEach((currRecord) => {
                    // console.log("Current Record:", currRecord);

                    const deviceFuelUse =
                        selectedVID && currRecord.deltaFuel && selectedVehicleFuelProfile && selectedVehicleFuelProfile.fuelCapacity ?
                            (Number(selectedVehicleFuelProfile.fuelCapacity) * Number(currRecord.deltaFuel) / 100).toFixed(2) :
                            "-";

                    const deviceTotalCost =
                        deviceFuelUse && deviceFuelUse !== "-" ?
                            (Number(deviceFuelUse) * Number(userFuelUnitPrice)).toFixed(2) :
                            "-";

                    const receiptTotalCost = currRecord.receiptRefuelLevel ?
                        (Number(currRecord.receiptRefuelLevel) * Number(currRecord.fuelUnitPrice)).toFixed(2) : 0;

                    const costDiff = deviceTotalCost && receiptTotalCost ? (Number(deviceTotalCost) - Number(receiptTotalCost)).toFixed(2) : "-";

                    newRefuelDataSource.push({
                        key: currRecord.frid,
                        index: newRefuelDataSource.length + 1,
                        date: moment(currRecord.fuelStartAt).format("DD/MM/YYYY h:mm:ss A"),
                        address: { address: currRecord.address, location: currRecord.startLocation },

                        deviceFuelUse: deviceFuelUse,
                        deviceTotalCost: deviceTotalCost,

                        billFuelUse: currRecord.receiptRefuelLevel ? currRecord.receiptRefuelLevel.toFixed(2) : "-",
                        billTotalCost: receiptTotalCost ? receiptTotalCost : "-",

                        totalCostDiff: costDiff,

                        accuracy: deviceTotalCost && receiptTotalCost ? (Number(receiptTotalCost) / Number(deviceTotalCost) * 100).toFixed(2) : "-",
                        remarks: currRecord.receiptRemarks && currRecord.receiptRemarks.replace(" ", "") ? currRecord.receiptRemarks : "-",
                    });

                    if (deviceFuelUse && deviceFuelUse !== "-") {
                        totalDeviceFuelUse += Number(deviceFuelUse);
                    }

                    if (deviceTotalCost && deviceTotalCost !== "-") {
                        totalDeviceTotalCost += Number(deviceTotalCost);
                    }

                    if (currRecord.receiptRefuelLevel) {
                        totalBillFuelUse += Number(currRecord.receiptRefuelLevel);
                    }

                    if (receiptTotalCost) {
                        totalBillTotalCost += Number(receiptTotalCost);
                    }

                    if (costDiff && costDiff !== "-") {
                        totalTotalCostDiff += Number(costDiff);
                    }
                })

            newRefuelDataSource.push({
                key: "TOTAL",
                index: "TOTAL",
                // date: moment(currRecord.fuelStartAt).format("DD/MM/YYYY h:mm:ss A"),
                // address: currRecord.address && currRecord.address.replace(" ", "") ? currRecord.address : "-",

                deviceFuelUse: totalDeviceFuelUse ? Number(totalDeviceFuelUse).toFixed(2) : "-",
                deviceTotalCost: totalDeviceTotalCost ? Number(totalDeviceTotalCost).toFixed(2) : "-",

                billFuelUse: totalBillFuelUse ? Number(totalBillFuelUse).toFixed(2) : "-",
                billTotalCost: totalBillTotalCost ? Number(totalBillTotalCost).toFixed(2) : "-",

                totalCostDiff: totalTotalCostDiff ? Number(totalTotalCostDiff).toFixed(2) : "-",

                accuracy:
                    totalDeviceTotalCost && totalBillTotalCost ?
                        (Number(totalBillTotalCost) / Number(totalDeviceTotalCost) * 100).toFixed(2) :
                        "-",
                // remarks: currRecord.receiptRemarks && currRecord.receiptRemarks.replace(" ", "") ? currRecord.receiptRemarks : "-",
            })
        }

        // console.log("New Data Source:", newRefuelDataSource);

        this.setState({
            refuelDataSource: newRefuelDataSource,
            movingDataSource: newMovingDataSource,
            idlingDataSource: newIdlingDataSource,
        });

        // if (newRefuelDataSource.length > 0) {
        // }
        // else {
        //     message.info(`No fuel records found within this time period.`);
        // }
    }

    onCancelReceipt = () => {
        this.setState({
            ...this.state,
            isUpdatingReceipt: false,
        })
    }

    onSubmitReceipt = () => {
        this.setState({
            ...this.state,
            isUpdatingReceipt: false,
        })
    }

    componentDidMount = () => {
        const { selectedVID } = this.state;
        // const { fuelRecord } = this.props;

        if (selectedVID) {
            this.setUpDataSources();
        }
    }

    componentDidUpdate = (prevProps) => {
        const { selectedVID } = this.state;
        const {
            vehicles,
            fuelRecord
        } = this.props;

        if ((vehicles !== prevProps.vehicles || fuelRecord !== prevProps.fuelRecord)
            && fuelRecord
            && selectedVID
            && vehicles.byId[selectedVID]) {
            this.setUpDataSources();
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clear_fuel_record_selected_frid());
    }

    render() {
        const {
            endTime,
            startTime,
            selectedVID,
            refuelDataSource,
            movingDataSource,
            idlingDataSource,
        } = this.state

        const {
            vehicles,
            fuelRecord,
        } = this.props;

        const movingColumns = [
            {
                key: 'mileage',
                title: "Mileage (KM)",
                dataIndex: "mileage",
            },
            {
                key: 'fuelUsage_L',
                title: "Fuel Usage (L)",
                dataIndex: "fuelUsage_L",
            },
            // {
            //     key: 'fuelUsage_P',
            //     title: "Fuel Usage (%)",
            //     dataIndex: "fuelUsage_P",
            // },
            {
                key: 'totalFuelCost',
                title: "Total Fuel Cost (RM)",
                dataIndex: "totalFuelCost",
            },
            {
                key: 'litrePerKM',
                title: "Litre/KM",
                dataIndex: "litrePerKM",
            },
            {
                key: 'kmPerLitre',
                title: "KM/Litre",
                dataIndex: "kmPerLitre",
            },
            {
                key: 'rmPerKM',
                title: "RM/KM",
                dataIndex: "rmPerKM",
            },
        ];

        const idlingColumns = [
            {
                key: 'duration',
                title: "Duration",
                dataIndex: "duration",
            },
            {
                key: 'fuelUsage_L',
                title: "Fuel Usage (L)",
                dataIndex: "fuelUsage_L",
            },
            // {
            //     key: 'fuelUsage_P',
            //     title: "Fuel Usage (%)",
            //     dataIndex: "fuelUsage_P",
            // },
            {
                key: 'totalFuelCost',
                title: "Total Fuel Cost (RM)",
                dataIndex: "totalFuelCost",
            },
            {
                key: 'litrePerHour',
                title: "Litre/Hr",
                dataIndex: "litrePerHour",
            },
            {
                key: 'rmPerHour',
                title: "RM/Hr",
                dataIndex: "rmPerHour",
            },
        ];

        const refuelColumns = [
            {
                key: 'index',
                title: "No.",
                dataIndex: "index",
            },
            {
                key: 'date',
                title: "Refuel Time",
                dataIndex: "date",
            },
            {
                key: 'address',
                title: "Address",
                dataIndex: "address",
                render: (value) => {
                    if (value) {
                        const { address, location } = value
                        return (
                            location && location.lat && location.lon ?
                                <a
                                    href={`https://www.google.com.my/maps/place/${parseFloat(location.lat)},${parseFloat(location.lon)}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {address || `${location.lat}, ${location.lon}` || '-'}
                                </a>
                                :
                                '-'
                        )

                    }
                }
            },
            {
                key: 'deviceFuelUse',
                title: "Estimated Refuel (L)",
                dataIndex: "deviceFuelUse",
            },
            {
                key: 'deviceTotalCost',
                title: "Estimated Fuel Cost (RM)",
                dataIndex: "deviceTotalCost",
            },
            {
                key: 'billFuelUse',
                title: "Bill Refuel (L)",
                dataIndex: "billFuelUse",
            },
            {
                key: 'billTotalCost',
                title: "Bill Fuel Cost (RM)",
                dataIndex: "billTotalCost",
            },
            {
                key: 'totalCostDiff',
                title: "Cost Difference (RM)",
                dataIndex: "totalCostDiff",
            },
            {
                key: 'accuracy',
                title: "Accuracy (%)",
                dataIndex: "accuracy",
            },
            {
                key: 'remarks',
                title: "Remarks",
                dataIndex: "remarks",
            },
            // {
            //     key: "actions",
            //     title: "Actions",
            //     render: (columnData) => {
            //         if (columnData.key !== "TOTAL") {
            //             return(
            //                 <div 
            //                     style = {{
            //                         display: "flex",
            //                         justifyContent: "center"
            //                     }}
            //                 >
            //                     {
            //                         <Icon
            //                             type = "account-book"
            //                             onClick = {() => {
            //                                 this.props.dispatch(set_fuel_record_selected_frid(columnData.key));

            //                                 // this.props.dispatch(moveToPage(`/FuelAnalysis/RefuelReceipt/`));

            //                                 this.setState({
            //                                     ...this.state,
            //                                     isUpdatingReceipt: true,
            //                                 })
            //                             }}
            //                         />
            //                     }
            //                 </div>
            //             );
            //         }
            //     }
            // },
        ];

        // console.log(this.state);

        return (
            <div>
                {
                    !this.state.isUpdatingReceipt ?
                        <Page title="Fuel Analysis">
                            <div
                                style={{
                                    display: "block",
                                    minHeight: "95px",
                                    padding: "10px 10px 10px 10px",
                                }}
                            >
                                <Form layout="inline">
                                    <Form.Item>
                                        <span style={{ marginLeft: '10px' }}>Vehicles: </span>

                                        <Select
                                            showSearch
                                            style={{ width: 200 }}
                                            placeholder="Select a vehicle"
                                            value={selectedVID ? selectedVID : undefined}
                                            optionFilterProp="children"
                                            onChange={selectedVID => {
                                                this.setState({ selectedVID })
                                            }}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                Object.values(this.props.vehicles.byId)
                                                    .map(vehicle => {
                                                        return <Select.Option
                                                            key={vehicle.vid}
                                                            value={vehicle.vid}
                                                        >
                                                            {vehicle.vehicleDisplayName}
                                                        </Select.Option>
                                                    })
                                            }
                                        </Select>

                                        {/* {
                                            this.props.vehicles.allIds.length > 0 ?
                                                <AutoComplete
                                                    // disabled = {this.props.style.isLoadingSubmit}
                                                    placeholder = 'Vehicles'
                                                    style = {{ width: '177px' }}
                                                    value = {selectedVID ? vehicles.byId[selectedVID].vehicleDisplayName : undefined}
                                                    onSearch = {filterString => this.setState({ filterString })}
                                                    onSelect = {selectedVID => {
                                                        this.setState({
                                                            ...this.state,
                                                            selectedVID: selectedVID
                                                        })
                                                    }}
                                                    dataSource = {
                                                        vehicles.allIds
                                                            .filter(currVID => vehicles.byId[currVID].vehicleDisplayName.toLowerCase().includes(this.state.filterString.toLowerCase()))
                                                            .map((currVID, index) => {
                                                                // console.log("Index:", index);
                                                                // console.log("Current VID:", currVID);

                                                                return (
                                                                    <AutoComplete.Option key = {index} value = {currVID}>
                                                                        {vehicles.byId[currVID].vehicleDisplayName}
                                                                    </AutoComplete.Option>
                                                                );
                                                            })
                                                    }
                                                /> :
                                                <AutoComplete
                                                    disabled = {true}
                                                    placeholder = 'No unallocated vehicles'
                                                    style = {{ width: '177px' }}
                                                />
                                        } */}
                                    </Form.Item>

                                    <Form.Item>
                                        <span style={{ marginLeft: '10px' }}>Start Date: </span>

                                        <DatePicker
                                            defaultValue={startTime}
                                            // disabled = {this.props.style.isLoadingSubmit}
                                            style={{ width: '177px' }}
                                            onChange={value => this.setState({
                                                ...this.state,
                                                startTime: value
                                            })}
                                            showTime
                                        />

                                        <span style={{ marginLeft: '10px' }}>End Date: </span>

                                        <DatePicker
                                            defaultValue={endTime}
                                            // disabled = {this.props.style.isLoadingSubmit}
                                            style={{ width: '177px' }}
                                            onChange={value => this.setState({
                                                ...this.state,
                                                endTime: value
                                            })}
                                            showTime
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <span style={{ marginLeft: '10px' }}>Fuel price (RM): </span>

                                        <Input
                                            min={0}
                                            type={"number"}
                                            value={this.state.userFuelUnitPrice}
                                            // disabled = {this.props.style.isLoadingSubmit}
                                            style={{ width: 100 }}
                                            onChange={event =>
                                                this.setState({
                                                    ...this.state,
                                                    userFuelUnitPrice: event.target.value,
                                                },
                                                    () => {
                                                        // console.log(this.state.userFuelUnitPrice);

                                                        this.setUpDataSources();
                                                    }
                                                )
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <PrimaryButton
                                            style={{ marginLeft: "22px" }}
                                            onClick={this.submitForm}
                                            loading={this.props.style.isLoadingSubmit}
                                            disabled={!this.state.selectedVID}
                                        >
                                            Submit
                                        </PrimaryButton>

                                        {/* {
                                            this.state.isExportable &&
                                                <ExportExcelButton
                                                    filename = {`${vehicles.byId[selectedVID].vehicleDisplayName} - Vehicle Activity Records - ${moment(startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}`}
                                                    sheetData = {refuelDataSource}
                                                    sheetName = {vehicles.byId[selectedVID].vehicleDisplayName}
                                                    sheetRow = {[
                                                        { label: 'Date', formatter: value => value.date },
                                                        { label: 'Current Fuel %', formatter: value => value.currFuel },
                                                        { label: 'Velocity (km/h)', formatter: value => value.velocity },
                                                    ]}
                                                />
                                        } */}
                                    </Form.Item>
                                </Form>
                            </div>

                            {
                                selectedVID
                                && vehicles.byId[selectedVID]
                                && vehicles.byId[selectedVID].dvid
                                && fuelRecord.fuelRecordDeviceLog.byDVID[vehicles.byId[selectedVID].dvid] &&
                                <div style={{ paddingBottom: 100 }}>
                                    <FuelAnalysisTimelineChart vid={selectedVID} dvid={vehicles.byId[selectedVID].dvid} />
                                </div>
                            }

                            <div>
                                <div>
                                    <b>Moving Fuel Consumption</b>

                                    {
                                        movingDataSource.length > 0 ?
                                            <Table
                                                columns={movingColumns}
                                                dataSource={movingDataSource}
                                                bordered
                                                scroll={{ y: 400 }}
                                            /> :
                                            <EmptyIndicator />
                                    }
                                </div>

                                <div>
                                    <b>Idling Fuel Consumption</b>

                                    {
                                        idlingDataSource.length > 0 ?
                                            <Table
                                                columns={idlingColumns}
                                                dataSource={idlingDataSource}
                                                bordered
                                                scroll={{ y: 400 }}
                                            /> :
                                            <EmptyIndicator />
                                    }
                                </div>


                                <div>
                                    <b>Refuel Analysis Table</b>

                                    {
                                        refuelDataSource.length > 0 ?
                                            <Table
                                                columns={refuelColumns}
                                                dataSource={refuelDataSource}
                                                bordered
                                                scroll={{ y: 400 }}
                                            // footer = {() => {
                                            //     let totalEstimatedCost = 0;

                                            //     refuelDataSource.forEach((currData) => {
                                            //         if (!isNaN(Number(currData.deviceTotalCost))) {
                                            //             totalEstimatedCost += Number(currData.deviceTotalCost);
                                            //         }
                                            //     })

                                            //     return "Total Estimated Cost: RM" + totalEstimatedCost.toFixed(2);
                                            // }}
                                            /> :
                                            <EmptyIndicator />
                                    }
                                </div>
                            </div>
                        </Page> :
                        <RefuelReceipt
                            onCancelReceipt={() => this.onCancelReceipt()}
                            onSubmitReceipt={() => this.onSubmitReceipt()}
                        />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    vehicles: state.v2.vehicles,
    fuelRecord: state.v2.fuelRecord,
});

const ConnectedFuelAnalysisPage = connect(mapStateToProps)(FuelAnalysisPage);
export default showSecondarySidebar(false)(ConnectedFuelAnalysisPage);