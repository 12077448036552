import ontreklogo from "../img/ontreklogo.png";
import ontreklogoreversed from "../img/ontreklogoreversed.png";
import abstract_blue from "../img/abstract_blue.jpg";

import SvgGpsFixed from "../img/components/GpsFixed.js";
import SvgGpsOff from "../img/components/GpsOff.js";
import SvgHourglassEmpty from "../img/components/HourglassEmpty.js";
import SvgHourglassFull from "../img/components/HourglassFull.js";
import SvgIosSpeedometer from "../img/components/IosSpeedometer.js";
import SvgLocalParking from "../img/components/LocalParking.js";
import SvgSignalCellular4Bar from "../img/components/SignalCellular4Bar.js";
import SvgSignalCellularOff from "../img/components/SignalCellularOff.js";
import Info from "../img/components/Info.js";

let img = {}

const eventSVG = {
    gpsFull: SvgGpsFixed,
    gpsNo: SvgGpsOff,
    idleOver: SvgHourglassFull,
    idleNormal: SvgHourglassEmpty,
    parking: SvgLocalParking,
    cellFull: SvgSignalCellular4Bar,
    cellNo: SvgSignalCellularOff,
    speed: SvgIosSpeedometer,
    info: Info,
}

img.logo = ontreklogoreversed;
img.background = abstract_blue;
img.sideLogo = ontreklogo;

export const LOGO = img.logo;
export const LOGINBACKGROUND = img.background;

export const SIDEBARLOGO = img.sideLogo;

export const EVENTSVG = eventSVG;