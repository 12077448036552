import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import ColorPicker from '../../../components/ColorPicker';
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import { 
    GEOFENCE_COLORS,
    DEFAULT_GEOFENCE_HEXCODE,
} from "../../../../../constants";
import {
    Form,
    Input,
    Button,
    Select,
    message,
} from "antd";

// Redux Actions
import { 
    add_geofence_template,
    clear_temp_new_gtid,
    assign_geofences_to_geofence_template
} from "../../../services/redux/actions/geofenceTemplate";

const FormItem = Form.Item;
const { Option } = Select;

class AddGeofenceTemplatePage extends Component {
    state = {
        geofenceTemplateName: "",
        colorHexCode: DEFAULT_GEOFENCE_HEXCODE,
        selectedGeoIDs: [],
    }

    submitForm = () => {
        this.setState({ isLoading: true })

        const { 
            colorHexCode, 
            geofenceTemplateName 
        } = this.state;

        // console.log('submit form:', this.props.user.uid, this.state.geofenceTemplateName);

        let isValid = true;

        if (!geofenceTemplateName) {
            message.error("Please Enter A Geofence Template Name");
            isValid = false;
        }

        if (!colorHexCode) {
            message.error("Please Enter A Geofence Color For The Geofence Template");
            isValid = false;
        }

        if (isValid) {
            // console.log(`
            //     Creating New Geofence:
            //     Target UID - ${this.props.user.uid}
            //     Geofence Template Name - ${geofenceTemplateName}
            //     Color Hex Code: ${colorHexCode}
            //     Selected GeoIDs: ${this.state.selectedGeoIDs}
            // `);

            this.props.dispatch(add_geofence_template(this.props.user.uid, colorHexCode, geofenceTemplateName));
        }
    }

    componentDidUpdate = (prevProps) => {
        const { selectedGeoIDs } = this.state;

        if (prevProps.geofenceTemplate !== this.props.geofenceTemplate 
            && this.props.geofenceTemplate.tempNewGTID 
            && prevProps.geofenceTemplate.tempNewGTID !== this.props.geofenceTemplate.tempNewGTID) 
        {
            // console.log("Selected GeoIDs:", selectedGeoIDs);

            if (selectedGeoIDs.length > 0) {
                this.props.dispatch(assign_geofences_to_geofence_template(
                    this.props.user.uid, 
                    this.props.geofenceTemplate.tempNewGTID, 
                    selectedGeoIDs
                ));
            }
            else {
                this.props.dispatch(goBackToPrev());
            }
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clear_temp_new_gtid()); // Clear geofence templates's tem when leaving page
    }

    render() {
        // console.log(this.props.style.isLoadingSubmit);

        return (
            <div className = "page-container">
                <Page title = "Add New Geofence Template">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Geofence Template Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Geofence Template Name"
                                    value = {this.state.geofenceTemplateName}
                                    onChange = {e => this.setState({ 
                                        ...this.state,
                                        geofenceTemplateName: e.target.value 
                                    })}
                                />
                            </FormItem>
                            
                            <FormItem
                                label = "Assign Geofence(s)"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {
                                    this.props.geofences.allIds.length > 0 ?
                                        <Select
                                            mode = "multiple"
                                            placeholder = "Select Geofences To Add (Optional)"
                                            value = {this.state.selectedGeoIDs.length > 0 ? this.state.selectedGeoIDs : undefined}
                                            style = {{ width: '100%' }}
                                            onChange = {
                                                value => {
                                                    // console.log("Test:", value);

                                                    this.setState({ 
                                                        ...this.state,
                                                        selectedGeoIDs: [...value]
                                                    })
                                                }
                                            }
                                        >
                                            {
                                                this.props.geofences.allIds.map((currGeoID) => 
                                                    <Option key = {currGeoID} value = {currGeoID}>
                                                        {this.props.geofences.byId[currGeoID].geofenceName}
                                                    </Option>
                                                )
                                            }
                                        </Select> :
                                        <Select
                                            disabled
                                            placeholder = "No Geofences Found"
                                        />
                                }
                            </FormItem>

                            <FormItem
                                label = "Geofence Color"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <div
                                    style = {{
                                        display: "flex",
                                        alignItems: "center",

                                        height: 40,
                                    }}
                                >
                                    <ColorPicker
                                        currHexCode = {this.state.colorHexCode}
                                        presetColors = {GEOFENCE_COLORS}
                                        onChange = {(color) => {
                                            this.setState({
                                                ...this.state,
                                                colorHexCode: color.hex.toUpperCase(),
                                            },
                                                () => {
                                                    // console.log("New Color:", this.state.colorHexCode);
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            </FormItem>

                            <div 
                                style = {{
                                    padding: 5,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: 10
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton 
                                    onClick = {this.submitForm}
                                    loading = {this.props.style.isLoadingSubmit && this.state.isLoading}
                                    disabled = {!this.state.geofenceTemplateName || !this.state.colorHexCode}
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.v2.user,
    style: state.v2.style,
    geofences: state.v2.geofences,
    geofenceTemplate: state.v2.geofenceTemplate,
});

const ConnectedAddRule = connect(mapStateToProps)(AddGeofenceTemplatePage);
export default showSecondarySidebar(false)(ConnectedAddRule)
