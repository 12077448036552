import * as API from "../../api";
import * as actionType from '../action-types/hierarchy';

import { message } from "antd";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    setLoadingSubmit,
    unsetLoadingSubmit,
} from './style';
import { ROLE_TYPE } from '../../../../../constants';

export const update_tree_data = (treeData) => {
    // console.log("treeData",treeData);

    return {
        type: actionType.UPDATE_TREE_DATA,
        treeData: treeData
    }
}

export const select_user = (user) => async (dispatch, getState) => {
    dispatch(setLoadingSubmit())

    // console.log('select_user', { user });

    // // check if user data is already queried previously. If no, call api. Else, simply re-use
    // if (user.vehicleGroups && typeof user.vehicleGroups[0] !== 'object') {
    const userRolesWithDeviceGroup = [ROLE_TYPE.SUPER, ROLE_TYPE.DISTRIBUTOR];

    // console.log("Test:", user.vehicleGroups.map((currObj) => currObj.id), user.uid);

    const rtids = (user.ruleTemplates && user.ruleTemplates.map(rt => rt.rtid) )|| []
    const dgids = (user.deviceGroups && user.deviceGroups.map(dg => dg.dgid)) || []
    const gtids = (user.geofenceTemplates && user.geofenceTemplates.map(gt => gt.gtid)) || []
    const vgids = (user.vehicleGroups && user.vehicleGroups.map(vg => vg.vgid)) || []

    const [
        ruleTemplatesResponse,
        geofenceTemplatesResponse,
        vehicleGroupsResponse,
    ] = await Promise.all([
        API.getRuleTemplate(rtids),
        API.getGeofenceByTemplate(gtids, user.uid),
        API.getVehicleByGroup(vgids, user.uid),
    ])

    const deviceGroupsResponse = userRolesWithDeviceGroup.includes(user.userRole) ? await API.getDeviceByGroup(dgids, user.uid) : await API.getDeviceByVGIDs(user.uid, vgids)

    const info = {
        deviceGroups: deviceGroupsResponse.deviceGroups,
        devices: deviceGroupsResponse.devices,
        vehicleGroups: vehicleGroupsResponse.vehicleGroups,
        vehicles: vehicleGroupsResponse.vehicles,
        ruleTemplates: ruleTemplatesResponse.ruleTemplate,
        rules: ruleTemplatesResponse.rules,
        geofenceTemplates: geofenceTemplatesResponse.geofenceTemplates,
        geofences: geofenceTemplatesResponse.geofences,
        notificationTemplates: []
    }

    // console.log({ info })

    dispatch(select_user_success(user, info));

    dispatch(unsetLoadingSubmit())

    // }
    // else {
    //     dispatch(select_user_success(user, user));
    // }

}

export const select_user_success = (user, info) => {
    // console.log({ user, info });

    return {
        type: actionType.SELECT_USER_HIERARCHY,
        info: info,
        user: user,
    }
}

export const select_user_vid = (newVID) => {
    return {
        type: actionType.SELECT_USER_HIERARCHY_VID,
        newVID: newVID,
    }
}

export const select_user_dvid = (newDVID) => {
    return {
        type: actionType.SELECT_USER_HIERARCHY_DVID,
        newDVID: newDVID,
    }
}

export const get_user_hierarchy = (uid) => dispatch => {
    // console.log(`get_user_hierarchy`, uid);

    API.getUserHierarchy(uid)
        .then(data => {
            // console.log("get_user_hierarchy data:", data);

            if (data.status !== 200) {
                message.error('Error at get_user_hierarchy', data.message)
            }
            else {
                dispatch(get_user_hierarchy_success(data))
            }
        })
}

export const get_user_hierarchy_success = (data) => {
    // console.log("User Hierarchy:", data);

    return {
        type: actionType.GET_USER_HIERARCHY,
        hierarchy: data.hierarchy.hierarchy,
        byId: data.hierarchy.byId,
    }
}

export const update_user_hierarchy_devices = (uid, newDevice) => {
    return {
        type: actionType.UPDATE_USER_HIERARCHY_DEVICES,
        uid: uid,
        newDevice: newDevice
    }
}

// Exported to vehicle action script
export const update_selected_user_hierarchy = (uid, newVehicle, vgidList, gtidList, rtidList) => {
    return {
        type: actionType.UPDATE_SELECTED_USER_HIERARCHY,
        uid: uid,
        vgidList: vgidList,
        gtidList: gtidList,
        rtidList: rtidList,
        newVehicle: newVehicle,
    }
}

export const add_user_hierarchy = (newUser, parentUID) => {
    return {
        type: actionType.ADD_USER_HIERARCHY,
        newUser: newUser,
        parentUID: parentUID,
    }
}

export const edit_user_hierarchy = (editedUser) => {
    return {
        type: actionType.EDIT_USER_HIERARCHY,
        editedUser: editedUser,
    }
}

export const add_groups_to_user = (srcUser, destUser, groupIDList) => dispatch => {
    dispatch(setLoadingSubmit());

    API.addGroupsToUser(srcUser, destUser, groupIDList)
        .then(data => {
            // console.log("Add Groups To User Data:", data);

            if (data.err) {
                message.error(data.err);
            }
            else {
                message.success("Successfully Added Groups To New User");

                dispatch(add_groups_to_user_success(data));
                dispatch(goBackToPrev());
            }

            dispatch(unsetLoadingSubmit());
        })
}

const add_groups_to_user_success = (data) => {
    // console.log("add_groups_to_user_success data:", data);

    return {
        type: actionType.ADD_GROUPS_TO_USER,
        data: data,
    }
}

export const update_groups_to_user = (srcUser, destUser, groupIDList) => dispatch => {
    dispatch(setLoadingSubmit());

    API.updateGroupsToUser(srcUser, destUser, groupIDList)
        .then(data => {
            // console.log("Update Groups To User Data:", data);

            if (data.status !== 200) {
                message.error(`Error at update_groups_to_user`);
            }
            else {
                message.success("Successfully Updated Groups For Edited User");

                dispatch(update_groups_to_user_success(data));
                dispatch(goBackToPrev());
            }

            dispatch(unsetLoadingSubmit());
        })
}

const update_groups_to_user_success = (data) => {
    // console.log("update_groups_to_user_success data:", data);

    return {
        type: actionType.UPDATE_GROUPS_TO_USER,
        data: data,
    }
}

// Will be called when leave User Management Add User page
export const clear_temp_new_uid = () => {
    return {
        type: actionType.CLEAR_TEMP_NEW_UID,
    }
}