import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../components/Page";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../components/Button";
import { goBackToPrev } from "../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message,
    // TreeSelect,
} from "antd";

// Redux Actions
import {
    add_vehicle_group,
    clear_temp_new_vgid,
    assign_vehicle_group,
} from "../../services/redux/actions/vehicleGroups";

const FormItem = Form.Item;
// const {
//     TreeNode,
//     SHOW_PARENT
// } = TreeSelect;

class AddVehicleGroup extends Component {
    state = {
        groupName: "",

        searchedVehicleNameString: "",
        selectedVIDKeys: [],
    }

    keySeperator = " - ";

    submitForm = () => {
        this.setState({ isLoading: true })
        const { groupName } = this.state;

        let isValid = true;

        if (!this.props.user.uid) {
            message.error("Current User ID Not Found");

            isValid = false;
        }

        if (!groupName) {
            message.error("Please Enter The Vehicle Group's Name");

            isValid = false;
        }

        if (isValid) {
            // console.log(
            //     `Adding New Vehicle Group:
            //     - UID: ${this.props.user.uid}
            //     - Group Name: ${groupName}`
            // );

            this.props.dispatch(add_vehicle_group(this.props.user.uid, groupName));
        }
    }

    componentDidUpdate = prevProps => {
        const { vehicleGroups } = this.props;

        if (prevProps.vehicleGroups !== vehicleGroups && vehicleGroups.tempNewVGID) {
            if (this.state.selectedVIDKeys.length > 0) {
                const selectedVIDs = this.state.selectedVIDKeys.map((currKey) => {
                    if (currKey.includes(this.keySeperator)) {
                        return currKey.split(this.keySeperator)[1];
                    }
                    else {
                        return vehicleGroups.byVGID[currKey].vehicles;
                    }
                })

                // console.log(`
                //     Assigning Vehicle Group:
                //     - UID: ${this.props.user.uid}
                //     - Selected VGID: ${vehicleGroups.tempNewVGID}
                //     - Selected VIDs: ${selectedVIDs}
                // `);

                this.props.dispatch(assign_vehicle_group(this.props.user.uid, vehicleGroups.tempNewVGID, selectedVIDs));
            }
            else {
                this.props.dispatch(goBackToPrev());
            }
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clear_temp_new_vgid());
    }

    render() {
        const {
            vehicles,
            // vehicleGroups,
        } = this.props;

        return (
            <div className = "page-container">
                <Page title = "Add New Vehicle Group">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form" style = {{ marginBottom: 25 }}>
                            <FormItem
                                label = "Group Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Group Name"
                                    value = {this.state.groupName}
                                    onChange = {e =>
                                        this.setState({
                                            groupName: e.target.value
                                        })
                                    }
                                />
                            </FormItem>

                            {/* <FormItem
                                label = "Assign vehicles"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {
                                    vehicleGroups.allVGIDs.length > 0 && vehicles.allIds.length > 0 ?
                                        <TreeSelect
                                            treeCheckable = {true}
                                            filterTreeNode = {false} // Keep this disabled or filtering will be weird
                                            showCheckedStrategy = {SHOW_PARENT}
                                            placeholder = 'Please Select Vehicles'
                                            value = {this.state.selectedVIDKeys}
                                            onSearch = {(value) => {
                                                this.setState({
                                                    ...this.state,
                                                    searchedVehicleNameString: value
                                                },
                                                    // () => console.log("Searched Vehicle Name String:", this.state.searchedVehicleNameString)
                                                )
                                            }}
                                            onChange = {(value) => {
                                                // console.log("Value:", value);

                                                this.setState({
                                                    ...this.state,
                                                    selectedVIDKeys: value
                                                },
                                                    //    () => console.log("Selected VID Objects:", this.state.selectedVIDKeys)
                                                )
                                            }}
                                        >
                                            {
                                                vehicleGroups.allVGIDs
                                                    .filter(currVGID => vehicleGroups.byVGID[currVGID].vehicles.filter((currVID) => vehicles.byId[currVID].vehicleDisplayName.toLowerCase().includes(this.state.searchedVehicleNameString.toLowerCase()) && currVID).length > 0 && currVGID)
                                                    .map(currVGID => {
                                                        return (
                                                            <TreeNode
                                                                key = {currVGID}
                                                                value = {currVGID}
                                                                title = {vehicleGroups.byVGID[currVGID].groupName}
                                                            >
                                                                {
                                                                    vehicleGroups.byVGID[currVGID].vehicles
                                                                        .filter((currVID) => vehicles.byId[currVID].vehicleDisplayName.toLowerCase().includes(this.state.searchedVehicleNameString.toLowerCase()) && currVID)
                                                                        .map((currVID) => {
                                                                            if (vehicles.byId[currVID]) {
                                                                                return (
                                                                                    <TreeNode
                                                                                        key = {currVID}
                                                                                        value = {currVGID + this.keySeperator + currVID}
                                                                                        title = {vehicles.byId[currVID].vehicleDisplayName}
                                                                                    />
                                                                                )
                                                                            } else {
                                                                                return null
                                                                            }
                                                                        })
                                                                }
                                                            </TreeNode>
                                                        );
                                                    })
                                            }
                                        </TreeSelect> :
                                        <Input disabled = {true} placeholder = "No Vehicles Found" />
                                }
                            </FormItem> */}
                            
                            <FormItem
                                label = "Assign Vehicle(s)"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {
                                    Object.values(vehicles.byId).length ?
                                        <Select
                                            mode = "multiple"
                                            placeholder = "Select Vehicles To Add (Optional)"
                                            value = {this.state.selectedVIDKeys.length > 0 ? this.state.selectedVIDKeys : undefined}
                                            onSearch = {(value) => {
                                                // console.log("Test:", value);

                                                this.setState({ 
                                                    ...this.state,
                                                    searchedVehicleNameString: value
                                                })
                                            }}
                                            onChange = {(value) => {
                                                // console.log("Test:", value);

                                                this.setState({ 
                                                    ...this.state,
                                                    selectedVIDKeys: [...value]
                                                })
                                            }}
                                            style = {{ width: '100%' }}
                                        >
                                            {
                                                Object.values(vehicles.byId)
                                                .filter((currVehicle) => currVehicle.vehicleDisplayName && currVehicle.vehicleDisplayName.toLowerCase().includes(this.state.searchedVehicleNameString.toLowerCase()))
                                                .sort((a, b) => {
                                                    const vA = a.vehicleDisplayName.toLowerCase();
                                                    const vB = b.vehicleDisplayName.toLowerCase();
            
                                                    if (vA < vB) return -1;
                                                    if (vA > vB) return 1;
                                                    return 0;
                                                })
                                                .map((currVehicle) => 
                                                    <Select.Option key = {currVehicle.vid} value = {currVehicle.vid}>
                                                        {currVehicle.vehicleDisplayName}
                                                    </Select.Option>
                                                )
                                            }
                                        </Select> :
                                        <Select
                                            disabled
                                            placeholder = "No Vehicles Found"
                                        />
                                }
                            </FormItem>

                            <div
                                style = {{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading = {this.props.style.isLoadingSubmit && this.state.isLoading}
                                    onClick = {this.submitForm}
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.v2.user,
    style: state.v2.style,
    vehicles: state.v2.vehicles,
    vehicleGroups: state.v2.vehicleGroups,
});

const ConnectedAddVehicle = connect(mapStateToProps)(AddVehicleGroup);
export default showSecondarySidebar(false)(ConnectedAddVehicle);