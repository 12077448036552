import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../components/Page";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../components/Button";
import { goBackToPrev } from "../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message,
} from "antd";

// Redux Actions
import { edit_vehicle_group } from "../../services/redux/actions/vehicleGroups";

const FormItem = Form.Item;

class EditVehicleGroup extends Component {
    state = {
        selectedVG: {},

        // Search Stuff
        searchedVehicleNameString: "",
    }

    keySeperator = " - ";

    submitForm = () => {
        this.setState({ isLoading: true });

        let isValid = true;

        if (!this.state.selectedVG.groupName) {
            message.error("Please Enter The Vehicle Group's Name");

            isValid = false;
        }

        if (isValid) {
            // console.log("Editing Vehicle Group:", this.state.selectedVG);

            this.props.dispatch(edit_vehicle_group(this.state.selectedVG));
        }
    }

    setUpSelectedVG = () => {
        const selectedVG = this.props.vehicleGroups.byVGID[this.props.router.location.state.selectedVGID];
        
        this.setState({
            selectedVG: {
                ...selectedVG,
                vehicles:
                    selectedVG.vehicles
                    .sort((a, b) => {
                        const vA = this.props.vehicles.byId[a].vehicleDisplayName.toLowerCase();
                        const vB = this.props.vehicles.byId[b].vehicleDisplayName.toLowerCase();

                        if (vA < vB) return -1;
                        if (vA > vB) return 1;
                        return 0;
                    })
            },
        })
    }

    componentDidMount = () => {
        if (Object.keys(this.props.vehicles.byId).length && Object.keys(this.props.vehicleGroups.byVGID).length) {
            this.setUpSelectedVG();
        }
    }

    componentDidUpdate = (prevProps) => {
        const { 
            vehicles, 
            vehicleGroups 
        } = this.props;

        if ((JSON.stringify(prevProps.vehicles) !== JSON.stringify(vehicles) || JSON.stringify(prevProps.vehicleGroups) !== JSON.stringify(vehicleGroups))
            && Object.keys(vehicles.byId).length
            && Object.keys(vehicleGroups.byVGID).length
        ) {
            this.setUpSelectedVG();
        }
    }

    render() {
        const { selectedVG } = this.state;

        const { vehicles } = this.props;

        return (
            <div className = "page-container">
                <Page title = "Edit Vehicle Group">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form" style = {{ marginBottom: 25 }}>
                            <FormItem
                                label = "Group Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    disabled = {!selectedVG.groupName}
                                    placeholder = "Group Name"
                                    value = {selectedVG.groupName}
                                    onChange = {e =>
                                        this.setState({
                                            selectedVG: {
                                                ...selectedVG,
                                                groupName: e.target.value,
                                            }  
                                        })
                                    }
                                />
                            </FormItem>

                            {/* <FormItem
                                label = "Assign vehicles"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {
                                    vehicleGroups.allVGIDs.length > 0 && vehicles.allIds.length > 0 ?
                                        <TreeSelect
                                            treeCheckable = {true}
                                            filterTreeNode = {false} // Keep this disabled or filtering will be weird
                                            showCheckedStrategy = {SHOW_PARENT}
                                            placeholder = 'Please Select Vehicles'
                                            value = {this.state.selectedVIDKeys}
                                            onSearch = {(value) => {
                                                this.setState({
                                                    ...this.state,
                                                    searchedVehicleNameString: value
                                                },
                                                    // () => console.log("Searched Vehicle Name String:", this.state.searchedVehicleNameString)
                                                )
                                            }}
                                            onChange = {(value) => {
                                                // console.log("Value:", value);

                                                this.setState({
                                                    ...this.state,
                                                    selectedVIDKeys: value
                                                },
                                                    //    () => console.log("Selected VID Objects:", this.state.selectedVIDKeys)
                                                )
                                            }}
                                        >
                                            {
                                                vehicleGroups.allVGIDs
                                                    .filter(currVGID => vehicleGroups.byVGID[currVGID].vehicles.filter((currVID) => vehicles.byId[currVID].vehicleDisplayName.toLowerCase().includes(this.state.searchedVehicleNameString.toLowerCase()) && currVID).length > 0 && currVGID)
                                                    .map(currVGID => {
                                                        return (
                                                            <TreeNode
                                                                key = {currVGID}
                                                                value = {currVGID}
                                                                title = {vehicleGroups.byVGID[currVGID].groupName}
                                                            >
                                                                {
                                                                    vehicleGroups.byVGID[currVGID].vehicles
                                                                        .filter((currVID) => currVID && vehicles.byId[currVID].vehicleDisplayName.toLowerCase().includes(this.state.searchedVehicleNameString.toLowerCase()))
                                                                        // .sort((a, b) => {
                                                                        //     const vA = a.vehicleDisplayName.toLowerCase();
                                                                        //     const vB = b.vehicleDisplayName.toLowerCase();
                                    
                                                                        //     if (vA < vB) return -1;
                                                                        //     if (vA > vB) return 1;
                                                                        //     return 0;
                                                                        // })
                                                                        .map((currVID) => {
                                                                            if (vehicles.byId[currVID]) {
                                                                                return (
                                                                                    <TreeNode
                                                                                        key = {currVID}
                                                                                        value = {currVID}
                                                                                        title = {vehicles.byId[currVID].vehicleDisplayName}
                                                                                    />
                                                                                )
                                                                            } 
                                                                            else {
                                                                                return null
                                                                            }
                                                                        })
                                                                }
                                                            </TreeNode>
                                                        );
                                                    })
                                            }
                                        </TreeSelect> :
                                        <Input disabled = {true} placeholder = "No Vehicles Found" />
                                }
                            </FormItem> */}
                            
                            <FormItem
                                label = "Assign Vehicle(s)"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {
                                    Object.values(vehicles.byId).length ?
                                        <Select
                                            disabled = {!(Object.values(vehicles.byId).length)}
                                            mode = "multiple"
                                            placeholder = "Select Vehicles To Add (Optional)"
                                            value = {selectedVG.vehicles && selectedVG.vehicles.length ? selectedVG.vehicles : undefined}
                                            onSearch = {(value) => {
                                                // console.log("Test:", value);

                                                this.setState({ 
                                                    ...this.state,
                                                    searchedVehicleNameString: value
                                                })
                                            }}
                                            onChange = {(value) => {
                                                // console.log("Test:", value);

                                                this.setState({
                                                    selectedVG: {
                                                        ...selectedVG,
                                                        vehicles: value,
                                                    }  
                                                })
                                            }}
                                            style = {{ width: '100%' }}
                                        >
                                            {
                                                Object.values(vehicles.byId)
                                                .filter((currVehicle) => currVehicle.vehicleDisplayName && currVehicle.vehicleDisplayName.toLowerCase().includes(this.state.searchedVehicleNameString.toLowerCase()))
                                                .sort((a, b) => {
                                                    const vA = a.vehicleDisplayName.toLowerCase();
                                                    const vB = b.vehicleDisplayName.toLowerCase();
            
                                                    if (vA < vB) return -1;
                                                    if (vA > vB) return 1;
                                                    return 0;
                                                })
                                                .map((currVehicle) => 
                                                    <Select.Option key = {currVehicle.vid} value = {currVehicle.vid}>
                                                        {currVehicle.vehicleDisplayName}
                                                    </Select.Option>
                                                )
                                            }
                                        </Select> :
                                        <Select
                                            disabled
                                            placeholder = "No Vehicles Found"
                                        />
                                }
                            </FormItem>

                            <div
                                style = {{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading = {this.props.style.isLoadingSubmit && this.state.isLoading}
                                    onClick = {this.submitForm}
                                >
                                    Edit
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    vehicles: state.v2.vehicles,
    vehicleGroups: state.v2.vehicleGroups,
});

const ConnectedAddVehicle = connect(mapStateToProps)(EditVehicleGroup);
export default showSecondarySidebar(false)(ConnectedAddVehicle);