import * as API from "../../api";
import * as ActionTypes from "../action-types/deviceLog";

import { message } from "antd";
import {
  setLoadingSubmit,
  unsetLoadingSubmit,
} from "./style";

//TODO: future should focus on vehicle centric, instead of device centric. replacement to get_vehicle_log_by_timeframe_request
export const get_device_log_by_timeframe_request = (dvid, startTime, endTime) => dispatch => {
  dispatch(setLoadingSubmit());

  // console.log("Getting device log by timeframe:", dvid, startTime, endTime);

  API.getDeviceLogByTimeframe(dvid, startTime, endTime)
    .then(data => {

      if (data.status !== 200 && data.errorMessage) {
        message.error("Get Device Log Error: " + data.errorMessage);
      }
      else {
        dispatch(get_device_log_by_timeframe_success(data));
      }
      
    })
    .finally(()=>{

      dispatch(unsetLoadingSubmit());
    })

  dispatch(setSelectedMarker(0));
}

const get_device_log_by_timeframe_success = (deviceLog) => {
  return {
    type: ActionTypes.GET_DEVICE_LOG,
    deviceLog: deviceLog
  }
}

export const get_device_log_debug_by_timeframe_request = (dvid, startTime, endTime) => dispatch => {
  dispatch(setLoadingSubmit());

  API.getDeviceLogDebugByTimeframe(dvid, startTime, endTime)
    .then(data => {
      if (data.length < 1) {
        message.info("No device logs found within this timeframe.");
      }

      dispatch(get_device_log_by_timeframe_debug_success(data));
    })
    .finally(()=>{

      dispatch(unsetLoadingSubmit());
    })

  dispatch(setSelectedMarker(0));
}

const get_device_log_by_timeframe_debug_success = (data) => {
  // console.log("Get Device Log By Timeframe Debug Success Data:", data);

  return {
    type: "GET_DEVICE_LOG_DEBUG",
    data: data
  }
}

export const get_device_log_debug_device_by_timeframe_request = (dvid, startTime, endTime) => dispatch => {
  dispatch(setLoadingSubmit());

  // console.log('get_device_log_debug_device_by_timeframe_request running...');
  // console.log({ dvid, startTime, endTime });

  const day = 24 * 60 * 60 * 1000
  let deviceLogData = []

  // calls api in a loop ( by day ) so doesn't overwhelm api
  let promises = []
  for (let i = startTime; i <= endTime; i += day) {
    const newEndTime = i
    const newStartTime = i - day
    let promise = API.getDeviceLogDebugDeviceByTimeframe(dvid, newStartTime, newEndTime)
      .then(data => {
        data && deviceLogData.push(...data)
      })

    promises.push(promise)
  }

  // calls api for remaining time
  if ((endTime - startTime) % day) {
    let promise = API.getDeviceLogDebugDeviceByTimeframe(dvid, (endTime - startTime) % day, endTime)
      .then(data => {
        data && deviceLogData.push(...data)
      })

    promises.push(promise)
  }

  // console.log(`...running ${promises.length} API calls`);

  Promise.all(promises)
    .then(() => {

      // console.log('...done');
      dispatch(get_device_log_by_timeframe_success(deviceLogData));
    })
    .finally(()=>{

      dispatch(unsetLoadingSubmit());
    })

  dispatch(setSelectedMarker(0));
}

// const get_device_log_device_by_timeframe_debug_success = (data) => {
//   // console.log("Get Device Log Device By Timeframe Debug Success Data:", data);

//   return {
//     type: ActionTypes.GET_DEVICE_LOG_DEBUG_TEST,
//     data: data
//   }
// }

export const setSelectedTime = (startTime, endTime) => {
  return {
    type: ActionTypes.SET_SELECTED_TIME,
    startTime,
    endTime
  };
};

export const clearSelectedTime = () => {
  return {
    type: ActionTypes.CLEAR_SELECTED_TIME
  };
};

export const setSelectedMarker = (markerPlaybackIndex) => {
  return {
    type: ActionTypes.SET_SELECTED_MARKER,
    markerPlaybackIndex: markerPlaybackIndex
  }
}

export const setSelectedPackageTime = (selectedTime) => {
  return {
    type: ActionTypes.SET_SELECTED_PACKAGE_TIME,
    selectedTime: selectedTime
  }
}

export const clearSelectedPackageTime = () => {
  return {
    type: ActionTypes.CLEAR_SELECTED_PACKAGE_TIME,
  }
}