import * as API from "../../api"
import * as actionType from "../action-types/user";

import { message } from 'antd'

export const sa_user_sign_in_action = uid => dispatch => {
    API.getInfoByUser(uid)
    .then(info => {
        // console.log("sa_user_sign_in_action info:", info);

        if (info.errorType ==='ValidationException') {
            message.error('Error. Refresh page?');
        }
        else {
            dispatch(sa_user_sign_in_success(info.user));
        }
    })
}

export const sa_user_sign_in_success = (user) => {
    return {
        type: actionType.SA_USER_SIGN_IN,
        user
    };
}

export const sa_user_sign_out = () => {
    return {
        type: actionType.SA_USER_SIGN_OUT
    };
}