import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import Page from '../../../../components/Page';
import ExportExcelButton from '../../../../components/ExportExcelButton';
import showSecondarySidebar from '../../../../components/Layout/helpers/showSecondarySidebar';

import { PrimaryButton } from '../../../../components/Button';
import { DISPLAY_NOTIFICATION_TYPE } from '../../../../../../constants';
import {
    Form,
    Table,
    Select,
    DatePicker,
} from 'antd';

// Redux Actions
import { get_notification_by_timeframe } from '../../../../services/redux/actions/notifications';

class NotificationLogPage extends React.Component {
    state = {
        dataSource: [],
        startTime: moment().startOf('month'),
        endTime: moment(),
    }

    NOTIFICATION_TYPES_FORMAT_RECIPIENT = {
        0: {
            formatRecipient: (recipients) => {
                return Array.isArray(recipients) ? recipients.map((recipient) => `${recipient.email} (${recipient.name})`) : "-";
            }
        },
        1: {
            formatRecipient: (recipients) => {
                return Array.isArray(recipients) ? recipients.map((recipient) => recipient.name) : "-";
            }
        },
        2: {
            formatRecipient: (recipients) => {
                return Array.isArray(recipients) ? recipients.map((recipient) => `${recipient.contact} (${recipient.name})`) : "-";
            }
        },
        3: {
            formatRecipient: recipients => {
                return Array.isArray(recipients) ? recipients.map((recipient) => `${recipient.contact} (${recipient.name})`) : "-";
            }
        }
    }

    durationFormat = ms => {
        const momentDuration = moment.duration(ms);
        const days = parseInt(momentDuration.asDays(), 10);
        const hrs = momentDuration.hours();
        const mins = momentDuration.minutes();
        const secs = momentDuration.seconds();

        return (
            days ?
                `${days} days ${hrs} hrs ${mins} mins` :
                hrs ?
                    `${hrs} hrs ${mins} mins` :
                    mins ?
                        `${mins} mins ${secs} secs` :
                        `${secs} secs`
        )
    }


    submitForm = () => {
        this.props.dispatch(
            get_notification_by_timeframe(
                this.state.selectedNfID,
                this.state.startTime.utc().valueOf(),
                this.state.endTime.utc().valueOf()
            )
        )
    }


    setupFormDefaultValues = () => {
        const nfid = this.props.router.location.state

        this.setState({ selectedNfID: nfid })
    }

    componentDidMount = () => {
        this.setupFormDefaultValues()
        this.setState({
            ...this.state,
            selectedNfID: this.props.notifications.selectedNfID
        })
    }

    componentDidUpdate = prevProps => {
        const { notifications } = this.props;

        if (
            JSON.stringify(prevProps.notifications) !== JSON.stringify(this.props.notifications)
            || JSON.stringify(prevProps.notifications) !== JSON.stringify(this.props.notifications)
        ) {
            this.setupFormDefaultValues()
        }

        if (JSON.stringify(prevProps.notifications.notificationLog) !== JSON.stringify(this.props.notifications.notificationLog)) {
            const dataSource = notifications.notificationLog
                .sort((a, b) => b.createdAt - a.createdAt)
                .map((log) => {
                    const notification = this.props.notifications.byNfID[log.nfid];

                    return {
                        key: `${log.nfid}#${log.createdAt}`,
                        notificationType: notification && DISPLAY_NOTIFICATION_TYPE[notification.serviceType],
                        notificationName: notification && notification.notificationName,

                        recipient: notification && this.NOTIFICATION_TYPES_FORMAT_RECIPIENT[notification.serviceType] && this.NOTIFICATION_TYPES_FORMAT_RECIPIENT[notification.serviceType].formatRecipient(log.recipient),

                        createdAt: log.createdAt,
                        deviceTime: log.deviceTime,
                        // createdAt: moment(currLog.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                        // deviceTime: moment(currLog.deviceTime).format('YYYY-MM-DD HH:mm:ss'),
                        statusCode: !isNaN(log.statusCode) ? log.statusCode : "-",
                        statusMessage: log.statusMessage && typeof(log.statusMessage) === "string" ? log.statusMessage : "-",

                        getid: log.getid,
                        genid: log.genid,
                        genStatus: log.genStatus,
                    }
                })
            
            // console.log("New Data Source:", dataSource);

            this.setState({
                dataSource
            });
        }
    }

    render() {
        const { selectedNfID } = this.state;
        const { notifications } = this.props;

        const columns = [
            {
                title: 'Name',
                key: 'notificationName',
                dataIndex: 'notificationName',

            },
            {
                title: 'Type',
                key: 'notificationType',
                dataIndex: 'notificationType',

            },
            // {
            //     title: 'ID',
            //     key: 'key',
            //     dataIndex: 'key',

            // },
            {
                title: 'Recipient(s)',
                key: 'recipient',
                dataIndex: 'recipient',
                render: recipients => {
                    return (
                        <ol>
                            {Array.isArray(recipients) ? recipients.map((r, i) => <li key = {i}>{r}</li>) : "-"}
                        </ol>
                    )
                }

            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                render: time => moment(time).format('YYYY-MM-DD HH:mm:ss')
            },
            {
                title: 'Device Time',
                key: 'deviceTime',
                dataIndex: 'deviceTime',
                render: time => moment(time).format('YYYY-MM-DD HH:mm:ss')
            },
            {
                title: 'Status Code',
                key: 'statusCode',
                dataIndex: 'statusCode',
            },
            {
                title: 'Status Message',
                key: 'statusMessage',
                dataIndex: 'statusMessage',
            },
        ]

        return (
            <div className = 'page-container'>
                <Page title = 'Notification Log'>
                    <div
                        style = {{
                            display: "block",
                            minHeight: "95px",
                            padding: "10px 10px 10px 10px",
                        }}
                    >
                        <Form layout = 'inline'>
                            <Form.Item>
                                <span style = {{ marginLeft: '10px' }}>Notification: </span>

                                <Select
                                    showSearch
                                    placeholder = 'Select a Notification'
                                    value = {this.state.selectedNfID}
                                    filterOption = {(input, option) => {
                                        // console.log({ input, option });

                                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    onSelect = {selectedNfID => {
                                        this.setState({ selectedNfID })
                                    }}
                                    style = {{ width: '250px' }}
                                >
                                    {
                                        Object.values(notifications.byNfID)
                                            .sort((a, b) => {
                                                const A = a.notificationName.toLowerCase();
                                                const B = b.notificationName.toLowerCase();
                                        
                                                if (A < B) return -1;
                                                if (A > B) return 1;
                                                return 0;
                                            })
                                            .map(notification => {
                                                return <Select.Option
                                                    key = {notification.nfid}
                                                    value = {notification.nfid}
                                                >
                                                    {notification.notificationName}
                                                </Select.Option>
                                            })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <span style = {{ marginLeft: '10px' }}>Start time: </span>

                                <DatePicker
                                    showTime
                                    defaultValue = {this.state.startTime}
                                    style = {{ width: '177px' }}
                                    onChange = {value => this.setState({ startTime: value })}
                                />

                                <span style = {{ marginLeft: '10px' }}>End time: </span>

                                <DatePicker
                                    showTime
                                    defaultValue = {this.state.endTime}
                                    style = {{ width: '177px' }}
                                    onChange = {value => this.setState({ endTime: value })}
                                />
                            </Form.Item>

                            <Form.Item>
                                <PrimaryButton
                                    style = {{ marginLeft: '15px' }}
                                    onClick = {this.submitForm}
                                    loading = {this.props.style.isLoadingSubmit}
                                    disabled = {!(selectedNfID)}
                                >
                                    Submit
                                </PrimaryButton>

                                {
                                    <ExportExcelButton
                                        loading = {this.props.style.isLoadingSubmit}
                                        disabled = {!this.state.dataSource.length}
                                        filename = {`${notifications.byNfID[selectedNfID] && notifications.byNfID[selectedNfID].notificationName} - Notification Log - ${moment(this.state.startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(this.state.endTime).format('YYYY-MM-DD HH:mm:ss')}`}
                                        sheetData = {this.state.dataSource}
                                        sheetName = 'Notification Log'
                                        sheetRow = {
                                            columns.map(col => ({
                                                label: col.title,
                                                formatter: value => value[col.dataIndex]
                                            }))
                                        }
                                    />
                                }
                            </Form.Item>
                        </Form>
                    </div>

                    <Table
                        loading = {this.props.style.isLoadingSubmit}
                        columns = {columns}
                        dataSource = {this.state.dataSource}
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    style: state.v2.style,
    notifications: state.v2.notifications,
    hierarchy: state.v2.hierarchy,
});

const ConnectedNotificationLogPage = connect(mapStateToProps)(NotificationLogPage);
export default showSecondarySidebar(false)(ConnectedNotificationLogPage);