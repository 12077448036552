import
// React, 
{
    // useState, 
    useEffect,
    useCallback
}
    from 'react';
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment';

import { update_device } from '../../services/redux/actions/devices'
import { update_vehicle } from '../../services/redux/actions/vehicles'
import { update_notification_rt } from '../../services/redux/actions/notificationRT'

import { 
    // message, 
    notification 
} from 'antd';

import {
    RULE_TYPE_ANT
} from '../../../../constants'

import {
    wsContainer,
    // closeWS,
} from '../../services/websocket';
import { user_sign_in_request } from '../../services/redux/actions/user';

notification.config({
    placement: 'bottomRight',
    bottom: 50,
    duration: 60,
});

const BackgroundTask = (props) => {

    // const [messageCount, setMessageCount] = useState(0);
    const notificationRTs = useSelector(state => state.v2.notificationRT.byEVID);
    const notificationRTLatest = useSelector(state => state.v2.notificationRT.latestEVID);
    const vehicles = useSelector(state => state.v2.vehicles.byId);
    const user = useSelector(state=>state.v2.user)

    const dispatch = useDispatch()

    const redux_update_device = useCallback((data) => dispatch(update_device(JSON.parse(data))), [dispatch])
    // const redux_update_vehicle = useCallback((data) => dispatch(update_vehicle(JSON.parse(data))), [dispatch])
    const redux_update_notification_rt = useCallback((data) => dispatch(update_notification_rt(JSON.parse(data))), [dispatch])

    // const timeDiff = (time) => moment(Number(time)).utc().diff(moment().utc())


    useEffect(() => {

        wsContainer(
            (err, data) => {
                // setMessageCount(messageCount + 1);
                // console.log('[BackgroundTask] WebSoc', data.mode)
                // if (data.id === 'GPS100#864893036802141' || data.id === 'vhc#a17bf9b0-3ce7-11ea-aaf7-ad2d2391ff77') {
                //     console.log('ws', data)
                //     console.log(moment(data.item.updatedAt).format('hh:mm:ss a'))
                // }

                if (!err) {
                    // console.log('ws')
                    if (data.mode === 'dev' && data.id) {
                        // console.log('[BackgroundTask] WebSoc -  Device', data.item.package)
                        dispatch(redux_update_device(JSON.stringify(data.item)))
                    } else if (data.mode === 'vhc' && data.id) {
                        // console.log('[BackgroundTask] WebSoc - Vehicle', data.item.location , moment().format('LLLL'))
                        dispatch(redux_update_device(JSON.stringify(data.item)))
                        // dispatch(redux_update_vehicle(JSON.stringify(data.item)))
                        dispatch(update_vehicle(data.item))
                    } else if (data.mode === 'notify' && data.id) {
                        // console.log('[BackgroundTask] WebSoc - Notify', data)
                        dispatch(redux_update_notification_rt(JSON.stringify(data.item)))
                    }
                }
            },
            () => {
                console.log(`resubscribe...`)
                const uid = user && user.uid
                if(uid){
                    dispatch(user_sign_in_request(uid))
                }
            }
        )

    })

    const onNotificationComing = useCallback(() => {
        const occurredVehicle = (notificationRTLatest) => vehicles[notificationRTs[notificationRTLatest].vid].vehicleDisplayName
        const displayEventType = (notificationRTLatest) => notificationRTs[notificationRTLatest].eventType
        const displayEventName = (notificationRTLatest) => notificationRTs[notificationRTLatest].eventName
        const eventAt = (notificationRTLatest) => moment(notificationRTs[notificationRTLatest].createdAt).format("YYYY-MM-DD h:mm:ss a")

        notificationRTLatest &&
            notification[RULE_TYPE_ANT[displayEventType(notificationRTLatest)]]({
                "message": `[${occurredVehicle(notificationRTLatest)}]`,
                "description": `${displayEventName(notificationRTLatest)} At ${eventAt(notificationRTLatest)}`,
                "placement": `bottomRight`
            })
    }, [notificationRTLatest, notificationRTs, vehicles])

    useEffect(onNotificationComing, [notificationRTLatest])


    return null
}

export default BackgroundTask