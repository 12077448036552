import React, { Component } from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Highlighter from 'react-highlight-words';
import EventStatusIcon from "../../../components/EventStatusIcon";

import { parseDuration } from '../../../util/time';
import { PrimaryButton } from "../../../components/Button";
import { moveToPage } from "../../../navigation/navigationService";
import { EmptyIndicator } from "../../../components/EmptyIndicator";
import { DEFAULT_GEOFENCE_HEXCODE } from "../../../../../constants";
import {
    VEHICLE_COLOR,
    VEHICLE_STATUS,
} from "../../../../../constants";
import {
    Row,
    Col,
    Table,
    Input,
    Button,
} from "antd";
import {
    SearchOutlined
} from '@ant-design/icons'

class VehicleDisplayPanel extends Component {
    state = {
        searchText: "",
        getUserInfo: false,

        dataSource: [],
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style = {{ padding: 8 }}>
                <Input
                    ref = {(node) => { this.searchInput = node }}
                    placeholder = {`Search ${dataIndex}`}
                    value = {selectedKeys[0]}
                    onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter = {() => this.handleSearch(selectedKeys, confirm)}
                    style = {{
                        width: 188,
                        marginBottom: 8,
                        display: 'block',
                    }}
                />

                <Button
                    type = "primary"
                    icon = {<SearchOutlined/>}
                    size = "small"
                    onClick = {() => this.handleSearch(selectedKeys, confirm)}
                    style = {{
                        width: 90,
                        marginRight: 8,
                    }}
                >
                    Search
                </Button>

                <Button
                    size = "small"
                    style = {{ width: 90 }}
                    onClick = {() => this.handleReset(clearFilters)}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined 
                style = {{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                textToHighlight = {text ? text : ""}
                searchWords = {[this.state.searchText]}
                highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
                autoEscape
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();

        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();

        this.setState({ searchText: '' });
    };

    timeDiff = (currVehicle) => {
        const duration = moment(Number(currVehicle.updatedAt)).utc().diff(moment(currVehicle.transitFrom).utc())
        return parseDuration(duration);
    }

    returnLatestGeofenceTemplateColorForGeoID = (geoID) => {
        let latestTemplate = {};

        Object.values(this.props.geofenceTemplates.byId)
            .filter((currTemplate) => currTemplate.geofences.includes(geoID)) // Only check geofence templates containing this geofence ID
            .forEach((currTemplate) => {
                if (!latestTemplate.createdAt || currTemplate.createdAt > latestTemplate.createdAt) {
                    latestTemplate = currTemplate;
                }
            })

        // console.log("Color:", latestTemplate.colorHexCode);

        return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }

    setupDatasource = () => {
        const dataSource = Object.values(this.props.vehicles.byId)
            .sort((b, a) => b.vehiclePlate - a.vehiclePlate)
            .map(vehicle => {
                const device = this.props.devices.byId[vehicle.dvid];

                const fuelProfile = Object.keys(vehicle.fuelProfile || {})
                    .map((key, i) => {
                        const deviceLevel = (device && device.fuelProfile[key]) || 0
                        const vehicleTank = vehicle.fuelProfile[key]

                        const fuelLevel = (deviceLevel * vehicleTank.fuelCapacity).toFixed(2)

                        return `[Tank ${i + 1}] ${fuelLevel} L`
                    })

                const inGeofences = vehicle.geofenceProfile ?
                    Object.keys(vehicle.geofenceProfile)
                        .filter(geoid => {
                            return vehicle.geofenceProfile[geoid] === 1
                        })
                        .map(geoid => this.props.geofences.byId[geoid]) :
                    [];

                return {
                    key: vehicle.vid,
                    vehiclePlate: vehicle.vehiclePlate,
                    vehicleDisplayName: vehicle.vehicleDisplayName,
                    transitStatus: vehicle.transitStatus,
                    location: { inGeofences, device, vehicle },
                    fuelProfile: fuelProfile,
                    updatedAt: vehicle.updatedAt,

                    duration: this.timeDiff(vehicle),
                    speed: device && `${(device.speed && device.speed.toFixed(2)) || 0} km/h`,
                    odometer: vehicle.mileage && !isNaN(Number(vehicle.mileage)) ? `${vehicle.mileage.toFixed(2)} km` : "-",
                    details: vehicle.dvid,
                }
            })

        this.setState({ dataSource })
    }

    componentDidMount = () => {
        this.setupDatasource()
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setupDatasource()
        }
    }

    render() {
        const columns = [
            {
                title: "Details",
                dataIndex: "details",
                render: (rowData) => <EventStatusIcon deviceId = {rowData}/>
            },
            {
                title: "Vehicle Name",
                dataIndex: "vehicleDisplayName",
                // fixed: 'left',
                ...this.getColumnSearchProps("vehicleDisplayName"),
            },

            {
                title: "Vehicle Plate",
                dataIndex: "vehiclePlate",
                // fixed: 'left',
                ...this.getColumnSearchProps("vehiclePlate"),
            },

            {
                title: "Status",
                dataIndex: "transitStatus",
                render: transitStatus => {
                    if (transitStatus) {
                        return (
                            <div style = {{ color: VEHICLE_COLOR[transitStatus] }}>
                                {transitStatus}
                            </div>
                        )
                    }

                    return '-'
                }
            },

            {
                title: "Duration",
                dataIndex: "duration"
            },

            {
                title: "Speed",
                dataIndex: "speed",
            },

            {
                title: "Odometer",
                dataIndex: "odometer",
            },

            {
                title: "Fuel",
                dataIndex: "fuelProfile",
                render: (fuelProfile = []) => {
                    if (fuelProfile.length) {
                        return (
                            <ul>
                                {
                                    fuelProfile.map((p, i) => {
                                        return (
                                            <li key = {i}>{p}</li>
                                        )
                                    })
                                }
                            </ul>
                        )
                    }
                    return `(No fuel profiles)`
                }
            },

            {
                title: "Location",
                dataIndex: "location",
                render: ({ inGeofences, device, vehicle }) => {
                    if (!device) return

                    // console.log({inGeofences})
                    
                    /**Prioritize showing geofence first */
                    const inGeofence = inGeofences && inGeofences[0];

                    if (
                        device &&
                        device.location &&
                        inGeofence
                    ) {
                        return (
                            <a
                                target = "_blank"
                                rel = "noopener noreferrer"
                                href = {`https://www.google.com.my/maps/place/${device.location.lat},${device.location.lon}`}
                            >
                                {
                                    vehicle.transitStatus !== VEHICLE_STATUS.DISCONNECTED && (
                                        <div>
                                            <Row style = {{ margin: 1 }}>
                                                <Col
                                                    span = {7}
                                                    className = "POI"
                                                    style = {{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        
                                                        backgroundColor: this.returnLatestGeofenceTemplateColorForGeoID(inGeofences[0].geoid)
                                                    }}
                                                >
                                                    {inGeofence.geoType}
                                                </Col>

                                                <Col
                                                    span = {16}
                                                    offset = {1}
                                                    className = "POI_Description"
                                                >
                                                    {inGeofence.geofenceName}
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }
                            </a>
                        )
                    }

                    return (
                        <a
                            target = "_blank"
                            rel = "noopener noreferrer"
                            href = {`https://www.google.com.my/maps/place/${device.location.lat},${device.location.lon}`}
                        >
                            {vehicle.address || '-'}
                        </a>
                    )
                }
            },
            {
                title: "Last Updated",
                dataIndex: "updatedAt",
                sorter: (a, b) => a.updatedAt - b.updatedAt,
                render: updatedAt => (updatedAt && moment(updatedAt).format("YYYY/MM/DD HH:mm:ss")) || '-'
            },
        ]

        return (
            <div
                style = {{
                    flex: 1,
                    display: "flex",
                }}
            >
                <div
                    className = "scroll-div"
                    style = {{ width: "100%" }}
                >
                    <div
                        style = {{
                            display: "flex",
                            justifyContent: "flex-end",

                            padding: 5,
                            marginBottom: 5,
                        }}
                    >
                        <PrimaryButton onClick = {() => this.props.dispatch(moveToPage("/"))}>Dashboard Map</PrimaryButton>
                    </div>

                    {
                        this.state.dataSource.length > 0 ?
                            <Table
                                loading = {this.props.style.isLoadingSubmit}
                                columns = {columns}
                                dataSource = {this.state.dataSource}
                                pagination = {false}
                                scroll = {{
                                    y: window.innerHeight,
                                    x: columns && columns.length * 150
                                }}
                            /> :
                            <EmptyIndicator />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
    hierarchy: state.v2.hierarchy,
    geofenceTemplates: state.v2.geofenceTemplate,
});

export default connect(mapStateToProps)(VehicleDisplayPanel);

