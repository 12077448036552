import React from "react";
import { connect } from "react-redux";

import moment from "moment";
import Highlighter from 'react-highlight-words';

import { ROLE_TYPE } from '../../../../../constants';
import { moveToPage } from "../../../navigation/navigationService";
import { EmptyIndicator } from "../../../components/EmptyIndicator";
import {
  EditOutlined,
  SearchOutlined,
  MonitorOutlined
} from '@ant-design/icons';
import {
  Tabs,
  Table,
  Input,
  Button,
  Tooltip,
} from "antd";

// React Actions
import { select_user_dvid } from "../../../services/redux/actions/hierarchy";

const TabPane = Tabs.TabPane;

class UserInfoTab extends React.Component {
  state = {
    searchText: "",
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style = {{ padding: 8 }}>
        <Input
          ref = {node => {
            this.searchInput = node;
          }}
          value = {selectedKeys[0]}
          placeholder = {`Search ${dataIndex}`}
          onPressEnter = {() => this.handleSearch(selectedKeys, confirm)}
          onChange = {e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          style = {{
            width: 188,
            marginBottom: 8,
            display: 'block',
          }}
        />

        <Button
          size = "small"
          icon = {<SearchOutlined />}
          type = "primary"
          style = {{
            width: 90,
            marginRight: 8
          }}
          onClick = {() => this.handleSearch(selectedKeys, confirm)}
        >
          Search
        </Button>

        <Button
          size = "small"
          style = {{ width: 90 }}
          onClick = {() => this.handleReset(clearFilters)}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style = {{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle = {{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords = {[this.state.searchText]}
        autoEscape
        textToHighlight = {text ? text.toString() : ""}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({
      ...this.state,
      searchText: selectedKeys[0]
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({
      ...this.state,
      searchText: ''
    });
  };

  returnIfUserIsFleetOwnerOrOperator = () => {
    return this.props.user.userRole && (this.props.user.userRole === ROLE_TYPE.FLEET_OWNER || this.props.user.userRole === ROLE_TYPE.FLEET_OPERATOR);
  }

  render() {
    const { hierarchy } = this.props;

    const selectedUser = hierarchy.byId[hierarchy.selectedId];

    // console.log("Test:", selectedUser.vehicles);

    const vehicleArr =
      (
        selectedUser
        && selectedUser.vehicles
        && selectedUser.vehicles.map((currVehicle, index) => {
            const currDevice = 
              selectedUser
              && selectedUser.devices
              && selectedUser.devices.find((device) => device.dvid === currVehicle.dvid)

            const simCard = currDevice && currDevice.simCard;

            return ({
              key: index,
              simCard: simCard,
              ...currVehicle,
            });
          })
      ) ||
      []

    const columnsVehicle = [
      {
        title: `Vehicle Plate (${vehicleArr.length})`,
        dataIndex: "vehiclePlate",
        key: "vehiclePlate",
        ...this.getColumnSearchProps("vehiclePlate"),
      },

      {
        title: "Display Name",
        dataIndex: "vehicleDisplayName",
        key: "vehicleDisplayName",
        ...this.getColumnSearchProps("vehicleDisplayName"),
      },

      {
        title: "Type",
        dataIndex: "vehicleType"
      },

      {
        title: "Model",
        dataIndex: "vehicleModel"
      },

      {
        title: "Assigned Device",
        dataIndex: "dvid",
        key: "dvid",
        ...this.getColumnSearchProps("dvid"),
      },

      {
        title: "Device Sim Card",
        dataIndex: "simCard",
        key: "simCard",
        ...this.getColumnSearchProps("simCard"),
      },

      {
        title: "Creation Date",
        dataIndex: "createdAt",
        render: text => moment(Number(text)).format("YYYY-MM-DD h:mm:ss A"),
        sorter: (a, b) => b.createdAt - a.createdAt,
        sortDirections: ["ascend", "descend"],
        defaultSortOrder: "ascend"
      },

      {
        title: "Actions",
        fixed: 'right',
        render: vehicle => (
          <Button.Group>
            <button className = "transparent-button">
              <Tooltip title = "Inspect Vehicle Log">
                <MonitorOutlined
                  onClick = {() => {
                    this.props.dispatch(moveToPage(`/VehicleInspector/${vehicle.vid}`, {vehicle, uid: this.props.hierarchy.selectedId}))
                  }}
                />
              </Tooltip>
            </button>

            <button className = "transparent-button">
              <Tooltip title = "Edit">
                <EditOutlined
                  onClick = {
                    () => {
                      // Delete extra keys to not accidentally push to Edit Vehicle API
                      delete vehicle.key;
                      delete vehicle.simCard;

                      this.props.dispatch(moveToPage(`/UserManagement/EditVehicle/${vehicle.vid}`, {vehicle, uid: this.props.hierarchy.selectedId}))
                    }
                  }
                />
              </Tooltip>
            </button>

            {/* unimplemented */}
            {/* <button className = "transparent-button">

              <Tooltip title = "Information List">
                <Icon
                  type = "unordered-list"
                  onClick = {() => this.props.dispatch(moveToPage(`/VehicleDisplay/${this.props.hierarchy.selectedId}/`, { vid: vehicle.vid }))}
                />
              </Tooltip>
            </button> */}
          </Button.Group>
        )
      }
    ]

    const deviceArr =
      (
        selectedUser
        && selectedUser.devices
        && selectedUser.devices
          .map((currDevice, index) => {
            const currVehicle = 
              selectedUser
              && selectedUser.vehicles
              && selectedUser.vehicles.find((vehicle) => vehicle.dvid === currDevice.dvid)

            const vehiclePlate = currVehicle && currVehicle.vehiclePlate;

            return ({
              key: index,
              vehiclePlate: vehiclePlate ? vehiclePlate : "-",
              ...currDevice,
            });
          })
      ) ||
      []

    let columnsDevice = [
      {
        key: "dvid",
        title: `Device ID (${deviceArr.length})`,
        dataIndex: "dvid",
        ...this.getColumnSearchProps("dvid"),
      },

      {
        title: `Vehicle Plate (${vehicleArr.length})`,
        dataIndex: "vehiclePlate",
        key: "vehiclePlate",
        ...this.getColumnSearchProps("vehiclePlate"),
      },

      {
        key: "simCard",
        title: "Assigned Sim Card",
        dataIndex: "simCard",
        ...this.getColumnSearchProps("simCard"),
      },

      {
        title: "Creation Date",
        dataIndex: "createdAt",
        render: text => moment(Number(text)).format("YYYY-MM-DD h:mm:ss A"),
        sorter: (a, b) => b.createdAt - a.createdAt,
        sortDirections: ["ascend", "descend"],
        defaultSortOrder: "ascend"
      },

      {
        title: "Actions",
        render: device => (
          <Button.Group>
            <button className = "transparent-button">
              <Tooltip title = "Inspect Device Log">
                <MonitorOutlined
                  onClick = {() => {
                    this.props.dispatch(select_user_dvid(device.dvid));
                    this.props.dispatch(moveToPage(`/DeviceInspector/${this.props.hierarchy.selectedId}/`, { dvid: device.dvid, uid: hierarchy.selectedId }));
                  }}
                />
              </Tooltip>
            </button>

            {
              !this.returnIfUserIsFleetOwnerOrOperator() ?
                <button className = "transparent-button">
                  <EditOutlined onClick = {() => this.props.dispatch(moveToPage(`/UserManagement/EditDevice/${device.dvid}`, { device, targetUser: hierarchy.selectedId }))} />
                </button> :
                null
            }
          </Button.Group>
        )
      }
    ]

    if (this.returnIfUserIsFleetOwnerOrOperator()) {
      columnsDevice.splice(2, 1);
    }

    // console.log({ vehicleArr, deviceArr })

    return (
      <div style = {{ margin: "5% 10%" }}>
        {
          !this.returnIfUserIsFleetOwnerOrOperator() ?
            <Tabs
              type = "card"
              defaultActiveKey = "1"
            >
              <TabPane tab = "Vehicles" key = "vehicles">
                {
                  hierarchy.selectedId ?
                    <Table
                      dataSource = {vehicleArr}
                      columns = {columnsVehicle}
                      scroll = {{
                        x: columnsVehicle && columnsVehicle.length * 150
                      }}
                    /> :
                    <EmptyIndicator />
                }
              </TabPane>

              <TabPane tab = "Devices" key = "devices">
                {
                  hierarchy.selectedId ?
                    <Table
                      dataSource = {deviceArr}
                      columns = {columnsDevice}
                      scroll = {{
                        x: columnsDevice && columnsDevice.length * 150
                      }}
                    /> :
                    <EmptyIndicator />
                }
              </TabPane>
            </Tabs> :
            hierarchy.selectedId ?
              <Table
                dataSource = {vehicleArr}
                columns = {columnsVehicle}
                scroll = {{
                  x: columnsVehicle && columnsVehicle.length * 150
                }}
              /> :
              <EmptyIndicator />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.v2.user,
  hierarchy: state.v2.hierarchy,
});

export default connect(mapStateToProps)(UserInfoTab);