import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import ColorPicker from '../../../components/ColorPicker';
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import { 
    GEOFENCE_COLORS,
    DEFAULT_GEOFENCE_HEXCODE,
} from "../../../../../constants";
import {
    Form,
    Input,
    Button,
    Select,
    message,
} from "antd";

// Redux Actions
import { edit_geofence_template } from "../../../services/redux/actions/geofenceTemplate";

const FormItem = Form.Item;
const { Option } = Select;

class EditGeofenceTemplatePage extends Component {
    state = {
        selectedGT: {},

        isLoading: false,
    }

    submitForm = () => {
        this.setState({ 
            isLoading: true,
        },
            () => {
                const { selectedGT } = this.state;

                let isValid = true;
        
                if (!selectedGT.templateName) {
                    message.error("Please Enter A Geofence Template Name");
                    isValid = false;
                }
        
                if (!selectedGT.colorHexCode) {
                    message.error("Please Enter A Geofence Color For The Geofence Template");
                    isValid = false;
                }
        
                if (isValid) {
                    // console.log("Editing Geofence:", selectedGT);
        
                    this.props.dispatch(edit_geofence_template(selectedGT));
                }
            }
        );
    }

    setSelectedGT = () => {
        const { 
            geofences,
            geofenceTemplate, 
        } = this.props;

        const selectedGTID = this.props.router.location.state.selectedGTID;
        const selectedGT = geofenceTemplate.byId[selectedGTID];

        // console.log("Selected GTID:", selectedGTID);

        this.setState({
            selectedGT: {
                ...selectedGT,
                geofences: 
                    selectedGT.geofences
                    .filter((currGeoID) => geofences.allIds.includes(currGeoID))
                    .sort((a, b) => {
                        const A = geofences.byId[a].geofenceName.toLowerCase();
                        const B = geofences.byId[b].geofenceName.toLowerCase();
                  
                        if (A < B) return -1;
                        if (A > B) return 1;
                        return 0;
                    }),
                colorHexCode: selectedGT.colorHexCode ? selectedGT.colorHexCode : DEFAULT_GEOFENCE_HEXCODE, // In case template does not have a color because it's old
            },
        })
    }

    componentDidMount = () => {
        if (this.props.geofences.allIds.length && this.props.geofenceTemplate.allIds.length) {
            this.setSelectedGT();
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((JSON.stringify(prevProps.geofences) !== JSON.stringify(this.props.geofences) || JSON.stringify(prevProps.geofenceTemplate) !== JSON.stringify(this.props.geofenceTemplate))
            && this.props.geofences.allIds.length
            && this.props.geofenceTemplate.allIds.length
        ) {
            this.setSelectedGT();
        }
    }

    render() {
        const { selectedGT } = this.state;
        const { geofences } = this.props;

        const returnIsGTReady = (objPropName) => {
            return selectedGT && selectedGT[objPropName];
        }

        return (
            <div className = "page-container">
                <Page title = "Edit Geofence Template">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Geofence Template Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    disabled = {!returnIsGTReady("templateName")}
                                    placeholder = "Geofence Template Name"
                                    value = {returnIsGTReady("templateName") ? selectedGT.templateName : undefined}
                                    onChange = {e => {
                                        this.setState({
                                            selectedGT: {
                                                ...selectedGT,
                                                templateName: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </FormItem>
                            
                            <FormItem
                                label = "Assigned Geofence"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                {
                                    returnIsGTReady("geofences") && selectedGT.geofences.length ?
                                        <Select
                                            mode = "multiple"
                                            placeholder = "Select Geofences To Add (Optional)"
                                            value = {selectedGT.geofences}
                                            onChange = {
                                                value => {
                                                    // console.log("Test:", value);

                                                    this.setState({
                                                        selectedGT: {
                                                            ...selectedGT,
                                                            geofences: value.sort((a, b) => {
                                                                const A = geofences.byId[a].geofenceName.toLowerCase();
                                                                const B = geofences.byId[b].geofenceName.toLowerCase();
                                                          
                                                                if (A < B) return -1;
                                                                if (A > B) return 1;
                                                                return 0;
                                                            }),
                                                        }
                                                    })
                                                }
                                            }
                                            style = {{ width: '100%' }}
                                        >
                                            {
                                                Object.values(geofences.byId)
                                                .sort((a, b) => {
                                                    const A = a.geofenceName.toLowerCase();
                                                    const B = b.geofenceName.toLowerCase();
                                              
                                                    if (A < B) return -1;
                                                    if (A > B) return 1;
                                                    return 0;
                                                })
                                                .map((currGeofence) => 
                                                    <Option key = {currGeofence.geoid} value = {currGeofence.geoid}>
                                                        {currGeofence.geofenceName}
                                                    </Option>
                                                )
                                            }
                                        </Select> :
                                        <Select
                                            disabled
                                            placeholder = "No Geofences Found"
                                        />
                                }
                            </FormItem>

                            <FormItem
                                label = "Geofence Color"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <div
                                    style = {{
                                        display: "flex",
                                        alignItems: "center",

                                        height: 40,
                                    }}
                                >
                                    <ColorPicker
                                        disabled = {!returnIsGTReady("colorHexCode")}
                                        currHexCode = {returnIsGTReady("colorHexCode") ? selectedGT.colorHexCode : DEFAULT_GEOFENCE_HEXCODE}
                                        presetColors = {GEOFENCE_COLORS}
                                        onChange = {(color) => {
                                            this.setState({
                                                selectedGT: {
                                                    ...selectedGT,
                                                    colorHexCode: color.hex.toUpperCase(),
                                                }
                                            },
                                                () => {
                                                    // console.log("New Color:", selectedGT.colorHexCode);
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            </FormItem>

                            <div 
                                style = {{
                                    padding: 5,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: 10
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton 
                                    onClick = {this.submitForm}
                                    loading = {this.props.style.isLoadingSubmit && this.state.isLoading}
                                    disabled = {!(returnIsGTReady("templateName") && returnIsGTReady("colorHexCode"))}
                                >
                                    Edit
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    geofences: state.v2.geofences,
    geofenceTemplate: state.v2.geofenceTemplate,
});

const ConnectedEditRule = connect(mapStateToProps)(EditGeofenceTemplatePage);
export default showSecondarySidebar(false)(ConnectedEditRule)
