import React, { Component } from "react";
import { connect } from "react-redux";

// import moment from "moment";
import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { DEVICE_MODEL } from '../../../../../constants';
import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message,
} from "antd";

// Redux Actions
import { add_new_device } from "../../../services/redux/actions/devices";

const FormItem = Form.Item;

class AddDevice extends Component {
    state = {
        dvid: "",
        simCard: "+6",
        deviceImei: "",
        deviceModel: "",
        deviceBrand: "",
    }

    submitForm = () => {
        this.setState({ isLoading: true })

        const {
            simCard,
            targetUser,
            deviceImei,
            deviceModel,
            deviceBrand,
        } = this.state;

        try {
            if (!deviceImei) throw new Error(`Device Imei cannot be blank`)
            if (!deviceModel) throw new Error(`Device Model cannot be blank`)
            if (this.isDeviceExist(deviceModel, deviceImei)) throw new Error(`Device already exist`)
            if (this.isSimCardExist(simCard)) throw new Error(`Sim Card is already in use`)
            if (!targetUser) throw new Error(`Please select a target user`)

            const newDevice = {
                imei: deviceImei,
                simCard: simCard,
                model: deviceModel,
                brand: deviceBrand,
            }

            const uid = targetUser
            const user = this.props.hierarchy.byId[uid]
            const deviceGroup = user && user.deviceGroups.find(dg=>dg.groupName === 'ALL')

            const dgid = deviceGroup && deviceGroup.dgid

            // console.log({uid, user, dgid, newDevice})

            this.props.dispatch(add_new_device(uid, dgid, newDevice));

        } catch (err) {
            (err.hasOwnProperty('message') && message.error(err.message)) || message.error(err)
        }
    }

    setupFormDefaultValue = () => {
        const targetUser = this.props.router.location.state.targetUser;

        // console.log({ targetUser })

        if (!targetUser) {
            this.setState({ targetUser: this.props.user.uid })
        } else {
            this.setState({ targetUser })
        }
    }

    componentDidMount = () => {
        this.setupFormDefaultValue()
    }

    componentDidUpdate = (prevProps) => {
        if (
            JSON.stringify(this.props.router) !== JSON.stringify(prevProps.router)
            || JSON.stringify(this.props.user) !== JSON.stringify(prevProps.user)
            || JSON.stringify(this.props.hierarchy) !== JSON.stringify(prevProps.hierarchy)
        )
            this.setupFormDefaultValue()
    }

    isSimCardExist = simCard => {
        return Object.values(this.props.devices.byId).find(device => device.simCard === simCard)
    }

    isDeviceExist = (deviceModel, deviceImei) => {
        const dvid = `${deviceModel}#${deviceImei}`
        return this.props.devices.byId[dvid]
    }

    render() {
        
        return (
            <div className = "page-container">
                <Page title = "Add New Device">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Device Imei"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                help = {`*Cannot be changed once created`}
                                required
                            >
                                <Input
                                    placeholder = "IMEI Series No."
                                    value = {this.state.deviceImei}
                                    onChange = {e =>
                                        this.setState({
                                            ...this.state,
                                            deviceImei: e.target.value,
                                        })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label = "Device Model"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                help = {`*Cannot be changed once created`}
                                required
                            >
                                <Select
                                    placeholder = "Select a device model"
                                    onChange = {value =>
                                        this.setState({
                                            ...this.state,
                                            deviceModel: value,
                                        })
                                    }
                                >
                                    {
                                        Object.keys(DEVICE_MODEL).map((currModel) =>
                                            <Select.Option key = {currModel} value = {currModel}>
                                                {currModel}
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label = "Device Brand"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "Select a device brand (Optional)"
                                    value = {this.state.deviceBrand}
                                    onChange = {e => {
                                        this.setState({
                                            ...this.state,
                                            deviceBrand: e.target.value,
                                        })
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                label = "Sim Card"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder = "+60123456789"
                                    value = {this.state.simCard}
                                    onChange = {e => {
                                        if (e.target.value.length < 2) {
                                            this.setState({
                                                ...this.state,
                                                simCard: "+6",
                                            })
                                        }
                                        else {
                                            this.setState({
                                                ...this.state,
                                                simCard: e.target.value,
                                            })
                                        }
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                label = "Target user"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                                help = {`Target user cannot be changed once set`}
                            >
                                <Select
                                    showSearch
                                    value = {this.state.targetUser}
                                    placeholder = "Select a user"
                                    optionFilterProp = "children"
                                    onChange = {targetUser => {
                                        this.setState({ targetUser })
                                    }}
                                    filterOption = {(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        Object.values(this.props.hierarchy.byId)
                                        .filter((user) => user.userRole !== "fleetOwner" && user.userRole !== "fleetOperator") // Don't allow devices to be added to fleet owners and fleet operators
                                        .map(user => {
                                            return <Select.Option
                                                key = {user.uid}
                                                value = {user.uid}
                                            >
                                                {`${user.userName} (${user.userRole})`}
                                            </Select.Option>
                                        })
                                    }
                                </Select>
                            </FormItem>

                            <div style = {{ position: "absolute", right: "100px" }}>
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                    style = {{ marginRight: 10 }}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading = {this.props.style.isLoadingSubmit && this.state.isLoading}
                                    onClick = {this.submitForm}
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    
    user: state.v2.user,
    style: state.v2.style,
    devices: state.v2.devices,
    hierarchy: state.v2.hierarchy,
    deviceGroups: state.v2.deviceGroups,
});

const ConnectedAddDevice = connect(mapStateToProps)(AddDevice);
export default showSecondarySidebar(false)(ConnectedAddDevice)