export const GET_BROWSER_LOC = "GET_BROWSER_LOC";

export const SET_MAP_CONTROL = "SET_MAP_CONTROL"
export const RESET_MAP_LOCATION = "RESET_MAP_LOCATION"
export const SET_MAP_LOCATION = "SET_MAP_LOCATION"
export const SET_MAP_ZOOM = "SET_MAP_ZOOM"

export const CLEAR_GEOFENCE = "CLEAR_GEOFENCE";
export const SET_NEW_GEOFENCE = "SET_NEW_GEOFENCE";

export const SET_SELECTED_GEOFENCE_TEMPLATES = "SET_SELECTED_GEOFENCE_TEMPLATES";
export const CLEAR_SELECTED_GEOFENCE_TEMPLATES = "CLEAR_SELECTED_GEOFENCE_TEMPLATES";

export const ADD_MAP_SHAPE_INTO_LIST = "ADD_MAP_SHAPE_INTO_LIST";
export const CLEAR_MAP_SHAPES_FROM_LIST = "CLEAR_MAP_SHAPES_FROM_LIST";

export const SET_NEW_GEOFENCE_FILTER_STRING = "SET_NEW_GEOFENCE_FILTER_STRING";
export const RESET_GEOFENCE_FILTER_STRING = "RESET_GEOFENCE_FILTER_STRING";

// UI Control Stuff
//================================================================================
export const SET_MAP_FILTERS = "SET_MAP_FILTERS";

export const ADD_ENABLED_VEHICLE_POP_UP = "ADD_ENABLED_VEHICLE_POP_UP";
export const REMOVE_ENABLED_VEHICLE_POP_UP = "REMOVE_ENABLED_VEHICLE_POP_UP";
export const CLEAR_ENABLED_VEHICLE_POP_UP = "CLEAR_ENABLED_VEHICLE_POP_UP";

export const ADD_ENABLED_GEOFENCE_POP_UP = "ADD_ENABLED_GEOFENCE_POP_UP";
export const CLEAR_ENABLED_GEOFENCE_POP_UP = "CLEAR_ENABLED_GEOFENCE_POP_UP";

export const ENABLE_SHOW_VEHICLE_INFO = "ENABLE_SHOW_VEHICLE_INFO";
export const DISABLE_SHOW_VEHICLE_INFO = "DISABLE_SHOW_VEHICLE_INFO";

export const ENABLE_SHOW_GEOFENCE_INFO = "ENABLE_SHOW_GEOFENCE_INFO";
export const DISABLE_SHOW_GEOFENCE_INFO = "DISABLE_SHOW_GEOFENCE_INFO";

export const ENABLE_SHOW_GEOFENCES = "ENABLE_SHOW_GEOFENCES";
export const DISABLE_SHOW_GEOFENCES = "DISABLE_SHOW_GEOFENCES";

export const ENABLE_SHOW_LIVE_TRAFFIC = "ENABLE_SHOW_LIVE_TRAFFIC";
export const DISABLE_SHOW_LIVE_TRAFFIC = "DISABLE_SHOW_LIVE_TRAFFIC";

export const ENABLE_SHOW_VEHICLE_TRAIL = "ENABLE_SHOW_VEHICLE_TRAIL";
export const DISABLE_SHOW_VEHICLE_TRAIL = "DISABLE_SHOW_VEHICLE_TRAIL";

export const ADD_ENABLED_EVENT_POP_UP = "ADD_ENABLED_EVENT_POP_UP";
export const CLEAR_ENABLED_EVENT_POP_UPS = "CLEAR_ENABLED_EVENT_POP_UPS";
export const REMOVE_ENABLED_EVENT_POP_UP = "REMOVE_ENABLED_EVENT_POP_UP";

export const RESET_UI_CONTROL = "RESET_UI_CONTROL";
//================================================================================