import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { 
    moveToPage,
    goBackToPrev,  
} from "../../../navigation/navigationService";
import {
    ROLE_TYPE,
    VEHICLE_TYPE,
} from '../../../../../constants';
import {
    PlusOutlined,
    MinusCircleOutlined,
} from '@ant-design/icons';
import {
    Row,
    Col,
    Form,
    Input,
    Alert,
    Button,
    Select,
    message,
} from "antd";

// Redux Actions
import { select_user } from '../../../services/redux/actions/hierarchy';
import { add_new_vehicle } from "../../../services/redux/actions/vehicles";

// Style
import './index.css';

const FormItem = Form.Item;
const { Option } = Select;

const fuelSensorTypes = [
    "dipstick",
    "ultrasonic",
];

class AddVehiclePage extends Component {
    state = {
        targetUserUid: null,

        // New Vehicle Stuff
        vgids: [],
        gtids: [],
        rtids: [],
        newVehicle: {
            dvid: "",
            vehicleType: "",
            vehicleModel: "",
            vehiclePlate: "",
            vehicleDisplayName: "",
        },

        fuelTanks: [{
            portNumber: 0,
            fuelCapacity: 0,
            fuelLevelMin: 0,
            fuelLevelMax: 10,
            fuelLevelOperator: 'V',
            fuelSensorType: fuelSensorTypes[0],
        }],
    }

    submitForm = () => {
        this.setState({ isLoading: true });

        const {
            fuelTanks,
            newVehicle,
            targetUserUid,
        } = this.state;

        const {
            dvid,
            vehicleType,
            vehicleModel,
            vehiclePlate,
            vehicleDisplayName,
        } = newVehicle;

        try {
            if (!dvid) throw new Error('Please select a device for this vehicle');
            if (!vehicleDisplayName) throw new Error('Please enter a vehicle name');
            if (!vehiclePlate) throw new Error('Please enter vehicle plate');
            if (!vehicleModel) throw new Error(`Please enter a vehicle model`);
            if (!vehicleType) throw new Error(`Please select vehicle type`);
            if (!targetUserUid) throw new Error(`Please select target user`);

            fuelTankSanityCheck(fuelTanks);

            const {
                newVgids,
                newGtids,
                newRtids,
            } = this.getGroups(targetUserUid);

            const {
                uid,
                userRole,
            } = this.props.hierarchy.byId[targetUserUid];

            const targetUser = {
                uid,
                userRole,
            }

            /**Build fuel profile */
            newVehicle.fuelProfile = {};

            fuelTanks.map((tank) => {
                const key = `fuel${(tank.portNumber > 0 && tank.portNumber) || ''}`;

                newVehicle.fuelProfile[key] = tank;
                delete tank.portNumber;

                return null;
            })

            // console.log({ newVehicle, targetUser, newVgids, newGtids, newRtids })'

            this.props.dispatch(add_new_vehicle(newVehicle, targetUser, newVgids, newGtids, newRtids));
        } 
        catch (err) {
            message.error(err.message)
        }

        function fuelTankSanityCheck(fuelTanks) {
            // port number must be unique
            let tempPortNumbers = [];

            fuelTanks.forEach(tank => {
                // check invalidity
                ['portNumber', 'fuelCapacity', 'fuelLevelMin', 'fuelLevelMax'].map(key => {
                    if (tank[key] === null || tank[key] === undefined || isNaN(tank[key])) {
                        throw new Error('Invalid fuel tank information: Make sure fields are filled in correctly')
                    }

                    return null;
                })

                if (!tempPortNumbers.includes(tank.portNumber)) {
                    tempPortNumbers.push(tank.portNumber)
                } 
                else {
                    throw new Error('Invalid fuel tank information: Port numbers cannot be shared')
                }

                if (!fuelSensorTypes.includes(tank.fuelSensorType)) {
                    throw new Error('Invalid fuel tank information: Each fuel tank must have a sensor type');
                }
            })
        }
    }

    /**
     * Build newVgids, newGtids, newRtids based on
     * 1. targetUser's default groups/templates
     * 2. any additional groups/templates selected in form
     * 3. (special case if current user is distributor) current user's default vehicle group
     */
    getGroups = (uid) => {
        const {
            vgids = [],
            gtids = [],
            rtids = [],
        } = this.state;

        const {
            vehicleGroups = [],
            ruleTemplates = [],
            geofenceTemplates = [],
        } = this.props.hierarchy.byId[uid];

        const targetUserDefaultVehicleGroup = vehicleGroups.find(vg => vg.groupName === 'ALL')
        const targetUserDefaultVgid = targetUserDefaultVehicleGroup && targetUserDefaultVehicleGroup.vgid
        const targetUserDefaultGeofenceTemplate = geofenceTemplates.find(gt => gt.templateName === 'UNGROUP')
        const targetUserDefaultGtid = targetUserDefaultGeofenceTemplate && targetUserDefaultGeofenceTemplate.gtid
        const targetUserDefaultRulesTemplate = ruleTemplates.find(rt => rt.templateName === 'DEFAULT')
        const targetUserDefaultRtid = targetUserDefaultRulesTemplate && targetUserDefaultRulesTemplate.rtid

        let newVgids = [...vgids, targetUserDefaultVgid]
        let newGtids = [...gtids, targetUserDefaultGtid]
        let newRtids = [...rtids, targetUserDefaultRtid]

        /**
         * If current user is distributor, created vehicle would be added in current user's default vehicle group as well
         */
        switch (this.props.user.userRole) {
            case ROLE_TYPE.DISTRIBUTOR:
                const defaultVehicleGroup = Object.values(this.props.vehicleGroups.byVGID).find(vg => vg.groupName === 'ALL')
                const defaultVgid = defaultVehicleGroup && defaultVehicleGroup.vgid
                newVgids.push(defaultVgid)
                break
            default:
                break
        }

        return {
            newVgids,
            newGtids,
            newRtids,
        }
    }

    setUpFormDefaultValues = () => {
        const { uid } = this.props.router.location.state || {};

        this.setState({
            targetUserUid: uid,
        })
    }

    addFuelTank = () => {
        const sampleFuelTank = {
            portNumber: 0,
            fuelCapacity: 0,
            fuelLevelMin: 0,
            fuelLevelMax: 10,
            fuelLevelOperator: 'V',
            fuelSensorType: fuelSensorTypes[0],
        }

        const newFuelTanks = [...this.state.fuelTanks]
        newFuelTanks.push(sampleFuelTank)

        this.setState({ fuelTanks: newFuelTanks })
    }

    removeFuelTank = (id) => {
        this.state.fuelTanks.splice(id, 1)

        this.setState({ fuelTanks: this.state.fuelTanks })
    }

    fuelInfoForm = (index) => {
        const fuelTank = this.state.fuelTanks[index];
        const isLatest = index === this.state.fuelTanks.length - 1;

        return (
            <FormItem
                label = {`Tank ${index + 1}`}
                labelCol = {{ span: 6 }}
                wrapperCol = {{ span: 18 }}
            >
                Port Number
                <Select
                    defaultValue = {fuelTank.portNumber}
                    value = {fuelTank.portNumber}
                    onChange = {v => {
                        fuelTank.portNumber = v
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                >
                    {
                        Array.from(Array(this.state.fuelTanks.length).keys()).map(number => {
                            return (
                                <Option key = {number} value = {number}>
                                    {number}
                                </Option>
                            );
                        })
                    }
                </Select>

                Fuel Capacity (L)
                <Input
                    defaultValue = {fuelTank.fuelCapacity.toString()}
                    value = {fuelTank.fuelCapacity.toString()}
                    onBlur = {e => {
                        isNaN(Number(e.target.value)) && message.error('Must be a number')
                    }}
                    onFocus = {e => e.target.select()}
                    onChange = {e => {
                        const value = e.target.value.replace(" ", "")
                        fuelTank.fuelCapacity = isNaN(Number(value)) ? value : Number(value)
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                />

                Fuel Maximum Level
                <Input
                    defaultValue = {fuelTank.fuelLevelMax.toString()}
                    value = {fuelTank.fuelLevelMax.toString()}
                    onBlur = {e => {
                        isNaN(Number(e.target.value)) && message.error('Must be a number')
                    }}
                    onFocus = {e => e.target.select()}
                    onChange = {e => {
                        const value = e.target.value.replace(" ", "")
                        fuelTank.fuelLevelMax = isNaN(Number(value)) ? value : Number(value)
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                />

                Fuel Minimum Level
                <Input
                    defaultValue = {fuelTank.fuelLevelMin.toString()}
                    value = {fuelTank.fuelLevelMin.toString()}
                    onBlur = {e => {
                        isNaN(Number(e.target.value)) && message.error('Must be a number')
                    }}
                    onFocus = {e => e.target.select()}
                    onChange = {e => {
                        const value = e.target.value.replace(" ", "")
                        fuelTank.fuelLevelMin = isNaN(Number(value)) ? value : Number(value)
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                />

                Fuel Level Operator
                <Select
                    defaultValue = {fuelTank.fuelLevelOperator}
                    value = {fuelTank.fuelLevelOperator}
                    placeholder = "% or V"
                    onChange = {v => {
                        fuelTank.fuelLevelOperator = v
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                >
                    {
                        ['%', 'V'].map(operator => {
                            return (
                                <Option key = {operator} value = {operator}>
                                    {operator}
                                </Option>
                            );
                        })
                    }
                </Select>

                Fuel Sensor Type
                <Select
                    defaultValue = {fuelTank.fuelSensorType}
                    value = {fuelTank.fuelSensorType}
                    placeholder = "% or V"
                    onChange = {v => {
                        fuelTank.fuelSensorType = v;

                        this.setState({ fuelTanks: this.state.fuelTanks });
                    }}
                >
                    {
                        fuelSensorTypes.map(type => {
                            return (
                                <Option key = {type} value = {type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </Option>
                            );
                        })
                    }
                </Select>

                <div style = {{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    {
                        isLatest &&
                            <Button
                                ghost
                                disabled = {this.state.fuelTanks.length <= 1}
                                type = "dashed"
                                onClick = {() => this.removeFuelTank(index)}
                                style = {{
                                    width: isLatest ? '48%' : '100%',
                                    borderColor: 'red',
                                    color: 'red'
                                }}
                            >
                                <MinusCircleOutlined /> Remove
                            </Button>
                    }

                    {
                        isLatest &&
                            <Button
                                type = "dashed"
                                onClick = {() => this.addFuelTank()}
                                style = {{
                                    width: isLatest ? '48%' : '100%'
                                }}
                            >
                                <PlusOutlined /> Add
                            </Button>
                    }
                </div>
            </FormItem>
        )
    }

    componentDidMount = () => {
        this.setUpFormDefaultValues()
    }

    componentDidUpdate = prevProps => {
        if (
            JSON.stringify(this.props.vehicles.byId) !== JSON.stringify(prevProps.vehicles.byId)
            || JSON.stringify(this.props.devices.byId) !== JSON.stringify(prevProps.devices.byId)
        ) {
            this.setUpFormDefaultValues()
        }

        if (
            JSON.stringify(this.props.router.location) !== JSON.stringify(prevProps.router.location)
            || JSON.stringify(this.props.hierarchy) !== JSON.stringify(prevProps.hierarchy)
        ) {
            const { uid } = this.props.router.location.state || {}

            const user = this.props.hierarchy.byId[uid]

            // console.log(user);

            if (!user) return

            this.props.dispatch(select_user(user));
        }
    }

    render() {
        const {
            newVehicle,
            targetUserUid,
        } = this.state;

        const isNoUnassignedDevice = Object.values(this.props.devices.byId)
            .filter(device => {
                return !Object.values(this.props.vehicles.byId).find(vehicle => vehicle.dvid === device.dvid)
            })
            .length === 0

        /**Get vgids, gtids, rtids of target user */
        const userObject = this.props.hierarchy.byId[targetUserUid];

        // console.log(userObject);

        let ruleTemplates = (userObject && userObject.ruleTemplates) || [];
        let vehicleGroups = (userObject && userObject.vehicleGroups) || [];
        let geofenceTemplates = (userObject && userObject.geofenceTemplates) || [];

        // console.log({ vehicleGroups, geofenceTemplates, ruleTemplates });

        window.props = this.props;

        return (
            <div className = "page-container">
                <Page title = "Add New Vehicle">
                    {
                        isNoUnassignedDevice && (
                            <Alert
                                banner
                                closable
                                message = {<p>All devices are already assigned to a vehicle. {<p className = 'ButtonLink' onClick = {() => this.props.dispatch(moveToPage('/UserManagement/AddDevice'))}> Create a device to proceed </p>}</p>}
                            />
                        )
                    }

                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Vehicle Name"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder = "Car 1"
                                    value = {newVehicle.vehicleDisplayName}
                                    onFocus = {e => e.target.select()}
                                    onChange = {e => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            vehicleDisplayName: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label = "Vehicle Plate"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder = "ABC123"
                                    value = {newVehicle.vehiclePlate}
                                    onFocus = {e => e.target.select()}
                                    onChange = {e => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            vehiclePlate: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label = "Vehicle Model"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder = "Toyota Vios"
                                    value = {newVehicle.vehicleModel}
                                    onFocus = {e => e.target.select()}
                                    onChange = {e => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            vehicleModel: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label = "Vehicle Type"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                            >
                                <Select
                                    placeholder = "Select a vehicle type"
                                    onChange = {value => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            vehicleType: value,
                                        }
                                    })}
                                >
                                    {
                                        VEHICLE_TYPE.map(vehicleType => {
                                            return (
                                                <Option key = {vehicleType.value} value = {vehicleType.value}>
                                                    {vehicleType.label}
                                                </Option>
                                            );
                                        })
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label = "Vehicle Fuel Information"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Row>
                                    {
                                        this.state.fuelTanks.map((fuelTank, i) => {
                                            return (
                                                <Col span = {13} key = {i}>
                                                    {this.fuelInfoForm(i)}
                                                </Col>
                                            )
                                        })
                                    }

                                </Row>
                            </FormItem>

                            {/* This should be a list of all devices under currently logged in user */}
                            <FormItem
                                label = "Device"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                                help = {`Cannot be edited once set`}
                            >
                                <Select
                                    showSearch
                                    disabled = {!Object.values(this.props.devices.byId).length}
                                    optionFilterProp = "children"
                                    placeholder = {`Select a device`}
                                    filterOption = {(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange = {dvid =>
                                        this.setState({
                                            ...this.state,
                                            newVehicle: {
                                                ...newVehicle,
                                                dvid: dvid,
                                            }
                                        })
                                    }
                                >
                                    {
                                        Object.values(this.props.devices.byId)
                                            .sort((a, b) => {
                                                const dA = a.dvid.toLowerCase();
                                                const dB = b.dvid.toLowerCase();

                                                if (dA < dB) return -1;
                                                if (dA > dB) return 1;
                                                return 0;
                                            })
                                            .map(device => {
                                                const vehicle = Object.values(this.props.vehicles.byId).find(vehicle => vehicle.dvid === device.dvid);

                                                return <Option
                                                    key = {device.dvid}
                                                    value = {device.dvid}
                                                    disabled = {vehicle}
                                                >
                                                    {`${device.dvid} ${(vehicle && `(${vehicle.vehicleDisplayName})`) || ''}`}
                                                </Option>;
                                            })
                                    }
                                </Select>
                            </FormItem>

                            {/* 
                                Not allowed to select fleet operator as target user.
                                To assign fleet operator a vehicle,
                                1. log in as fleet owner
                                2. go to user management
                                3. click on designated fleet operator
                                4. configure user
                            */}
                            <FormItem
                                label = "Target User"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                            >
                                {
                                    this.props.user && `${this.props.user.userName} (${this.props.user.userRole})`
                                }
                            </FormItem>

                            {
                                targetUserUid && (
                                    <div style = {{ marginTop: 20 }}>
                                        {/* To decide which vehicle group of the target user this vehicle should be assigned to */}
                                        <FormItem
                                            label = "Vehicle Groups"
                                            labelCol = {{ span: 7 }}
                                            wrapperCol = {{ span: 15 }}
                                            help = {targetUserUid ? '' : 'Select a target user first'}
                                        >
                                            {
                                                <Select
                                                    showSearch
                                                    mode = "multiple"
                                                    placeholder = "Select vehicle groups to assign vehicle to (Optional)"
                                                    value = {this.state.vgids}
                                                    style = {{ width: '100%' }}
                                                    onChange = {(value) => {
                                                        // console.log({ value })
                                                        this.setState({
                                                            ...this.state,
                                                            vgids: value,
                                                        })
                                                    }}
                                                >
                                                    {
                                                        vehicleGroups
                                                            .filter(vg => vg.groupName !== 'ALL')
                                                            .map(vg => {
                                                                return <Option key = {vg.vgid}>{vg.groupName}</Option>
                                                            })
                                                    }
                                                </Select>
                                            }
                                        </FormItem>

                                        {/* Important for rule engine. */}
                                        <FormItem
                                            label = "Geofence Templates"
                                            labelCol = {{ span: 7 }}
                                            wrapperCol = {{ span: 15 }}
                                            help = {targetUserUid ? '' : 'Select a target user first'}
                                        >
                                            {
                                                <Select
                                                    showSearch
                                                    mode = "multiple"
                                                    placeholder = "Select geofence templates to assign vehicle to (Optional)"
                                                    value = {this.state.gtids}
                                                    style = {{ width: '100%' }}
                                                    onChange = {(gtids) => {
                                                        // console.log({ gtids })
                                                        this.setState({
                                                            ...this.state,
                                                            gtids: gtids,
                                                        })
                                                    }}
                                                >
                                                    {
                                                        geofenceTemplates
                                                            .filter(gt => gt.templateName !== 'UNGROUP')
                                                            .map(gt => {
                                                                return <Option key = {gt.gtid}>{gt.templateName}</Option>
                                                            })
                                                    }
                                                </Select>
                                            }
                                        </FormItem>

                                        {/* Important for rule engine. */}
                                        <FormItem
                                            label = "Rule Templates"
                                            labelCol = {{ span: 7 }}
                                            wrapperCol = {{ span: 15 }}
                                            help = {targetUserUid ? '' : 'Select a target user first'}
                                        >
                                            {
                                                <Select
                                                    showSearch
                                                    mode = "multiple"
                                                    placeholder = "Select rule templates to assign vehicle to (Optional)"
                                                    value = {this.state.rtids}
                                                    style = {{ width: '100%' }}
                                                    onChange = {(rtids) => {
                                                        // console.log(rtids)
                                                        this.setState({
                                                            ...this.state,
                                                            rtids: rtids,
                                                        })
                                                    }}
                                                >
                                                    {
                                                        ruleTemplates
                                                            .filter(rt => rt.templateName !== 'DEFAULT')
                                                            .map(rt => {
                                                                return <Option key = {rt.rtid}>{rt.templateName}</Option>
                                                            })
                                                    }
                                                </Select>
                                            }
                                        </FormItem>
                                    </div>
                                )
                            }

                            <div
                                style = {{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    onClick = {this.submitForm}
                                    loading = {this.props.style.isLoadingSubmit && this.state.isLoading}
                                    style = {{ marginLeft: 10 }}
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.v2.user,
    style: state.v2.style,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    hierarchy: state.v2.hierarchy,
    ruleTemplate: state.v2.ruleTemplate,
    vehicleGroups: state.v2.vehicleGroups,
    geofenceTemplate: state.v2.geofenceTemplate,
});

const ConnectedAddCustomerVehicle = connect(mapStateToProps)(AddVehiclePage);
export default showSecondarySidebar(false)(ConnectedAddCustomerVehicle);