import React, { useState, useEffect } from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';

import EventMarkers from "./components/EventMarkers";
import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { LOCATION } from '../../../../constants';
import { GoogleMap } from '@react-google-maps/api';

// Redux Actions
import { set_map_control } from '../../services/redux/actions/mapControl';
import { clear_selected_device } from "../../services/redux/actions/devices";
import { clear_enabled_vehicle_pop_ups } from "../../services/redux/actions/mapControl";

import "./gmaps.css";

const GoogleMapsComponentEvent = (props) => {
    // State
    const [mapRef, setMapRef] = useState(null);

    // Redux Store
    const style = useSelector(state => state.style);
    const events = useSelector(state => state.v2.events);
    const vehicles = useSelector(state => state.v2.vehicles);

    const dispatch = useDispatch();

    // Initial mount of component
    useEffect(() => {
        // console.log('new google maps component onMount');

        dispatch(clear_selected_device());
        dispatch(clear_enabled_vehicle_pop_ups());
    }, 
        [dispatch]
    )

    /**
     * If map did not set location to geofence/vehicles within 5 seconds, (a sign of a new account)
     * default location & zoom would be set instead
     */
    useEffect(() => {
        if (!mapRef) return

        setTimeout(() => {
            if (!mapRef.center) {
                // console.log(`[GoogleMapsComponent] Auto setting map location`)
                mapRef.panTo(LOCATION)
                mapRef.setZoom(7)
            }
        }, 5 * 1000)
    })

    /**
     * If map did not set location to geofence/vehicles within 5 seconds, (a sign of a new account)
     * default location & zoom would be set instead
     */
    useEffect(() => {
        if (!mapRef) return

        setTimeout(() => {
            if (!mapRef.center) {
                // console.log(`[GoogleMapsComponentEvent] Auto setting map location`);

                mapRef.panTo(LOCATION)
                mapRef.setZoom(7)
            }
        }, 
            5 * 1000
        )
    })

    /**
     * If map hasn't set a location yet,
     * Pan to all events' circumference location
     * Else if map already has a location, do nothing
     */
    useEffect(() => {
        if (!mapRef) return
        if (mapRef.center) return
        if (style.isLoadingSubmit) return

        if (vehicles.selectedId && events.byId[vehicles.selectedId]) {
            const allLocations = 
                Object.values(events.byId[vehicles.selectedId])
                .filter((currEvent) => currEvent.location && currEvent.location.lat && currEvent.location.lng) // Filter out events with no locations
                .map((currEvent) => {
                    const location = currEvent.location;
                    
                    return location && {
                        lat: parseFloat(location.lat),
                        lng: parseFloat(location.lon)
                    }
                })
    
            if (allLocations.length) {
                const bounds = new window.google.maps.LatLngBounds();
    
                allLocations.map(c => {
                    bounds.extend(c)
                    return null;
                })
    
                // console.log(`[GoogleMapsComponentEvent] Setting map location by vehicles & geofences`);
    
                mapRef.fitBounds(bounds, [10]);
            }
        }
    }, 
        [style, mapRef, events, vehicles]
    )

    return (
        <GoogleMap
            id = 'example-map'
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
            onDragStart = {() => {
                dispatch(set_map_control(0))
            }}
        >
            <EventMarkers mapRef = {mapRef}/>
        </GoogleMap>
    )
}

const GoogleMapsComponentWithLoadScript = (props) => GoogleMapsLoadScript(GoogleMapsComponentEvent, props);

export default GoogleMapsComponentWithLoadScript;