import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import {
    Axis,
    Geom,
    Chart,
    Legend,
    Tooltip,
    // Slider
} from "bizcharts";

import DataSet from "@antv/data-set";

class FuelAnalysisTimelineChart extends React.Component {
    static propTypes = {
        height: PropTypes.number,
        borderWidth: PropTypes.number,
        padding: PropTypes.arrayOf(PropTypes.number),
    };

    static defaultProps = {
        height: 400,
        borderWidth: 2,
        padding: [60, 120, 40, 120],
    };

    state = {
        title: "Speed and Fuel Graph"
    }

    render() {
        const { title } = this.state;
        const {
            vid,
            dvid,
            height,
            padding,
            borderWidth,
            deviceFuelRecordLog,
            vehicles,
        } = this.props;

        

        const titleMap = {
            y1: "Speed",
            y2: "Fuel (L)",
        };

        const thisVehicle = vehicles.byId[vid]
        // TODO: temp fix by assuming all vehicle has only one tank
        const thisFuelTankCapacity = thisVehicle.fuelProfile['fuel'] && thisVehicle.fuelProfile['fuel'].fuelCapacity / 100

        const data =
            (
                dvid && deviceFuelRecordLog.byDVID.hasOwnProperty(dvid) &&
                deviceFuelRecordLog.byDVID[dvid].map(g => ({
                    x: g.deviceTime ? g.deviceTime : 0,
                    y1: g.speed ? g.speed : 0,
                    y2: g.fuel && thisFuelTankCapacity ? g.fuel * thisFuelTankCapacity : 0
                }))
            )

            ||
            []

        data.sort((a, b) => a.x - b.x);

        let max;

        if (data[0] && data[0].y1 && data[0].y2) {
            max = Math.max(
                [...data].sort((a, b) => b.y1 - a.y1)[0].y1,
                [...data].sort((a, b) => b.y2 - a.y2)[0].y2
            );
        }

        const ds = new DataSet({
            state: {
                start: data[0] ? data[0].x : 0,
                end: data[0]
                    ? data[data.length - 1].x
                    : 0,
            },
        });

        let dv = ds.createView();

        dv.source(data)
            .transform({
                type: "filter",
                callback: obj => {
                    const date = obj.x;
                    return date <= ds.state.end && date >= ds.state.start;
                },
            })
            .transform({
                type: "map",
                callback(row) {
                    const newRow = { ...row };
                    newRow[titleMap.y1] = row.y1;
                    newRow[titleMap.y2] = row.y2;
                    return newRow;
                },
            })
            .transform({
                type: "fold",
                fields: [titleMap.y1, titleMap.y2], // 展开字段集
                key: "key", // key字段
                value: "value", // value字段
            });

        const timeScale = {
            type: "time",
            mask: "YY-MM-DD HH:mm",
            range: [0, 1],
        };

        const cols = {
            x: timeScale,
            value: {
                max,
                min: 0,
            },
        };

        
        return (
            <div style={{ height: height + 30 }}>
                <div>
                    {title && <h4 style={{ textAlign: "center" }}>{title}</h4>}

                    <Chart
                        data={dv}
                        scale={cols}
                        height={height}
                        padding={padding}
                        autoFit
                        // forceFit
                    >
                        <Axis name="x" />

                        <Tooltip />

                        <Legend name="key" position="top" />

                        <Geom type="area" position="x*value" color="key" shape="smooth" />

                        <Geom
                            type="line"
                            position="x*value"
                            size={borderWidth}
                            color="key"
                        />
                        {/* <div style={{ marginRight: -20, marginTop: 20 }}>
                            <Slider
                                width="auto"
                                height={50}
                                xAxis="x"
                                yAxis="y1"
    
                                scales={{ x: timeScale }}
                                data={data}
                                start={ds.state.start}
                                end={ds.state.end}
                                backgroundChart={{ type: "line" }}
                                // padding={[0, padding[1] + 20, 0, padding[3]]}
    
                                onChange={({ startValue, endValue }) => {
                                    ds.setState("start", startValue);
                                    ds.setState("end", endValue);
                                }}
                            />
                        </div> */}
                    </Chart>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    deviceFuelRecordLog: state.v2.fuelRecord.fuelRecordDeviceLog,
    vehicles: state.v2.vehicles,
});

export default connect(mapStateToProps)(FuelAnalysisTimelineChart);