import thunk from "redux-thunk";
// import logger from 'redux-logger';

import * as actionType from './containers/v2/services/redux/action-types/user';

import { routerMiddleware } from 'connected-react-router';
import { 
    compose, 
    createStore,
    applyMiddleware, 
} from 'redux';

import { createRootReducer }from './services/redux/reducers';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createBrowserHistory()

const resetEnhancer = rootReducer => (state, action) => {
    if (action.type === actionType.USER_SIGN_OUT) state = undefined;

    return rootReducer(state, action);
};

export default function configureStore(preloadedState) {
    const store = createStore(
        resetEnhancer(createRootReducer(history)), // root reducer with router state
        preloadedState,
        compose(
            composeWithDevTools(
                applyMiddleware(
                    routerMiddleware(history), // for dispatching history actions
                    thunk,
                    // logger,
                    // ... other middlewares ...
                ),
            ),
        ),
    )
   
    return store;
}