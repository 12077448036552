import * as actionType from "../action-types/geofenceTemplate";

const defaultState = {
    allIds: [],

    byId: {},
    byName:{},

    tempNewGTID: "", // Is set when add new GT.
};

export const geofenceTemplate = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    
    switch(action.type) {
        case actionType.GET_GEOFENCE_TEMPLATE: {
            if (action.geofenceTemplates && action.geofenceTemplates.length > 0) {
                action.geofenceTemplates.forEach(currTemplate => {
                    if (currTemplate && currTemplate.templateName) {
                        newState.byName[currTemplate.templateName] = currTemplate;
                    }

                    if (!newState.allIds.includes(currTemplate.gtid)) {
                        newState.allIds.push(currTemplate.gtid);
                    }

                    newState.byId[currTemplate.gtid] = currTemplate;
                })
            }
            
            return newState;
        }

        case actionType.ADD_GEOFENCE_TEMPLATE: {
            if (action.geofenceTemplate && action.geofenceTemplate.templateName) {
                newState.byName[action.geofenceTemplate.templateName] = action.geofenceTemplate;
            }

            if (!newState.allIds.includes(action.geofenceTemplate.gtid)) {
                newState.allIds.push(action.geofenceTemplate.gtid);
            }

            newState.byId[action.geofenceTemplate.gtid] = action.geofenceTemplate;
            
            newState.tempNewGTID = action.geofenceTemplate.gtid;

            return newState;
        }

        case actionType.EDIT_GEOFENCE_TEMPLATE: {
            if (action.geofenceTemplate && action.geofenceTemplate.templateName) {
                newState.byName[action.geofenceTemplate.templateName] = action.geofenceTemplate;
            }

            if (!newState.allIds.includes(action.geofenceTemplate.gtid)) {
                newState.allIds.push(action.geofenceTemplate.gtid);
            }

            newState.byId[action.geofenceTemplate.gtid] = action.geofenceTemplate;
            
            // newState.tempNewGTID = action.geofenceTemplate.gtid;

            return newState;
        }

        case actionType.REASSIGN_GEOFENCE_TEMPLATES: {
            if (action.geofenceTemplates && action.geofenceTemplates.length > 0) {
                action.geofenceTemplates.forEach(currTemplate => {
                    if (currTemplate && currTemplate.templateName) {
                        newState.byName[currTemplate.templateName] = currTemplate;
                    }

                    newState.byId[currTemplate.gtid] = currTemplate;
                })
            }

            return newState;
        }

        case actionType.ASSIGN_GEOFENCES_TO_GEOFENCE_TEMPLATE: {
            newState.byId[action.geofenceTemplate.gtid].geofences = action.geofenceTemplate.geofences;
            
            return newState;
        }

        case actionType.UNASSIGN_GEOFENCES_FROM_GEOFENCE_TEMPLATE: {
            newState.byId[action.geofenceTemplate.gtid].geofences = action.geofenceTemplate.geofences;
            
            return newState;
        }

        case actionType.UPDATE_GEOFENCE_TEMPLATE_GEOFENCES: {
            action.selectedGTIDList.forEach((currGTID) => {
                if (~newState.byId[currGTID].geofences.includes(action.newGeoID)) {
                    newState.byId[currGTID].geofences.push(action.newGeoID);
                }
            })
            
            return newState;
        }
        
        case actionType.CLEAR_TEMP_NEW_GTID: {
            return {
                ...newState,
                tempNewGTID: "",
            };
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Geofence Template Store:", defaultState);

            return defaultState;
        }

        default: {
            return state;
        }
    }
}
