import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import Page from '../../components/Page';
// import parseAddress from '../../util/parseAddress';
import ExportExcelButton from '../../components/ExportExcelButton';
import TableColumnSearchProps from '../../components/TableColumnSearchProps';
import showSecondarySidebar from '../../components/Layout/helpers/showSecondarySidebar';

import { EVENT_TYPES } from '../../../../constants';
import { PrimaryButton } from '../../components/Button';
import { 
    parseDate, 
    parseDuration,
} from '../../util/time';
import {
    Table,
    Select,
    message,
    DatePicker,
} from 'antd';

// Important Stuff
import TableTypes from "./TableTypes";
import SearchFields from "./SearchFields";
import AdvancedFilters from "./AdvancedFilters";
import AdvancedFilterModal from "./AdvancedFilterModal";
import returnNewDataSource from "./ReturnNewDataSource";
import returnFilteredDataSource from "./ReturnFilteredDataSource";

// APIs
import {
    reportTrip,
    reportEvent,
    reportDetail,
    reportMileage,
    reportTransit,
    reportVehicle,
    reportGeofence,
} from '../../services/api/vehicleReport';

// Redux Actions
import {
    setLoadingSubmit, 
    unsetLoadingSubmit
} from '../../services/redux/actions/style';

import "./index.css";

const defaultFilter = "All";

class VehicleReportPage extends React.Component {
    state = {
        dataSource: [],
        filteredDataSource: [],

        // Search Stuff
        selectedVIDs: [],
        // selectedGeoIDs: [],
        startTime: moment().startOf('day'),
        endTime: moment().add(1, 'day').startOf('day'),
        currTable: Object.keys(TableTypes)[0], // Default table
        tempTable: Object.keys(TableTypes)[0], // Just so table columns won't switch automatically

        // Advanced Filter Stuff
        selectedDuration: 1, // Default is > 1 mins
        selectedEventTypes: [defaultFilter],
        selectedGeofenceFilters: [defaultFilter],

        // Modal Stuff
        isVisible: false,
    }

    // Immediately pass new selected IDs because there are different filters for DETAILS table
    setSelectedVIDs = (newSelectedVIDs) => {
        this.setState({
            ...this.state,
            selectedVIDs: newSelectedVIDs
        },
            // () => console.log("New Selected VIDs:", this.state.selectedVIDs)
        );
    }

    // setSelectedGeoIDs = (newSelectedGeoIDs) => {
    //     this.setState({
    //         ...this.state,
    //         selectedGeoIDs: newSelectedGeoIDs
    //     },
    //         // () => console.log("New Selected GeoIDs:", this.state.newSelectedGeoIDs)
    //     );
    // }

    filterDataSource = () => {
        this.setState({
            filteredDataSource: returnFilteredDataSource(defaultFilter, this.state),
        })
    }

    setEventFilter = (newEventTypes) => {
        this.setState({ 
            selectedEventTypes: newEventTypes,
        },
            () => this.filterDataSource()
        );
    }

    setDurationFilter = (newDuration) => {
        this.setState({ 
            selectedDuration: newDuration,
        },
            () => this.filterDataSource()
        );
    }

    setGeofenceFilter = (newGeofenceFilters) => {
        this.setState({
            selectedGeofenceFilters: newGeofenceFilters,
        },
            () => this.filterDataSource()
        );
    }

    returnGetReportAPI = async () => {
        const { 
            selectedVIDs,
            // selectedGeoIDs, 
        } = this.state;

        const properEndTime = this.state.endTime.valueOf();
        const properStartTime = this.state.startTime.valueOf();

        switch(this.state.currTable) {
            case "TRIP": return await reportTrip(selectedVIDs, properStartTime, properEndTime);
            case "EVENT": return await reportEvent(selectedVIDs, properStartTime, properEndTime);
            case "MILEAGE": return await reportMileage(selectedVIDs, properStartTime, properEndTime);
            case "TRANSIT": return await reportTransit(selectedVIDs, properStartTime, properEndTime);
            case "VEHICLE": return await reportVehicle(selectedVIDs, properStartTime, properEndTime);
            case "DETAIL": return await reportDetail(selectedVIDs.map((currVID) => this.props.vehicles.byId[currVID].dvid), properStartTime, properEndTime);
            case "GEOFENCE": {
                return await reportGeofence(
                    selectedVIDs, 
                    Object.values(this.props.geofences.byId)
                    .filter((currGeofence) => currGeofence.geofenceName)
                    .sort((a, b) => {
                        const gA = a.geofenceName.toLowerCase();
                        const gB = b.geofenceName.toLowerCase();
                
                        if (gA < gB) return -1;
                        if (gA > gB) return 1;
                        return 0;
                    })
                    .map((currGeofence) => currGeofence.geoid), 
                    properStartTime, 
                    properEndTime
                );
            }
            default: return null
        }
    }

    submitForm = () => {
        const { currTable } = this.state;
        
        if (this.state.startTime.valueOf() < this.state.endTime.valueOf()) {
            this.setState({
                isVisible: false,
                
                dataSource: [],
                filteredDataSource: [],
                currTable: this.state.tempTable, // Officially set currTable as selected table
            },
                () => {
                    this.props.dispatch(setLoadingSubmit());
                    
                    this.returnGetReportAPI()
                    .then((data) => {
                        // console.log("Get Report Data:", this.state.currTable, data);
    
                        if (data.status !== 200 && data.message) {
                            message.error(`Get ${TableTypes[currTable].title} Report Error: ${data.message}`);
                        }
                        else {
                            const newDataSource = returnNewDataSource(this.state.currTable, this.props.vehicles, this.props.geofences, this.props.geofenceTemplates, data);
    
                            this.setState({
                                dataSource: newDataSource,
                            },
                                () => this.filterDataSource()
                            )
                        }
                    })
                    .finally(() => {
                        this.props.dispatch(unsetLoadingSubmit());
                    })
                }
            )
        }
        else {
            message.error("Start time must be before end time!");
        }
    }

    returnFormattedColumns = () => {
        const sortableKeys = [
            "fuel",
            "speed",
            "heading",
            "endTime",
            "topSpeed",
            "fuelUsed",
            "duration",
            "startTime",
            "createdAt",
            "deviceTime",  
            "totalEvents",
            "totalMileage", 
            "numberOfTrips",
            "totalEngineDuration", 
            "totalMovingDuration", 
            "totalIdlingDuration", 
            "totalParkingDuration"
        ];

        const searchableKeys = [
            "vehicle",
            "deviceIMEI",
            "geofenceName",
            "geofenceTemplates",
        ];

        const filterableKeys = [
            "eventType",
            "gpsStatus",
            "tripStatus",
            "engineStatus",
            "devicePackage",
            "transitStatus",
        ];

        const newColumns = 
            TableTypes[this.state.currTable].columns
            .map((currColumn) => {
                if (sortableKeys.includes(currColumn.key)) {
                    return {
                        ...currColumn,
                        sortDirections: ["ascend", "descend"],
                        sorter: (a, b) => {
                            let A = a[currColumn.key];
                            let B = b[currColumn.key];

                            // In case sorting numbers
                            if (!isNaN(Number(A)) && !isNaN(Number(B))) {
                                A = parseFloat(A);
                                B = parseFloat(B);
                            }

                            // console.log("Test:", A, B);

                            if (A === "-" || isNaN(A) || A < B) return -1;
                            if (B === "-" || isNaN(B) || A > B) return 1;
                            return 0;
                        }
                    }
                }
                else if (searchableKeys.includes(currColumn.key)) {
                    return {
                        ...currColumn,
                        ...TableColumnSearchProps(
                            currColumn.key,
                            this.state.searchText, 
                            (newSearchText) => {
                                this.setState({
                                    searchText: newSearchText,
                                }) 
                            }, 
                            () => {
                                this.setState({
                                    searchText: "",
                                }) 
                            }, 
                        ),
                    }
                }
                else if (filterableKeys.includes(currColumn.key)) {
                    const returnFilterObjects = (stringArr) => {
                        return stringArr.map((currString) => ({
                            text: currString, 
                            value: currString
                        }))
                    }

                    // Cases must include/match all filterable keys
                    const returnFilters = () => {
                        switch(currColumn.key) {
                            case "tripStatus": {
                                return returnFilterObjects(["TRANSIT", "PARKING"]);
                            }

                            case "transitStatus": {
                                return returnFilterObjects(["MOVING", "IDLING", "PARKING", "DISCONNECTED"]);
                            }

                            case "devicePackage": {
                                return returnFilterObjects(["auth", "heartbeat", "latest", "gps#memory", "event#session#error", "event#session#close", "event#session#timeout"]);
                            }

                            case "gpsStatus": {
                                return returnFilterObjects(["ON", "OFF"]);
                            }

                            case "engineStatus": {
                                return returnFilterObjects(["ON", "OFF"]);
                            }

                            case "eventType": {
                                return returnFilterObjects(Object.values(EVENT_TYPES).filter((type) => type !== "ALL"))
                            }

                            default: {
                                return [];
                            }
                        }
                    }

                    return {
                        ...currColumn,
                        filters: returnFilters(),
                        onFilter: (value, record) => record[currColumn.key] !== null && record[currColumn.key].includes(value),
                    }
                }
                else {
                    return currColumn;
                }
            })

        // console.log("New Columns:", newColumns);

        return newColumns;
    }

    returnExportData = () => {
        const isDateArr = [
            "inAt",
            "outAt",
            "endTime",
            "startTime",
            "createdAt",
            "deviceTime",
        ];

        const isDurationArr = [
            "duration",
            "totalEngineDuration",
            "totalMovingDuration",
            "totalIdlingDuration",
            "totalParkingDuration",
        ];

        const isAddressArr = [
            "location",
            "endLocation",
            "startLocation",
        ];
        
        // Add address columns into export columns if needed
        const returnFormattedExportColumns = () => {
            const returnNewColumn = (dataIndex) => {
                switch(dataIndex) {
                    case "location": {
                        return {
                            title: 'Address',
                            key: 'address',
                            dataIndex: 'address',
                        };
                    }
    
                    case "endLocation": {
                        return {
                            title: 'End Address',
                            key: 'endAddress',
                            dataIndex: 'endAddress',
                        };
                    }
                    
                    case "startLocation": {
                        return {
                            title: 'Start Address',
                            key: 'startAddress',
                            dataIndex: 'startAddress',
                        };
                    }
                    default: return null
                }
            }
    
            let formattedColumns = [...TableTypes[this.state.currTable].columns];

            // console.log("Old Columns:", formattedColumns);
    
            if (this.state.currTable !== "EVENT" && this.state.currTable !== "DETAIL") {
                for (let i = 0; i < formattedColumns.length; i ++) {
                    const col = formattedColumns[i];
    
                    if (isAddressArr.includes(col.dataIndex)) {
                        formattedColumns.splice(i, 0, returnNewColumn(col.dataIndex));
    
                        i += 1;
                    }
                }
            }

            // console.log("New Columns:", formattedColumns);

            return formattedColumns;
        }

        const returnFormattedExportData = (value, dataIndex) => {
            if (value[dataIndex] && value[dataIndex] !== "-") {
                if (isDateArr.includes(dataIndex)) {
                    return parseDate(value[dataIndex]);
                }
                else if (isDurationArr.includes(dataIndex)) {
                    return parseDuration(value[dataIndex]);
                }
                else if (isAddressArr.includes(dataIndex)) {
                    return value[dataIndex] && value[dataIndex].lat && value[dataIndex].lon ? `${value[dataIndex].lat}, ${value[dataIndex].lon}` : "-";
                }
                else {
                    return value[dataIndex].toString();
                }
            }
            else {
                return "-";
            }
        }

        const newExportedData = returnFormattedExportColumns().map((col) => ({
            label: col.title, 
            formatter: (value) => returnFormattedExportData(value, col.dataIndex),
        }))

        // console.log("New Exported Data:", newExportedData);

        return newExportedData;
    }

    render() {
        const { 
            endTime,
            startTime,
            tempTable,
            currTable,
            selectedVIDs,
            // selectedGeoIDs,
            filteredDataSource,
        } = this.state;

        const { style } = this.props;

        const exportData = this.returnExportData();
        const formattedColumns = this.returnFormattedColumns();
    
        return (
            <div className = 'page-container'>
                <Page title = 'Vehicle Report'>
                    <div 
                        style = {{
                            // display: "block",
                            marginBottom: 5,
                        }}
                    >
                        <div className = "formField">
                            <SearchFields
                                defaultFilter = {defaultFilter}
                                tempTable = {tempTable}
                                selectedVIDs = {selectedVIDs}
                                // selectedGeoIDs = {selectedGeoIDs}
                                onVIDChange = {(newIDs) => this.setSelectedVIDs(newIDs)}
                                // onGeoIDChange = {(newIDs) => this.setSelectedGeoIDs(newIDs)}
                            />
                            
                            <div className = "formField">
                                <span className = "formFieldLabel">Start Time: </span>

                                <DatePicker
                                    showTime
                                    value = {startTime}
                                    onChange = {value => this.setState({ startTime: value })}
                                    style = {{ width: '177px' }}
                                />
                            </div>

                            <div className = "formField">
                                <span className = "formFieldLabel">End Time: </span>

                                <DatePicker
                                    showTime
                                    value = {endTime}
                                    onChange = {value => this.setState({ endTime: value })}
                                    style = {{ width: '177px' }}
                                />
                            </div>

                            <div className = "formField">
                                <span className = "formFieldLabel">Report: </span>

                                <Select
                                    placeholder = {"Select a Report"}
                                    value = {TableTypes[tempTable].title}
                                    onSelect = {(newTable) => {
                                        if (tempTable !== newTable) {
                                            // this.searchInput.current.clear();

                                            this.setState({ 
                                                selectedVIDs: [],
                                                // selectedGeoIDs: [],
                                                tempTable: newTable,
                                                isVisible: newTable === "EVENT" || newTable === "GEOFENCE" ? true : false,
                                                
                                                // Advanced Filter Stuff
                                                selectedDuration: 1, // Default is > 1 mins
                                                selectedEventTypes: [defaultFilter],
                                                selectedGeofenceFilters: [defaultFilter],
                                            });
                                        }
                                        else {
                                            this.setState({ 
                                                isVisible: newTable === "EVENT" || newTable === "GEOFENCE" ? true : false,
                                            });
                                        }
                                    }}
                                    style = {{ width: '130px' }}
                                >
                                    {
                                        Object.keys(TableTypes).map((currKey) => 
                                            <Select.Option key = {currKey} value = {currKey}>
                                                {TableTypes[currKey].title}
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </div>

                            <div className = "formField">
                                <PrimaryButton
                                    loading = {(style && style.isLoadingSubmit) || false}
                                    disabled = {(tempTable !== "GEOFENCE" && !selectedVIDs.length) || (tempTable === "GEOFENCE" && !selectedVIDs.length)}
                                    onClick = {this.submitForm}
                                    style = {{ marginLeft: '15px' }}
                                >
                                    Submit
                                </PrimaryButton>

                                <ExportExcelButton
                                    disabled = {!(filteredDataSource.length && tempTable === currTable)}
                                    filename = {`Vehicle Report - ${currTable} - ${moment(startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}`}
                                    sheetData = {filteredDataSource}
                                    sheetName = {`Vehicle Report`}
                                    sheetRow = {exportData}
                                />
                            </div>
                        </div>
                    </div>

                    {
                        (tempTable === "EVENT" || tempTable === "GEOFENCE") ? 
                            <div
                                style = {{
                                    width: "100%",
                                    marginBottom: 5,
                
                                    backgroundColor: "white",
                                    
                                    padding: 10,
                                }}
                            >
                                <div style = {{ marginBottom: 10, fontWeight: "bold" }}>Advanced Filters</div>
                
                                {
                                    <AdvancedFilters
                                        tempTable = {tempTable}
                                        selectedDuration = {this.state.selectedDuration}
                                        selectedEventTypes = {this.state.selectedEventTypes}
                                        selectedGeofenceFilters = {this.state.selectedGeofenceFilters}
                                        onEventFilterChange = {(newEventFilter) => this.setEventFilter(newEventFilter)}
                                        onDurationFilterChange = {(newDurationFilter) => this.setDurationFilter(newDurationFilter)}
                                        onGeofenceFilterChange = {(newGeofenceFilter) => this.setGeofenceFilter(newGeofenceFilter)}
                                    />
                                }
                            </div> :
                            null
                    }

                    <Table
                        columns = {formattedColumns}
                        dataSource = {filteredDataSource}
                    />

                    <AdvancedFilterModal
                        isVisible = {this.state.isVisible}
                        selectedVIDs = {selectedVIDs}
                        modalContents = {
                            <AdvancedFilters
                                isModal
                                tempTable = {tempTable}
                                selectedDuration = {this.state.selectedDuration}
                                selectedEventTypes = {this.state.selectedEventTypes}
                                selectedGeofenceFilters = {this.state.selectedGeofenceFilters}
                                onEventFilterChange = {(newEventFilter) => this.setEventFilter(newEventFilter)}
                                onDurationFilterChange = {(newDurationFilter) => this.setDurationFilter(newDurationFilter)}
                                onGeofenceFilterChange = {(newGeofenceFilter) => this.setGeofenceFilter(newGeofenceFilter)}
                            />
                        }
                        onAdvancedFilterModalClose = {() => {
                            this.setState({
                                ...this.state,
                                isVisible: false,
                            })
                        }}
                        onAdvancedFilterModalSubmit = {this.submitForm}
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.v2.style,
    vehicles: state.v2.vehicles,
    geofences: state.v2.geofences,
    geofenceTemplates: state.v2.geofenceTemplate,
});

const ConnectedPage = connect(mapStateToProps)(VehicleReportPage);
export default showSecondarySidebar(false)(ConnectedPage);