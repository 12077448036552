import * as API from "../../api"
import * as actionType from "../action-types/events";

import moment from 'moment';

import { message } from "antd";
import {
  setLoadingEvent,
  unsetLoadingEvent,
} from "./style";

export const get_event_by_timeframe_request = (vids, startTime, endTime) => dispatch => {
  // console.log(`get_event_by_timeframe_request ${vids}, ${startTime}, ${endTime}`)

  dispatch(setLoadingEvent());

  API.getEventByTimeframe(vids, startTime, endTime)
    .then(data => {
      // console.log("getEventByTimeframe data:", data);

      const {
        status,
        eventLogs = []
      } = data || {}

      if (status !== 200) {
        message.error('Error getting events')
      } 
      else {
        if (eventLogs.length) {
          message.info(`Retrieved ${eventLogs.length} log(s)`);
        }
        else {
          message.info(`No event logs found!`);
        }

        dispatch(get_event_by_timeframe_success(eventLogs));
      }
    })
    .finally(() => {
      dispatch(unsetLoadingEvent());
    })
}

export const get_event_by_timeframe_success = (data) => {
  // console.log("action events", events)

  return {
    type: actionType.GET_EVENTS,
    data: data
  }
}

export const get_todays_events = (vids) => (dispatch) => {
  // console.log(`get_todays_events ${vids}`)

  dispatch(setLoadingEvent());

  API.getEventByTimeframe(vids, moment().startOf('day').valueOf(), moment.now())
    .then(data => {
      // console.log("get_todays_events data:", data);

      const {
        status,
        eventLogs = []
      } = data || {}

      if (status !== 200) {
        message.error("Error getting today's events")
      } 
      else {
        dispatch(get_todays_events_success(eventLogs));
      }
    })
    .finally(() => {
      dispatch(unsetLoadingEvent());
    })
}

export const get_todays_events_success = (data) => {
  return {
    type: actionType.GET_TODAYS_EVENTS,
    data
  }
}

export const clear_event = () => {
  return {
    type: actionType.CLEAR_EVENTS
  }
}

export const set_selected_evid = (selectedEvID) => {
  return {
    type: actionType.SET_SELECTED_EVID,
    selectedEvID: selectedEvID
  }
}

export const clear_selected_evid = () => {
  return {
    type: actionType.CLEAR_SELECTED_EVID
  }
}

export const set_selected_event_index = (selectedIndex) => {
  return {
    type: actionType.SET_SELECTED_EVENT_INDEX,
    selectedIndex: selectedIndex
  }
}

export const clear_selected_event_index = () => {
  return {
    type: actionType.CLEAR_SELECTED_EVENT_INDEX,
  }
}