import * as actionType from "../action-types/mapControl";

export const set_map_control = (control) => {
  // console.log('set_map_control: ', control)

  return {
    type: "SET_MAP_CONTROL",
    control: control
  }
}

export const set_map_location = (location) => {
  // console.log('set_map_location: ', location)

  return {
    type: "SET_MAP_LOCATION",
    location: location
  }
}

export const reset_map_location = (location) => {
  // console.log('set_map_location: ', location)

  return {
    type: "RESET_MAP_LOCATION",
    location: location
  }
}

export const set_map_zoom = (zoom) => {
  return {
    type: "SET_MAP_ZOOM",
    zoom: zoom
  }
}

export const set_new_geofence_from_map = (geofence) => {
  // console.log("Geofence", geofence);

  return {
    type: "SET_NEW_GEOFENCE",
    geofence: geofence
  }
}

export const clear_new_geofence_from_map = () => {
  return {
    type: "CLEAR_GEOFENCE"
  }
}

export const set_selected_geofence_templates_for_map = (geofenceTemplateList) => {
  // console.log("Geofence Template List:", geofenceTemplateList);

  return {
    type: actionType.SET_SELECTED_GEOFENCE_TEMPLATES,
    geofenceTemplateList,
  }
}

export const clear_selected_geofence_templates_for_map = () => {
  return {
    type: actionType.CLEAR_SELECTED_GEOFENCE_TEMPLATES,
  }
}

export const add_map_shape_into_list = (drawnShape) => {
  return {
    type: actionType.ADD_MAP_SHAPE_INTO_LIST,
    drawnShape: drawnShape
  }
}

export const clear_map_shapes_from_list = () => {
  return {
    type: actionType.CLEAR_MAP_SHAPES_FROM_LIST,
  }
}

export const get_browser_latlng = () => {
  if('geolocation' in window.navigator) {
    window.navigator.geolocation.getCurrentPosition(position => {
      // console.log("coord", position.coords);

      return {
        type: "GET_BROWSER_LOC",
        location: {lat: position.coords.latitude,lng: position.coords.longitude}
      }
    })
  }
}

export const set_map_filters = (durationMin, selectedEventTypes, selectedTransitTypes) => {
  // console.log("Setting Map Filters:", durationMin, selectedEventTypes, selectedTransitTypes);

  return {
    type: actionType.SET_MAP_FILTERS,
    durationMin: durationMin,
    selectedEventTypes: selectedEventTypes,
    selectedTransitTypes: selectedTransitTypes,
  }
}

export const add_enabled_vehicle_pop_up = (enabledIdentifier) => {
  return {
    type: actionType.ADD_ENABLED_VEHICLE_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const remove_enabled_vehicle_pop_up = (enabledIdentifier) => {
  return {
    type: actionType.REMOVE_ENABLED_VEHICLE_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const clear_enabled_vehicle_pop_ups = () => {
  return {
    type: actionType.CLEAR_ENABLED_VEHICLE_POP_UP,
  }
}

export const add_enabled_event_pop_up = (enabledIdentifier) => {
  return {
    type: actionType.ADD_ENABLED_EVENT_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const remove_enabled_event_pop_up = (enabledIdentifier) => {
  return {
    type: actionType.REMOVE_ENABLED_EVENT_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const clear_enabled_event_pop_ups = () => {
  return {
    type: actionType.CLEAR_ENABLED_EVENT_POP_UPS,
  }
}

export const add_enabled_geofence_pop_up = (enabledIdentifier) => {
  return {
    type: actionType.ADD_ENABLED_GEOFENCE_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const clear_enabled_geofence_pop_ups = () => {
  return {
    type: actionType.CLEAR_ENABLED_GEOFENCE_POP_UP,
  }
}

export const set_geofence_filter_string = () => {
  return {
    type: actionType.SET_NEW_GEOFENCE_FILTER_STRING,
  }
}

export const reset_geofence_filter_string = () => {
  return {
    type: actionType.RESET_GEOFENCE_FILTER_STRING,
  }
}

export const enable_show_vehicle_info = () => {
  return {
    type: actionType.ENABLE_SHOW_VEHICLE_INFO,
  }
}

export const disable_show_vehicle_info = () => {
  return {
    type: actionType.DISABLE_SHOW_VEHICLE_INFO,
  }
}

export const enable_show_geofence_info = () => {
  return {
    type: actionType.ENABLE_SHOW_GEOFENCE_INFO,
  }
}

export const disable_show_geofence_info = () => {
  return {
    type: actionType.DISABLE_SHOW_GEOFENCE_INFO,
  }
}

export const enable_show_geofences = () => {
  return {
    type: actionType.ENABLE_SHOW_GEOFENCES,
  }
}

export const disable_show_geofences = () => {
  return {
    type: actionType.DISABLE_SHOW_GEOFENCES,
  }
}

export const enable_show_live_traffic = () => {
  return {
    type: actionType.ENABLE_SHOW_LIVE_TRAFFIC,
  }
}

export const disable_show_live_traffic = () => {
  return {
    type: actionType.DISABLE_SHOW_LIVE_TRAFFIC,
  }
}

export const enable_show_vehicle_trail = () => {
  return {
    type: actionType.ENABLE_SHOW_VEHICLE_TRAIL,
  }
}

export const disable_show_vehicle_trail = () => {
  return {
    type: actionType.DISABLE_SHOW_VEHICLE_TRAIL,
  }
}

export const reset_ui_control = () => {
  return {
    type: actionType.RESET_UI_CONTROL,
  }
}