import React, {
    useState,
    useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment';
import EventStatusIcon from "../../../components/EventStatusIcon";

import { moveToPage } from "../../../navigation/navigationService";
import {
    Col,
    Row,
} from 'antd';
import {
    parseTime,
    parseDuration
} from '../../../util/time';
import {
    ReloadOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import {
    VEHICLE_COLOR,
    VEHICLE_STATUS,
} from "../../../../../constants";
import {
    Marker,
    InfoWindow,
    OverlayView,
} from '@react-google-maps/api';

// Redux Actions
import { storeSearchDetails } from "../../../services/redux/actions/tripRecordControl.js";
import { temp_update_vehicle_address_2 } from "../../../services/redux/actions/vehicles.js";
import {
    set_selected_device,
    clear_selected_device,
} from "../../../services/redux/actions/devices.js";
import {
    set_map_control,
    set_new_geofence_from_map,
    add_enabled_vehicle_pop_up,
    clear_enabled_vehicle_pop_ups,
    remove_enabled_vehicle_pop_up,
} from "../../../services/redux/actions/mapControl";

import "../gmaps.css";

const VehicleMarkers = (props) => {
    const {
        mapRef,
        // cusStyle,
    } = props;

    // State
    const [hasListener, setHasListener] = useState(false);
    const [isUpdateAddressLoading, setIsUpdateAddressLoading] = useState(false)

    // Redux Store
    const devices = useSelector(state => state.v2.devices);
    const vehicles = useSelector(state => state.v2.vehicles);
    const geofences = useSelector(state => state.v2.geofences);
    const mapControl = useSelector(state => state.v2.mapControl);

    const dispatch = useDispatch()

    // ComponentDidUpdate
    useEffect(() => {
        let location, zoom;

        /**
         * Pan to selected device
         */
        if (
            devices.selectedId
            && devices.byId[devices.selectedId]
            && devices.byId[devices.selectedId].location
            && devices.byId[devices.selectedId].location.lat
            && devices.byId[devices.selectedId].location.lon
            && mapControl.mapControl === devices.selectedId) {
            zoom = 20;

            location = {
                lat: parseFloat(devices.byId[devices.selectedId].location.lat),
                lng: parseFloat(devices.byId[devices.selectedId].location.lon),
            }

            if (mapRef) {
                mapRef.panTo(location);
                mapRef.setZoom(zoom);

                // dispatch(set_map_control(0));
            }
        }
    })

    // Initial mount of component - ComponentDidMount()
    useEffect(() => {
        dispatch(clear_selected_device());
        dispatch(clear_enabled_vehicle_pop_ups());
    },
        [dispatch]
    )

    // Add an event listener to disable map panning to vehicle once the map is dragged
    useEffect(() => {
        if (mapRef && !hasListener) {
            setHasListener(true);

            mapRef.addListener('dragstart', () => {
                dispatch(set_map_control(0));
                dispatch(clear_selected_device());
            });
        }
    },
        [mapRef, dispatch, setHasListener, hasListener]
    )

    const getVehicleTransitColor = (dvid) => {
        const vid = vehicles.allIds.filter((vid) => vehicles.byId[vid].dvid === dvid);

        if (vid.length > 0) {
            return VEHICLE_COLOR[vehicles.byId[vid[0]].transitStatus]
        }
        else return VEHICLE_COLOR[null];
    }

    const timeDiff = (startTime, endTime) => {
        const duration = parseDuration(moment(Number(endTime)).utc().diff(moment(startTime).utc()));

        return duration;
    }

    const getVehicleMarker = (thisVehicle, thisDevice) => {
        if (
            thisDevice &&
            thisVehicle &&
            thisDevice.location
        ) {
            const vid = thisVehicle.vid;

            const inGeofenceGeoid = Object.keys(thisVehicle.geofenceProfile).find(geoid => {
                return thisVehicle.geofenceProfile[geoid] === 1
            })

            let inGeofence;

            if (inGeofenceGeoid) {
                inGeofence = geofences.byId[inGeofenceGeoid];
            }

            // const stylePOI = cusStyle && cusStyle.hasOwnProperty('POI') ? cusStyle.POI : {};
            // const stylePOIDescription = cusStyle && cusStyle.hasOwnProperty('POI_Description') ? cusStyle.POI_Description : {};

            return (
                <Marker
                    key = {vid}
                    icon = {{
                        path: window.google && window.google.maps && window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 5,
                        strokeColor: getVehicleTransitColor(thisDevice.dvid),
                        rotation: Number(thisDevice.heading),
                        fillOpacity: 1,

                        // 24 is sprite width and 28 is sprite height. Make sure to match future anchors accordingly
                        // anchor: new window.google.maps.Point(24 / 2, 28 / 2),
                    }}
                    position = {{
                        lat: parseFloat(thisDevice.location.lat),
                        lng: parseFloat(thisDevice.location.lon)
                    }}
                    onClick = {() => {
                        // console.log(device.location);

                        dispatch(add_enabled_vehicle_pop_up(vid));
                        dispatch(set_selected_device(thisDevice.dvid));
                        dispatch(set_map_control(thisDevice.dvid));
                    }}
                >
                    {
                        mapControl.uiControl.showInfoWindowVehicles.includes(thisVehicle.vid)
                            && devices.selectedId
                            && devices.selectedId === thisVehicle.dvid ?
                            <InfoWindow
                                zIndex = {1000}
                                // maxWidth = {1000}
                                options = {{
                                    disableAutoPan: true,
                                    // maxWidth: 1000

                                }}
                                mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                position = {{
                                    lat: parseFloat(thisDevice.location.lat),
                                    lng: parseFloat(thisDevice.location.lon)
                                }}
                                onCloseClick = {
                                    () => {
                                        dispatch(remove_enabled_vehicle_pop_up(thisVehicle.vid));
                                    }
                                }
                            >
                                <div style = {{ width: 250 }}>
                                    <h1>{thisVehicle.vehicleDisplayName}</h1>

                                    <p
                                        style = {{
                                            color: thisVehicle.transitStatus ? VEHICLE_COLOR[thisVehicle.transitStatus] : VEHICLE_COLOR["NULL"]
                                        }}
                                    >
                                        {
                                            thisVehicle.transitStatus && thisVehicle.transitStatus !== "DISCONNECTED" ?
                                                `${thisVehicle.transitStatus} For ${timeDiff(thisVehicle.transitFrom, thisVehicle.updatedAt)}` :
                                                thisVehicle.hasOwnProperty('updatedAt') ?
                                                    `${thisVehicle.transitStatus} For ${timeDiff(thisVehicle.transitFrom, thisVehicle.updatedAt)}` :
                                                    thisVehicle.gpsStatus ?
                                                        `${thisVehicle.transitStatus} For ${timeDiff(thisVehicle.transitFrom, thisVehicle.updatedAt)}` :
                                                        `${thisVehicle.transitStatus} For ${timeDiff(thisVehicle.transitFrom, thisVehicle.noGPSAt)}`
                                        }
                                    </p>

                                    <p>
                                        {
                                            thisVehicle.hasOwnProperty('updatedAt') ?
                                                `Last Updated: ${parseTime(thisVehicle.updatedAt)}` :
                                                thisVehicle.gpsStatus ?
                                                    `Last Updated: ${parseTime(thisVehicle.updatedAt)}` :
                                                    thisVehicle.isAlive ?
                                                        `Last Updated: ${parseTime(thisVehicle.noGPSAt)}` :
                                                        `Last Updated: ${parseTime(thisVehicle.deviceTime)}`
                                        }
                                    </p>
                                    
                                    {<p>Speed: {`${thisVehicle.transitStatus === VEHICLE_STATUS.MOVING ? (!isNaN(thisVehicle.speed) && thisVehicle.speed.toFixed(1) )|| '0.0' : "0.0"} KM/H`}</p>}

                                    {
                                        inGeofence && thisVehicle.transitStatus !== VEHICLE_STATUS.DISCONNECTED &&
                                            <div style = {{ marginBottom: 5 }}>
                                                <Row style = {{ margin: 1 }}>
                                                    <Col
                                                        span = {7}
                                                        className = "POI"
                                                        // style = {stylePOI}
                                                        style = {{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        {inGeofence.geoType}
                                                    </Col>

                                                    <Col
                                                        span = {16}
                                                        offset = {1}
                                                        className = "POI_Description"
                                                        // style = {stylePOIDescription}
                                                    >
                                                        {inGeofence.geofenceName}
                                                    </Col>
                                                </Row>
                                            </div>
                                    }
                                    
                                    {
                                        isUpdateAddressLoading ?
                                            <div
                                                style = {{
                                                    display: "flex",
        
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <LoadingOutlined style = {{ marginRight: 5 }}/>

                                                <span>Updating Address...</span>
                                            </div> : 
                                            <div
                                                style = {{
                                                    display: "flex",
        
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <span
                                                    className = "ButtonLink"
                                                    onClick = {() => {
                                                        setIsUpdateAddressLoading(true)
                                                        dispatch(temp_update_vehicle_address_2(thisVehicle.vid, thisDevice.location.lat, thisDevice.location.lon));
        
                                                        setTimeout(() => setIsUpdateAddressLoading(false), 1 * 1000)
                                                    }}
                                                    style = {{ marginRight: 5 }}
                                                >
                                                    <ReloadOutlined/>
                                                </span>
        
                                                <span style = {{ fontWeight: "bold" }}>
                                                    {thisVehicle.address || "Address not found"}
                                                </span>
                                            </div>
                                    }

                                    <br />

                                    <div
                                        style = {{
                                            display: "flex",
                                            marginBottom: 5,
                                        }}
                                    >
                                        <EventStatusIcon deviceId = {thisDevice.dvid}/>
                                    </div>

                                    <div
                                        style = {{
                                            display: "flex",

                                            alignItems: "center",
                                            justifyContent: "center",

                                            width: "100%",
                                        }}
                                    >
                                        <span
                                            className = "ButtonLink"
                                            onClick = {() => {
                                                const currCoor = {
                                                    lat: parseFloat(thisDevice.location.lat),
                                                    lng: parseFloat(thisDevice.location.lon)
                                                }

                                                // console.log("Adding new POI from vehicle:", thisDevice.dvid, currDevice.location);

                                                dispatch(set_new_geofence_from_map({
                                                    ...mapControl.geofence,
                                                    coordinates: [currCoor],
                                                }));

                                                dispatch(moveToPage("/Geofence/AddNewPOI"), { 'coordinates': [currCoor] });
                                            }}
                                            style = {{
                                                flex: 1,
                                                fontSize: 12,
                                            }}
                                        >
                                            Add as POI
                                        </span>

                                        <a
                                            className = "ButtonLink"
                                            target = "_blank"
                                            rel = "noopener noreferrer"
                                            href = {`https://www.google.com.my/maps/place/${thisDevice.location.lat},${thisDevice.location.lon}`}
                                            style = {{
                                                flex: 1,
                                                fontSize: 12,
                                            }}
                                        >
                                            View on Map
                                        </a>

                                        <span
                                            className = "ButtonLink"
                                            onClick = {() => {
                                                dispatch(storeSearchDetails(thisDevice.dvid, vid, moment().startOf("day"), moment().startOf("day").add(1, "day")));

                                                dispatch(moveToPage("/TripRecord"));
                                            }}
                                            style = {{
                                                flex: 1,
                                                fontSize: 12,
                                            }}
                                        >
                                            View Playback
                                        </span>
                                    </div>
                                </div>
                            </InfoWindow> :
                            mapControl.uiControl.showAllVehiclesInfoWindow &&
                            <OverlayView
                                mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                options = {{
                                    disableAutoPan: true,
                                    maxWidth: 250
                                }}
                                position = {{
                                    lat: parseFloat(thisDevice.location.lat),
                                    lng: parseFloat(thisDevice.location.lon)
                                }}
                                getPixelPositionOffset = {(width, height) => ({
                                    x: -(width / 2),
                                    y: -height - 5,
                                })}
                            >
                                <div
                                    // className = "overlayPopUp"
                                    style = {{
                                        background: 'white',

                                        border: '1px solid #ccc',
                                        borderRadius: '10px',

                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        paddingTop: 8,
                                        paddingBottom: 1,
                                    }}
                                >
                                    <h1>{thisVehicle.vehicleDisplayName}</h1>
                                </div>
                            </OverlayView>
                    }
                </Marker>
            )
        }
    }

    return (
        vehicles &&
        vehicles.allIds.map(vid => {
            const thisVehicle = vehicles.byId[vid];
            const thisDevice = devices.byId[thisVehicle.dvid];

            return getVehicleMarker(thisVehicle, thisDevice)
        })
    )
}

export default VehicleMarkers