import React, { useState, useEffect } from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux';

import { moveToPage } from '../../../navigation/navigationService';
import { 
    Timeline, 
    Descriptions, 
} from 'antd';
import { 
    parseTime, 
    parseDuration, 
} from '../../../util/time';
import { 
    EVENT_TYPES, 
    VEHICLE_COLOR 
} from '../../../../../constants';
import { 
    Marker, 
    Polyline, 
    InfoWindow, 
} from '@react-google-maps/api';

import info from '../../../../../img/Trip Record/info.svg';
import warning from '../../../../../img/Trip Record/warning.svg';
import critical from '../../../../../img/Trip Record/critical.svg';

const TripRecordRoute = (props) => {
    const { mapRef } = props;

    // Redux Store
    const router = useSelector(state => state.router);
    const events = useSelector(state => state.v2.events);
    const vehicles = useSelector(state => state.v2.vehicles);
    const deviceLog = useSelector(state => state.v2.deviceLog);
    const mapControl = useSelector(state => state.v2.mapControl);
    const tripRecord = useSelector(state => state.v2.tripRecord);
    const transitRecord = useSelector(state => state.v2.transitRecord);

    const [thisEventLogs, setThisEventLogs] = useState([]);
    const [thisDeviceLogs, setThisDeviceLogs] = useState([]);
    const [playbackMarkerDeviceLog, setPlaybackMarkerDeviceLog] = useState(null);

    const dispatch = useDispatch()

    useEffect(() => {
        const {
            vid, 
            trid,
            endTime, 
            startTime, 
        } = router.location.state || {}

        const vehicle = vehicles.byId[vid]
        const dvid = vehicle && vehicle.dvid
        const thisTripRecord = tripRecord.byTrID[`tr#${trid}`]

        if (!dvid || !startTime || !endTime) return

        const thisDeviceLogs = (deviceLog.byId[dvid] && deviceLog.byId[dvid].deviceLog && deviceLog.byId[dvid].deviceLog
            .filter(log => startTime <= log.createdAt && endTime >= log.createdAt)
            .sort((a, b) => a.createdAt - b.createdAt)
            .map(log => {
                // formatting log

                let isHighlighted;

                if (!thisTripRecord) {
                    // isHighlighted = true;
                    isHighlighted = false;
                } 
                else {
                    isHighlighted = thisTripRecord.startTime <= log.createdAt && thisTripRecord.endTime >= log.createdAt
                }

                const lat = log.location && !isNaN(log.location.lat) && parseFloat(log.location.lat)
                const lng = log.location && !isNaN(log.location.lon) && parseFloat(log.location.lon)

                return {
                    ...log,
                    lat,
                    lng,
                    isHighlighted
                }
            })) || []


        const thisEventLogs = (thisDeviceLogs.length && events.byId[vid] && Object.values(events.byId[vid])
            .filter(eventLog => startTime <= eventLog.createdAt && endTime >= eventLog.createdAt)
            // .filter(eventLog => eventTypeFilters.includes(eventLog.eventType))
            .map(eventLog => {

                const lat = eventLog.location && !isNaN(eventLog.location.lat) && parseFloat(eventLog.location.lat)
                const lng = eventLog.location && !isNaN(eventLog.location.lon) && parseFloat(eventLog.location.lon)
                return {
                    ...eventLog,
                    lat,
                    lng
                }
            })) || []

        const playbackMarkerDeviceLog = thisDeviceLogs[deviceLog.markerPlaybackIndex]

        if (mapRef && thisDeviceLogs.length && mapControl.mapControl !== 0) {

            const bounds = new window.google.maps.LatLngBounds();

            thisDeviceLogs.map(c => {
                bounds.extend(c)
                return null;
            })

            // console.log(`[GoogleMapsComponent] Setting map location by route`);

            mapRef.fitBounds(bounds, [10]);
        }

        setThisDeviceLogs(thisDeviceLogs)
        setThisEventLogs(thisEventLogs)
        setPlaybackMarkerDeviceLog(playbackMarkerDeviceLog)

    }, [router, tripRecord, deviceLog, events, mapControl.mapControl, vehicles.byId, mapRef])


    const getTransitRecordInfoWindow = () => {
        const selectedTransitRecord = router.location.state && transitRecord.byTsid[router.location.state.tsid]

        if (!selectedTransitRecord) return null

        const position = selectedTransitRecord.startLocation && selectedTransitRecord.startLocation.lat && selectedTransitRecord.startLocation.lon
            && { lat: parseFloat(selectedTransitRecord.startLocation.lat), lng: parseFloat(selectedTransitRecord.startLocation.lon) }

        if (!position) return null

        return (
            <InfoWindow
                zIndex = {1000}
                options = {{
                    disableAutoPan: true,
                }}
                position = {position}

            >
                <div
                    style = {{
                        paddingTop: 10,
                    }}
                >
                    <Timeline>
                        <Timeline.Item
                            color = {VEHICLE_COLOR[selectedTransitRecord.transitStatus]}
                        >
                            <div
                                style = {{
                                    display: `flex`,
                                    flexDirection: `row`,
                                    justifyContent: `space-between`
                                }}
                            >
                                <h4 style = {{ fontWeight: 'bold' }}>
                                    {
                                        selectedTransitRecord.transitStatus
                                    }
                                </h4>

                                {
                                    parseTime(selectedTransitRecord.startTime)
                                }
                            </div>

                            <div>
                                <h4>
                                    {
                                        selectedTransitRecord.duration && parseDuration(selectedTransitRecord.duration)
                                    }
                                </h4>

                                <h4>
                                    {selectedTransitRecord.startAddress}
                                </h4>

                                <a
                                    href = {`https://www.google.com.my/maps/place/${position.lat},${position.lng}`}
                                    target = '_blank'
                                    rel = 'noopener noreferrer'
                                >
                                    {`${position.lat}, ${position.lng}`}
                                </a>
                            </div>
                        </Timeline.Item>
                    </Timeline>
                </div>
            </InfoWindow>
        )
    }

    const getEventLogMarkers = () => {
        return thisEventLogs.map(eventLog => {
            let dot
            switch (eventLog.eventType) {
                case EVENT_TYPES.CRITICAL:
                    dot = critical
                    break
                case EVENT_TYPES.WARNING:
                    dot = warning
                    break

                case EVENT_TYPES.INFO:
                default:
                    dot = info
                    break
            }

            const isShowEventInfoWindow = router.location.state && router.location.state.eventCreatedAt === eventLog.eventCreatedAt


            return <Marker
                key = {eventLog.eventCreatedAt}
                position = {{ lat: eventLog.lat, lng: eventLog.lng, }}
                icon = {dot}
                onClick = {() => {
                    const thisTripRecord = Object.values(tripRecord.byTrID).find(tr => {
                        return tr.startTime <= eventLog.createdAt && tr.endTime >= eventLog.createdAt
                    })
                    const trid = thisTripRecord && thisTripRecord.trid.split('#').pop()

                    dispatch(moveToPage(`TripRecord`, {
                        ...router.location.state,
                        eventCreatedAt: eventLog.eventCreatedAt,
                        trid,
                    }))
                }}
            >

                {
                    isShowEventInfoWindow && <InfoWindow
                        zIndex = {1000}
                        options = {{
                            disableAutoPan: true,
                        }}
                        // mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                        position = {{ lat: eventLog.lat, lng: eventLog.lng, }}
                    >
                        <div
                            style = {{
                                paddingTop: 10,
                            }}
                        >
                            <Timeline>
                                <Timeline.Item
                                    dot = {<img
                                        alt = ""
                                        src = {dot}
                                        style = {{
                                            width: 16,
                                            height: 16,
                                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                                        }}
                                    />}
                                    key = {eventLog.eventCreatedAt}
                                    onClick = {() => {
                                        // console.log(eventLog)
                                    }}
                                >
                                    {
                                        parseTime(eventLog.createdAt)
                                    }
                                    <h4 style = {{ fontWeight: 'bold' }}>
                                        {
                                            eventLog.eventType
                                        }
                                    </h4>

                                    <div>
                                        <p>
                                            {eventLog.eventName}
                                        </p>
                                    </div>
                                </Timeline.Item>
                            </Timeline>
                        </div>
                    </InfoWindow>
                }
            </Marker>
        })
    }

    const getDeviceLogPath = () => {
        return thisDeviceLogs.map((log, i) => {
            const nextLog = thisDeviceLogs[i + 1];

            if (!nextLog) return (
                <Marker
                    key = {i}
                    position = {{ lat: log.lat, lng: log.lng, }}
                >
                    <InfoWindow
                        zIndex = {1000}
                        options = {{
                            disableAutoPan: true,
                        }}
                        position = {{ lat: log.lat, lng: log.lng, }}
                    >
                        <div
                        >
                            {`End Journey`}
                        </div>
                    </InfoWindow>
                </Marker>
            )

            const path = [{ lat: log.lat, lng: log.lng }, { lat: nextLog.lat, lng: nextLog.lng }];
            const isShowDeviceLogInfoWindow = router.location.state && router.location.state.deviceLogCreatedAt === log.createdAt;

            const isHighlighted = log.isHighlighted && nextLog.isHighlighted;
            const isMemory = log.devicePackage === "gps#memory" && nextLog.devicePackage === "gps#memory";

            // let options = isHighlighted ? ({
            //     // strokeColor: 'red',
            //     // strokeOpacity: 0.8,
            //     // fillColor: 'red',
            //     // fillOpacity: 0.8

            //     strokeColor: 'blue',
            //     strokeOpacity: 0.8,
            //     fillColor: 'blue',
            //     fillOpacity: 0.8
            // }) : ({
            //     // strokeColor: '#757575',
            //     // strokeOpacity: 0.8,
            //     // fillColor: '#757575',
            //     // fillOpacity: 0.8,

            //     strokeColor: 'red',
            //     strokeOpacity: 0.8,
            //     fillColor: 'red',
            //     fillOpacity: 0.8,
            // })

            const returnPolylineOptions = () => {
                if (isMemory) {
                    return {
                        strokeColor: '#757575',
                        strokeOpacity: 0.75,
                        fillColor: '#757575',
                        fillOpacity: 0.75,
                    }
                }
                else if (isHighlighted) {
                    return {
                        strokeColor: 'blue',
                        strokeOpacity: 0.75,
                        fillColor: 'blue',
                        fillOpacity: 0.75,
                        zIndex: 100,
                    }
                }
                else {
                    return {
                        strokeColor: 'red',
                        strokeOpacity: 0.75,
                        fillColor: 'red',
                        fillOpacity: 0.75,
                    }
                }
            }

            return <div key = {i}>
                {
                    i === 0 && (
                        <Marker position = {{ lat: log.lat, lng: log.lng }}>
                            <InfoWindow
                                zIndex = {1000}
                                options = {{
                                    disableAutoPan: true,
                                }}
                                position = {{ lat: log.lat, lng: log.lng, }}
                            >
                                <div>
                                    {`Start Journey`}
                                </div>
                            </InfoWindow>
                        </Marker>
                    )
                }

                <Polyline
                    key = {i}
                    path = {path}
                    onClick = {() => {
                        // console.log(nextLog);

                        const deviceLogCreatedAt = nextLog.createdAt;
                        
                        dispatch(moveToPage('TripRecord', {
                            ...router.location.state,
                            deviceLogCreatedAt,
                        }))
                    }}
                    options = {{
                        // ...options,
                        ...returnPolylineOptions(),
                        strokeWeight: 2,
                        fillOpacity: 0.35,
                        icons: [{
                            icon: { path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                            offset: '100%'
                        }],
                        // zIndex: 99
                    }}
                    style = {{
                        cursor: `pointer`,
                    }}
                />

                {
                    isShowDeviceLogInfoWindow && (
                        <InfoWindow
                            zIndex = {1000}
                            options = {{
                                disableAutoPan: true,
                            }}
                            position = {{ lat: log.lat, lng: log.lng, }}
                        >
                            <div
                                style = {{
                                    paddingTop: 10,
                                    height: 200,
                                    overflow: 'scroll',
                                }}
                            >
                                <Descriptions
                                    bordered
                                    size = {'small'}
                                    column = {1}
                                >
                                    <Descriptions.Item
                                        label = {`Created At`}
                                    >
                                        {parseTime(log.createdAt)}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`DeviceMessage`}
                                    >
                                        {log.deviceMessage}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Location`}
                                    >
                                        <a
                                            href = {`https://www.google.com.my/maps/place/${parseFloat(log.location.lat)},${parseFloat(log.location.lon)}`}
                                            target = '_blank'
                                            rel = 'noopener noreferrer'
                                        >
                                            {`${log.location.lat}, ${log.location.lon}`}
                                        </a>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Speed`}
                                    >
                                        {log.speed || 0} km/h
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Altitude`}
                                    >
                                        {log.altitude}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Heading`}
                                    >
                                        {log.heading} degree
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Fuel`}
                                    >
                                        {log.fuel}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Device Time`}
                                    >
                                        {parseTime(log.deviceTime)}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Package Time`}
                                    >
                                        {log.packageTime}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`GPS Status`}
                                    >
                                        {log.gpsStatus}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Engine Status`}
                                    >
                                        {log.engineStatus}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Is Alive`}
                                    >
                                        {log.isAlive}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label = {`Device Package`}
                                    >
                                        {log.devicePackage}
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                        </InfoWindow>
                    )
                }
            </div>
        })
    }

    return (
        <div>

            {
                playbackMarkerDeviceLog && (
                    <Marker
                        position = {{ lat: playbackMarkerDeviceLog.lat, lng: playbackMarkerDeviceLog.lng, }}
                    >
                    </Marker>
                )
            }

            {
                getTransitRecordInfoWindow()
            }
            {
                getEventLogMarkers()
            }
            {
                getDeviceLogPath()
            }
        </div>

    )
}


export default TripRecordRoute