import { config } from '../../../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getStage = (uidList) => (
    fetch(`${api}/user/getstage`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uids" : uidList,
        })
    })
    .then(res => res.json())
)

export const migrateUser = (uid, sourceStage, targetStage) => (
    fetch(`${api}/user/migrate`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid" : uid,
            "sourceStage" : sourceStage,
            "targetStage" : targetStage,
        })
    })
    .then(res => res.json())
)