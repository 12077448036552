import React from 'react';

import parseAddress from '../../../util/parseAddress';

import { 
    parseDate, 
    parseDuration,
} from '../../../util/time';

// Do not sort!
const tableTypes = {
    MILEAGE: {
        title: "Mileage",
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Total Mileage (km)',
                key: 'totalMileage',
                dataIndex: 'totalMileage',
            },

            {
                title: 'Number of Trips',
                key: 'numberOfTrips',
                dataIndex: 'numberOfTrips',
            },

            {
                title: 'Total Engine Duration (hrs)',
                key: 'totalEngineDuration',
                dataIndex: 'totalEngineDuration',
                render: (data) => parseDuration(data),
            },
            
            {
                title: 'Total Moving Duration (hrs)',
                key: 'totalMovingDuration',
                dataIndex: 'totalMovingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Idling Duration (hrs)',
                key: 'totalIdlingDuration',
                dataIndex: 'totalIdlingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Parking Duration (hrs)',
                key: 'totalParkingDuration',
                dataIndex: 'totalParkingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Fuel Used (L)',
                key: 'fuelUsed',
                dataIndex: 'fuelUsed',
            },
        ]
    }, 

    TRIP: {
        title: "Trip",
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },

            {
                title: 'Trip Status',
                key: 'tripStatus',
                dataIndex: 'tripStatus',
            },
            
            {
                title: 'Start Date',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (data) => parseDate(data),
            },
            
            {
                title: 'End Date',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (data) => parseDate(data),
            },
            
            {
                title: 'Start Location',
                key: 'startLocation',
                dataIndex: 'startLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.startAddress) : "-",
            },
            
            {
                title: 'End Location',
                key: 'endLocation',
                dataIndex: 'endLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.endAddress) : "-",
            },
            
            {
                title: 'Total Duration (hrs)',
                key: 'duration',
                dataIndex: 'duration',
                render: (data) => parseDuration(data),
            },
            
            {
                title: 'Total Mileage (km)',
                key: 'totalMileage',
                dataIndex: 'totalMileage',
            },
            
            {
                title: 'Total Moving Duration (hrs)',
                key: 'totalMovingDuration',
                dataIndex: 'totalMovingDuration',
                render: (data) => parseDuration(data),
            },

            {
                title: 'Total Idling Duration (hrs)',
                key: 'totalIdlingDuration',
                dataIndex: 'totalIdlingDuration',
                render: (data) => parseDuration(data),
            },
            
            {
                title: 'Top Speed (km/h)',
                key: 'topSpeed',
                dataIndex: 'topSpeed',
            },

            {
                title: 'Fuel Used (L)',
                key: 'fuelUsed',
                dataIndex: 'fuelUsed',
            },
            
            {
                title: 'Total Events',
                key: 'totalEvents',
                dataIndex: 'totalEvents',
            },
        ]
    }, 

    TRANSIT: {
        title: "Transit",
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },
            
            {
                title: 'Transit Status',
                key: 'transitStatus',
                dataIndex: 'transitStatus',
            },
            
            {
                title: 'Start Date',
                key: 'startTime',
                dataIndex: 'startTime',
                render: (data) => parseDate(data),
            },
            
            {
                title: 'End Date',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (data) => parseDate(data),
            },
            
            {
                title: 'Total Duration (hrs)',
                key: 'duration',
                dataIndex: 'duration',
                render: (data) => parseDuration(data),
            },
            
            {
                title: 'Start Location',
                key: 'startLocation',
                dataIndex: 'startLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.startAddress) : "-",
            },
            
            {
                title: 'End Location',
                key: 'endLocation',
                dataIndex: 'endLocation',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.endAddress) : "-",
            },

            {
                title: 'Total Mileage (km)',
                key: 'totalMileage',
                dataIndex: 'totalMileage',
            },

            {
                title: 'Fuel Used (L)',
                key: 'fuelUsed',
                dataIndex: 'fuelUsed',
            },
        ]
    }, 

    DETAIL: {
        title: "Detail",
        columns: [
            {
                title: 'Server Time',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: (data) => parseDate(data),
            },

            {
                title: "Device Time",
                key: 'deviceTime',
                dataIndex: "deviceTime",
                render: (data) => parseDate(data),
            },

            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },
            
            {
                title: 'Device IMEI',
                key: 'deviceIMEI',
                dataIndex: 'deviceIMEI',
            },
            
            {
                title: 'Data Package',
                key: 'devicePackage',
                dataIndex: 'devicePackage',
            },

            {
                title: "Message",
                key: "deviceMessage",
                dataIndex: "deviceMessage",
            },

            {
                title: "GPS Status",
                key: "gpsStatus",
                dataIndex: "gpsStatus",
                render: (data) => data === "ON" ? <b style = {{ color : "green" }}>{data}</b> : <b style = {{ color : "red" }}>{data}</b>
            },

            {
                title: "Engine",
                key: "engineStatus",
                dataIndex: "engineStatus",
                render: (data) => data === "ON" ? <b style = {{ color : "green" }}>{data}</b> : <b style = {{ color : "red" }}>{data}</b>
            },

            {
                title: "Location",
                key: "location",
                dataIndex: "location",
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.address) : "-",
            },

            {
                title: "Speed (km/h)",
                key: "speed",
                dataIndex: "speed",
            },

            {
                title: "Direction",
                key: "heading",
                dataIndex: "heading",
            },

            {
                title: "Fuel (L)",
                key: "fuel",
                dataIndex: "fuel",
            },
        ]
    }, 

    GEOFENCE: {
        title: "Geofence",
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },
            
            {
                title: 'Geofence Template(s)',
                key: 'geofenceTemplates',
                dataIndex: 'geofenceTemplates',
            },
            
            {
                title: 'Geofence Name',
                key: 'geofenceName',
                dataIndex: 'geofenceName',
            },
            
            {
                title: 'IN',
                key: 'inAt',
                dataIndex: 'inAt',
                render: (data) => parseDate(data),
            },
            
            {
                title: 'OUT',
                key: 'outAt',
                dataIndex: 'outAt',
                render: (data) => parseDate(data),
            },
            
            {
                title: 'Total Duration (hrs)',
                key: 'duration',
                dataIndex: 'duration',
                render: (data) => parseDuration(data),
            },
        ]
    }, 

    EVENT: {
        title: "Event",
        columns: [
            {
                title: 'Vehicle',
                key: 'vehicle',
                dataIndex: 'vehicle',
            },
            
            {
                title: 'Date',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: (data) => parseDate(data),
            },

            {
                title: 'Event Name',
                key: 'eventName',
                dataIndex: 'eventName',
            },

            {
                title: 'Event Type',
                key: 'eventType',
                dataIndex: 'eventType',
            },
            
            {
                title: 'Location',
                key: 'location',
                dataIndex: 'location',
                render: (data, rowData) => data && data.lat && data.lon ? parseAddress(data.lat, data.lon, rowData.address) : "-",
            },
        ]
    },
}

export default tableTypes;