import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/Button";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    ROLE_TYPE,
    VEHICLE_TYPE,
    // DISPLAY_USER_ROLE
} from '../../../../../constants';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Select,
    message,
    // Divider,
} from "antd";
import {
    PlusOutlined,
    MinusCircleOutlined,
    LoadingOutlined,
} from "@ant-design/icons";

// Redux Actions
import { select_user } from "../../../services/redux/actions/hierarchy";
import { edit_vehicle } from "../../../services/redux/actions/vehicles";
// import { editVehicle } from '../../../services/api';

const FormItem = Form.Item;
const { Option } = Select;

const fuelSensorTypes = [
    "dipstick",
    "ultrasonic",
];

class EditCustomerVehiclePage extends Component {
    state = {
        selectedUser: {},

        vgids: [],
        rtids: [],
        editedVehicle: {
            dvid: "",
            vehicleType: "",
            vehicleModel: "",
            vehiclePlate: "",
            vehicleDisplayName: "",
        },

        fuelTanks: [],
    }

    submitForm = () => {
        this.setState({ isLoading: true })

        const {
            targetUserUid,
            fuelTanks,
            editedVehicle,
        } = this.state;

        const {
            dvid,
            vehicleType,
            vehicleModel,
            vehiclePlate,
            vehicleDisplayName,
        } = editedVehicle;

        try {
            if (!dvid) throw new Error('Please select a device for this vehicle')
            if (!vehicleDisplayName) throw new Error('Please enter a vehicle name')
            if (!vehiclePlate) throw new Error('Please enter vehicle plate')
            if (!vehicleModel) throw new Error(`Please enter a vehicle model`)
            if (!vehicleType) throw new Error(`Please select vehicle type`)
            if (!targetUserUid) throw new Error(`Please select target user`)

            fuelTankSanityCheck(fuelTanks);

            const {
                newVgids,
                newGtids,
                newRtids
            } = this.getGroups(targetUserUid)


            /**Build fuel profile */
            editedVehicle.fuelProfile = {}
            fuelTanks.map((tank) => {
                const key = `fuel${(tank.portNumber > 0 && tank.portNumber) || ''}`
                editedVehicle.fuelProfile[key] = tank
                delete tank.portNumber

                return null;
            })

            // console.log({ editedVehicle, targetUserUid, newVgids, newGtids, newRtids })

            this.props.dispatch(edit_vehicle(editedVehicle, targetUserUid, newVgids, newGtids, newRtids))
        } 
        catch (err) {
            message.error(err.message)
            this.setState({ isLoading: false })
        }

        function fuelTankSanityCheck(fuelTanks) {
            // port number must be unique
            let tempPortNumbers = []
            fuelTanks.forEach(tank => {
                // check invalidity
                ['portNumber', 'fuelCapacity', 'fuelLevelMin', 'fuelLevelMax'].map(key => {
                    if (tank[key] === null || tank[key] === undefined || isNaN(tank[key])) {
                        throw new Error('Invalid fuel tank information: Make sure fields are filled in correctly')
                    }

                    return null;
                })

                if (!tempPortNumbers.includes(tank.portNumber)) {
                    tempPortNumbers.push(tank.portNumber)
                } 
                else {
                    throw new Error('Invalid fuel tank information: Port numbers cannot be shared')
                }

                if (!fuelSensorTypes.includes(tank.fuelSensorType)) {
                    throw new Error('Invalid fuel tank information: Each fuel tank must have a sensor type');
                }
            })
        }
    }

    /**
     * Build newVgids, newGtids, newRtids based on
     * 1. targetUser's default groups/templates
     * 2. any additional groups/templates selected in form
     * 3. (special case if current user is distributor) current user's default vehicle group
     */
    getGroups = (uid) => {
        const {
            vgids = [],
            gtids = [],
            rtids = [],
        } = this.state

        const {
            vehicleGroups = [],
            geofenceTemplates = [],
            ruleTemplates = [],
        } = this.props.hierarchy.byId[uid]

        const targetUserDefaultVehicleGroup = vehicleGroups.find(vg => vg.groupName === 'ALL')
        const targetUserDefaultVgid = targetUserDefaultVehicleGroup && targetUserDefaultVehicleGroup.vgid
        const targetUserDefaultGeofenceTemplate = geofenceTemplates.find(gt => gt.templateName === 'UNGROUP')
        const targetUserDefaultGtid = targetUserDefaultGeofenceTemplate && targetUserDefaultGeofenceTemplate.gtid
        const targetUserDefaultRulesTemplate = ruleTemplates.find(rt => rt.templateName === 'DEFAULT')
        const targetUserDefaultRtid = targetUserDefaultRulesTemplate && targetUserDefaultRulesTemplate.rtid
        let newVgids = [...vgids, targetUserDefaultVgid].filter(v => v)
        let newGtids = [...gtids, targetUserDefaultGtid].filter(v => v)
        let newRtids = [...rtids, targetUserDefaultRtid].filter(v => v)

        /**
         * If current user is distributor, created vehicle would be added in current user's default vehicle group as well
         */
        switch (this.props.user.userRole) {
            case ROLE_TYPE.DISTRIBUTOR:
                const defaultVehicleGroup = Object.values(this.props.vehicleGroups.byVGID).find(vg => vg.groupName === 'ALL')
                const defaultVgid = defaultVehicleGroup && defaultVehicleGroup.vgid
                newVgids.push(defaultVgid)
                break
            default:
                break
        }

        return {
            newVgids,
            newGtids,
            newRtids
        }

    }

    componentDidMount = () => {
        this.setupFormDefaultValues()
    }

    componentDidUpdate = prevProps => {
        if (
            JSON.stringify(this.props) !== JSON.stringify(prevProps)
        ) {
            this.setupFormDefaultValues()
        }

        if (
            JSON.stringify(prevProps.router.location) !== JSON.stringify(this.props.router.location)
            || JSON.stringify(prevProps.hierarchy) !== JSON.stringify(this.props.hierarchy)
        ) {
            const { uid } = this.props.router.location.state || {}

            const user = this.props.hierarchy.byId[uid];

            // console.log(user);

            if (!user) return;

            this.props.dispatch(select_user(user))
        }
    }

    setupFormDefaultValues = () => {
        const {
            vehicle: editedVehicle,
            uid
        } = this.props.router.location.state || {};

        if (!editedVehicle) return null

        const fuelTanks = Object.keys(editedVehicle.fuelProfile || {}).map(key => {
            return {
                ...editedVehicle.fuelProfile[key],
                portNumber: Number(key.replace('fuel', '')),
                fuelSensorType: // In case the vehicle did not have a fuel sensor type or was using the old "rod" fuel sensor type
                    !fuelSensorTypes.includes(editedVehicle.fuelProfile[key].fuelSensorType) ? 
                        fuelSensorTypes[0] : 
                        editedVehicle.fuelProfile[key].fuelSensorType,
            }
        })

        const vgids = (uid &&
            this.props.hierarchy.byId[uid]
            && this.props.hierarchy.byId[uid].vehicleGroups
            && this.props.hierarchy.byId[uid].vehicleGroups
                .filter(vg => vg.groupName !== 'ALL')
                .filter(vg => {
                    return vg.vehicles.includes(editedVehicle.vid)
                }).map(vg => vg.vgid)) || []

        const gtids =
            (this.props.hierarchy.byId[uid]
            && this.props.hierarchy.byId[uid].geofenceTemplates
            && this.props.hierarchy.byId[uid].geofenceTemplates
                .filter(gt => gt.templateName !== 'UNGROUP')
                .filter(gt => editedVehicle.geofenceTemplates.includes(gt.gtid))
                .map(gt => gt.gtid)) || []

        const rtids =
            (this.props.hierarchy.byId[uid]
            && this.props.hierarchy.byId[uid].ruleTemplates
            && this.props.hierarchy.byId[uid].ruleTemplates
                .filter(rt => rt.templateName !== 'DEFAULT')
                .filter(rt => editedVehicle.ruleTemplates.includes(rt.rtid))
                .map(rt => rt.rtid)) || []

        // console.log({
        //     vgids,
        //     gtids,
        //     rtids,
        // })

        this.setState({
            editedVehicle,
            fuelTanks,
            targetUserUid: uid,
            vgids,
            gtids,
            rtids,
        })

    }


    addFuelTank = () => {
        const sampleFuelTank = { 
            portNumber: 0,
            fuelCapacity: 0,
            fuelLevelMin: 0,
            fuelLevelMax: 10,
            fuelLevelOperator: 'V',
            fuelSensorType: fuelSensorTypes[0],
        }

        const newFuelTanks = [...this.state.fuelTanks]
        newFuelTanks.push(sampleFuelTank)

        this.setState({ fuelTanks: newFuelTanks })
    }

    removeFuelTank = (id) => {
        this.state.fuelTanks.splice(id, 1)

        this.setState({ fuelTanks: this.state.fuelTanks })
    }

    fuelInfoForm = (index) => {
        const fuelTank = this.state.fuelTanks[index];
        const isLatest = index === this.state.fuelTanks.length - 1;

        return (
            <FormItem
                label={`Tank ${index + 1}`}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                Port Number
                <Select
                    defaultValue={fuelTank.portNumber}
                    value={fuelTank.portNumber}
                    onChange={v => {
                        fuelTank.portNumber = v
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                >
                    {
                        Array.from(Array(this.state.fuelTanks.length).keys()).map(number => {
                            return (
                                <Option key={number} value={number}>
                                    {number}
                                </Option>
                            );
                        })
                    }
                </Select>

                Fuel Capacity (L)
                <Input
                    defaultValue={fuelTank.fuelCapacity.toString()}
                    value={fuelTank.fuelCapacity.toString()}
                    onBlur={e => {
                        isNaN(Number(e.target.value)) && message.error('Must be a number')
                    }}
                    onFocus={e => e.target.select()}
                    onChange={e => {
                        const value = e.target.value.replace(" ", "")
                        fuelTank.fuelCapacity = isNaN(Number(value)) ? value : Number(value)
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                />

                Fuel Maximum Level
                <Input
                    defaultValue={fuelTank.fuelLevelMax.toString()}
                    value={fuelTank.fuelLevelMax.toString()}
                    onBlur={e => {
                        isNaN(Number(e.target.value)) && message.error('Must be a number')
                    }}
                    onFocus={e => e.target.select()}
                    onChange={e => {
                        const value = e.target.value.replace(" ", "")
                        fuelTank.fuelLevelMax = isNaN(Number(value)) ? value : Number(value)
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                />

                Fuel Minimum Level
                <Input
                    defaultValue={fuelTank.fuelLevelMin.toString()}
                    value={fuelTank.fuelLevelMin.toString()}
                    onBlur={e => {
                        isNaN(Number(e.target.value)) && message.error('Must be a number')
                    }}
                    onFocus={e => e.target.select()}
                    onChange={e => {
                        const value = e.target.value.replace(" ", "")
                        fuelTank.fuelLevelMin = isNaN(Number(value)) ? value : Number(value)
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                />

                Fuel Level Operator
                <Select
                    defaultValue={fuelTank.fuelLevelOperator}
                    value={fuelTank.fuelLevelOperator}
                    placeholder="% or V"
                    onChange={v => {
                        fuelTank.fuelLevelOperator = v
                        this.setState({ fuelTanks: this.state.fuelTanks })
                    }}
                >
                    {
                        ['%', 'V'].map(operator => {
                            return (
                                <Option key={operator} value={operator}>
                                    {operator}
                                </Option>
                            );
                        })
                    }
                </Select>

                Fuel Sensor Type
                <Select
                    defaultValue = {fuelTank.fuelSensorType}
                    value = {fuelTank.fuelSensorType}
                    placeholder = "% or V"
                    onChange = {v => {
                        fuelTank.fuelSensorType = v;

                        this.setState({ fuelTanks: this.state.fuelTanks });
                    }}
                >
                    {
                        fuelSensorTypes.map(type => {
                            return (
                                <Option key = {type} value = {type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </Option>
                            );
                        })
                    }
                </Select>

                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }} >
                    <Button
                        ghost
                        type="dashed"
                        disabled={this.state.fuelTanks.length === 1}
                        onClick={() => this.removeFuelTank(index)}
                        style={{
                            width: index === this.state.fuelTanks.length - 1 ? '48%' : '100%',
                            borderColor: 'red',
                            color: 'red'
                        }}
                    >
                        <MinusCircleOutlined /> Remove
                    </Button>

                    {
                        index === this.state.fuelTanks.length - 1 &&
                        <Button
                            type="dashed"
                            onClick={() => this.addFuelTank()}
                            style={{ width: isLatest ? '48%' : '100%' }}
                        >
                            <PlusOutlined /> Add
                            </Button>
                    }
                </div>
            </FormItem>
        )
    }

    render() {
        const {
            // selectedUser,
            editedVehicle,
            // selectedUserUIDList,
            targetUserUid,
        } = this.state;

        const {
            devices,
            hierarchy,
        } = this.props;

        

        /**Get vgids, gtids, rtids of target user */
        const userObject = this.props.hierarchy.byId[targetUserUid]

        let vehicleGroups = (userObject && userObject.vehicleGroups) || []
        let geofenceTemplates = (userObject && userObject.geofenceTemplates) || []
        let ruleTemplates = (userObject && userObject.ruleTemplates) || []



        return (
            <div className="page-container">
                <Page title="Edit Vehicle">
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                        <Form className="login-form">

                            {/* Vehicle Information */}
                            <FormItem
                                label="Vehicle Name"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="Car 1"
                                    value={editedVehicle.vehicleDisplayName}
                                    onFocus={e => e.target.select()}
                                    onChange={e => this.setState({
                                        ...this.state,
                                        editedVehicle: {
                                            ...editedVehicle,
                                            vehicleDisplayName: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label="Vehicle Plate"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="ABC123"
                                    value={editedVehicle.vehiclePlate}
                                    onFocus={e => e.target.select()}
                                    onChange={e => this.setState({
                                        ...this.state,
                                        editedVehicle: {
                                            ...editedVehicle,
                                            vehiclePlate: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label="Vehicle Model"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="Toyota Vios"
                                    value={editedVehicle.vehicleModel}
                                    onFocus={e => e.target.select()}
                                    onChange={e => this.setState({
                                        ...this.state,
                                        editedVehicle: {
                                            ...editedVehicle,
                                            vehicleModel: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label="Vehicle Type"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Select
                                    placeholder="Select a vehicle type"
                                    value={editedVehicle.vehicleType ? editedVehicle.vehicleType : undefined}
                                    onChange={value => this.setState({
                                        ...this.state,
                                        editedVehicle: {
                                            ...editedVehicle,
                                            vehicleType: value,
                                        }
                                    })}
                                >
                                    {
                                        VEHICLE_TYPE.map(vehicleType => {
                                            return (
                                                <Option key={vehicleType.value} value={vehicleType.value}>
                                                    {vehicleType.label}
                                                </Option>
                                            );
                                        })
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label="Vehicle Fuel Information"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Row>
                                    {
                                        this.state.fuelTanks.length > 0 ?
                                            this.state.fuelTanks.map((fuelTank, i) => {
                                                return (
                                                    <Col span={12} key={i}>
                                                        {this.fuelInfoForm(i)}
                                                    </Col>
                                                )
                                            }) :
                                            <Button
                                                type="dashed"
                                                onClick={() => this.addFuelTank()}
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Add
                                            </Button>
                                    }
                                </Row>
                            </FormItem>

                            <FormItem
                                label="Device"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                help={`Cannot be edited once set`}
                            >
                                {
                                    devices.listIds.filter((dvid) => (editedVehicle.dvid === dvid || devices.byId[dvid].assignedStatus !== 1) && dvid).length < 1 ?
                                        <Select
                                            disabled
                                            placeholder="No other unallocated devices"
                                        /> :
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Assign to a device"
                                            value={editedVehicle.dvid ? editedVehicle.dvid : undefined}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={value => this.setState({
                                                ...this.state,
                                                editedVehicle: {
                                                    ...editedVehicle,
                                                    dvid: value,
                                                }
                                            })}
                                            disabled
                                        >
                                            {
                                                devices.listIds
                                                    .filter((dvid) => {
                                                        return devices.byId[dvid].assignedStatus !== 1 && dvid
                                                    })
                                                    .map((dvid) => {
                                                        // console.log(dvid);

                                                        return (
                                                            <Option key={dvid} value={dvid}>
                                                                {dvid}
                                                            </Option>
                                                        );
                                                    })
                                            }
                                        </Select>
                                }
                            </FormItem>
                            {/* 
                                Not allowed to select fleet operator as target user.
                                To assign fleet operator a vehicle,
                                1. log in as fleet owner
                                2. go to user management
                                3. click on designated fleet operator
                                4. configure user
                            */}
                            <FormItem
                                label="Select Target User"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                help={`Only fleet owner and distributors are allowed.`}
                                required
                            >
                                {
                                    Object.keys(this.props.hierarchy.byId).length === 0 || this.props.style.isLoadingSubmit ? (
                                        <LoadingOutlined />
                                    ) : (
                                            <Select
                                                showSearch
                                                placeholder="Select a user to assign this vehicle to"
                                                value={targetUserUid}
                                                onChange={(targetUserUid) => {
                                                    this.setState({
                                                        ...this.state,
                                                        targetUserUid: targetUserUid,
                                                    })
                                                }}
                                            >
                                                {
                                                    Object.values(hierarchy.byId)
                                                        .filter((currUser) => currUser && currUser.userName)
                                                        .sort((a, b) => {
                                                            const uA = a.userName.toLowerCase();
                                                            const uB = b.userName.toLowerCase();

                                                            if (uA < uB) return -1;
                                                            if (uA > uB) return 1;
                                                            return 0;
                                                        })
                                                        .map((currUser) => {
                                                            const disabled = !currUser || currUser.userRole === ROLE_TYPE.FLEET_OPERATOR;

                                                            return (
                                                                <Option
                                                                    key={currUser.uid}
                                                                    disabled={disabled}
                                                                >
                                                                    {`${currUser.userName} (${currUser.userRole})`}
                                                                </Option>
                                                            );
                                                        })
                                                }
                                            </Select>

                                        )
                                }
                            </FormItem>

                            {
                                targetUserUid && (
                                    <div style={{ marginTop: 20 }}>
                                        {/* To decide which vehicle group of the target user this vehicle should be assigned to */}
                                        <FormItem
                                            label="Vehicle Groups"
                                            labelCol={{ span: 7 }}
                                            wrapperCol={{ span: 15 }}
                                            help={targetUserUid ? '' : 'Select a target user first'}
                                        >
                                            {
                                                <Select
                                                    showSearch
                                                    mode="multiple"
                                                    placeholder="Select vehicle groups to assign vehicle to (Optional)"
                                                    style={{ width: '100%' }}
                                                    value={this.state.vgids.map(vgid => {
                                                        const vehicleGroup = vehicleGroups.find(vg => vg.vgid === vgid)
                                                        return {
                                                            label: vehicleGroup && vehicleGroup.groupName,
                                                            value: vgid
                                                        }
                                                    })}
                                                    labelInValue={true}
                                                    onChange={(values) => {
                                                        const vgids = values.map(v => v.key)
                                                        this.setState({
                                                            ...this.state,
                                                            vgids,
                                                        })
                                                    }}
                                                >
                                                    {
                                                        vehicleGroups
                                                            .filter(vg => vg.groupName !== 'ALL')
                                                            .map(vg => {
                                                                return <Option key={vg.vgid}>{vg.groupName}</Option>
                                                            })
                                                    }
                                                </Select>
                                            }
                                        </FormItem>

                                        {/* Important for rule engine. */}
                                        <FormItem
                                            label="Geofence Templates"
                                            labelCol={{ span: 7 }}
                                            wrapperCol={{ span: 15 }}
                                            help={targetUserUid ? '' : 'Select a target user first'}
                                        >
                                            {
                                                <Select
                                                    showSearch
                                                    mode="multiple"
                                                    placeholder="Select geofence templates to assign vehicle to (Optional)"
                                                    style={{ width: '100%' }}
                                                    value={this.state.gtids.map(gtid => {
                                                        const geofenceTemplate = geofenceTemplates.find(gt => gt.gtid === gtid)
                                                        return {
                                                            label: geofenceTemplate && geofenceTemplate.templateName,
                                                            value: gtid
                                                        }
                                                    })}
                                                    labelInValue={true}
                                                    onChange={values => {
                                                        const gtids = values.map(v => v.key);

                                                        // console.log({ gtids });
                                                        
                                                        this.setState({
                                                            ...this.state,
                                                            gtids: gtids,
                                                        })
                                                    }}
                                                >
                                                    {
                                                        geofenceTemplates
                                                            .filter(gt => gt.templateName !== 'UNGROUP')
                                                            .map(gt => {
                                                                return <Option key={gt.gtid}>{gt.templateName}</Option>
                                                            })
                                                    }
                                                </Select>
                                            }
                                        </FormItem>

                                        {/* Important for rule engine. */}
                                        <FormItem
                                            label="Rule Templates"
                                            labelCol={{ span: 7 }}
                                            wrapperCol={{ span: 15 }}
                                            help={targetUserUid ? '' : 'Select a target user first'}
                                        >
                                            {
                                                <Select
                                                    showSearch
                                                    mode="multiple"
                                                    placeholder="Select rule templates to assign vehicle to (Optional)"
                                                    style={{ width: '100%' }}
                                                    value={this.state.rtids.map(rtid => {
                                                        const ruleTemplate = ruleTemplates.find(rt => rt.rtid === rtid)
                                                        return {
                                                            label: ruleTemplate && ruleTemplate.templateName,
                                                            value: rtid
                                                        }
                                                    })}
                                                    labelInValue={true}
                                                    onChange={(values) => {
                                                        const rtids = values.map(v => v.key)
                                                        this.setState({
                                                            ...this.state,
                                                            rtids: rtids,
                                                        })
                                                    }}
                                                >
                                                    {
                                                        ruleTemplates
                                                            .filter(rt => rt.templateName !== 'DEFAULT')
                                                            .map(rt => {
                                                                return <Option key={rt.rtid}>{rt.templateName}</Option>
                                                            })
                                                    }
                                                </Select>
                                            }
                                        </FormItem>
                                    </div>
                                )
                            }
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    type="danger"
                                    className="login-form-button"
                                    onClick={() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading={this.props.style.isLoadingSubmit && this.state.isLoading}
                                    onClick={this.submitForm}
                                >
                                    Save
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.v2.user,
    style: state.v2.style,
    router: state.router,
    devices: state.v2.devices,
    vehicles: state.v2.vehicles,
    hierarchy: state.v2.hierarchy,
    ruleTemplate: state.v2.ruleTemplate,
    vehicleGroups: state.v2.vehicleGroups,
    geofenceTemplate: state.v2.geofenceTemplate,
});

const ConnectedEditCustomerVehicle = connect(mapStateToProps)(EditCustomerVehiclePage);
export default showSecondarySidebar(false)(ConnectedEditCustomerVehicle);