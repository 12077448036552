import React from "react";

import Layout from "./Layout";
import PropTypes from "prop-types";

import { FEATURE } from "../../../../constants";
import { buildAbility } from "../../services/auth/accessRight";
import { moveToPage } from "../../navigation/navigationService";
import { 
  useDispatch, 
  useSelector, 
} from 'react-redux';

import {
  CopyOutlined,
  UserOutlined,
  ForkOutlined,
  TeamOutlined,
  BellOutlined,
  ExportOutlined,
  GatewayOutlined,
  SettingOutlined,
  // PieChartOutlined,
  SnippetsOutlined,
  LineChartOutlined,
  // EnvironmentOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'

const LayoutContainer = (props) => {
  const user = useSelector(state => state.v2.user)
  const style = useSelector(state => state.v2.style)

  const dispatch = useDispatch();
  
  const location = props.location

  const navigateTo = pageKey => dispatch(moveToPage(pageKey));

  let sidebarItems = [
    {
      key: `/${FEATURE.VEHICLE_DISPLAY}`,
      icon: <UnorderedListOutlined style = {{ fontSize: 24 }}/>,
      label: "Vehicle Information"
    },

    {
      key: `/${FEATURE.TRIP_RECORD}`,
      icon: <ForkOutlined style = {{ fontSize: 24 }}/>,
      label: "Trip Record"
    },

    {
      key: `/${FEATURE.GEOFENCE}`,
      icon: <GatewayOutlined style = {{ fontSize: 24 }}/>,
      label: "Geofence Management"
    },

    // {
    //   key: `/${FEATURE.GEOFENCE_RECORD}`,
    //   icon: <EnvironmentOutlined style = {{ fontSize: 24 }}/>,
    //   label: "Geofence Record"
    // },

    {
      key: `/${FEATURE.USER_MANAGEMENT}`,
      icon: <TeamOutlined style = {{ fontSize: 24 }}/>,
      label: "User Management"
    },

    {
      key: `/${FEATURE.MANAGEMENT}`,
      icon: <SettingOutlined style = {{ fontSize: 24 }}/>,
      label: "Management"
    },

    {
      key: `/${FEATURE.EVENT_RECORD}`,
      icon: <SnippetsOutlined style = {{ fontSize: 24 }}/>,
      label: "Event Record"
    },

    // {
    //   key: `/${FEATURE.REPORT}`,
    //   icon: <PieChartOutlined style = {{ fontSize: 24 }}/>,
    //   label: "Report"
    // },

    {
      key: `/${FEATURE.FUEL_ANALYSIS}`,
      icon: <LineChartOutlined style = {{ fontSize: 24 }}/>,
      label: "Fuel Analysis"
    },

    {
      key: `/${FEATURE.MIGRATION}`,
      icon: <ExportOutlined style = {{ fontSize: 24 }}/>,
      label: "Migration"
    },

    {
      key: `/${FEATURE.ESCALATION}`,
      icon: <BellOutlined style = {{ fontSize: 24 }}/>,
      label: "SOS Alerts"
    },

    {
      key: `/${FEATURE.VEHICLE_REPORT}`,
      icon: <CopyOutlined style = {{ fontSize: 24 }}/>,
      label: "Vehicle Reports"
    },

    {
      key: `/${FEATURE.PROFILE}`,
      icon: <UserOutlined style = {{ fontSize: 24 }}/>,
      label: "Profile"
    },
  ];

  let items = sidebarItems.filter(listItem => {
    if(user && user.userRole)
      return buildAbility(user.userRole).can("read", listItem.key.slice(1))
    else
      return buildAbility('NONE').can("read", listItem.key.slice(1))
  });

  return (
    <Layout
      sidebarItems={items}
      onClickSidebarItem={sidebarItem => navigateTo(sidebarItem.key|| "/")}
      selectedKeys={location ? location.pathname : ""}
      themeColor={(style && style.sidebarColor)|| "#fff"}
    >
      {props.children}
    </Layout>
  );
}

LayoutContainer.propTypes = {
  children: PropTypes.element
};

export default LayoutContainer;
